import React from "react";
import { Card, Form, Select, Input, DatePicker, Button } from "antd";
import { Link } from "react-router-dom";
import { DATE_AND_TIME_FORMAT } from "constants/globals";

const Inner = (props) => {
    const [form] = Form.useForm();

    return (
        <Card title="Создание заказа" extra={<Link to="/orders"><Button type='primary'>Назад</Button></Link>}>
            <Form autoComplete="off" labelCol={{ span: 5 }} onFinish={(values) => {
                props.onFinish(values, "inner")
            }}
                fields={
                    [
                        {
                            name: ["manager_id"],
                            value: props.user?.id
                        },
                    ]}
                form={form}
            >

                <Form.Item label="Дата и время работ" name="work_at" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                </Form.Item>

                <Form.Item label="Адрес" name="address" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Водитель" name="performer_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Select placeholder="Не указано">
                        {props.performers.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Form.Item label="Техника" name="equipment_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Select placeholder="Не указано">
                        {props.equipments.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Form.Item label="Описание" name="description">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Менеджер" name="manager_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? '').includes(input.toLowerCase())}
                    >
                        {props.managers.map((item) => (<Select.Option label={item.name} key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
            </Form>
        </Card >
    );
};

export default Inner;