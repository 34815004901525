import axios from "axios";
import { API_URL } from "../config/conf";

export function preloading() {
    return (dispatch) => {
        dispatch({ type: "PRELOADER", payload: false });
        dispatch({ type: "UPDATE_BUTTON", payload: false });
        axios.get(`${API_URL}users`).then((response) => {
            dispatch({ type: "USERS_LIST", payload: response.data });
        });
        axios.get(`${API_URL}users?role=1`).then((response) => {
            dispatch({ type: "MANAGERS_LIST", payload: response.data });
        });
        axios.get(`${API_URL}users?role=1&active=1`).then((response) => {
            dispatch({ type: "MANAGERS_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}users?role=2`).then((response) => {
            dispatch({ type: "PERFORMERS_LIST", payload: response.data });
        });
        axios.get(`${API_URL}users?role=2&active=1`).then((response) => {
            dispatch({ type: "PERFORMERS_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/cities`).then((response) => {
            dispatch({ type: "CITIES_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/cities`, { params: { archived: false } }).then((response) => {
            dispatch({ type: "CITIES_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/units`).then((response) => {
            dispatch({ type: "UNITS_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/nomencategories`).then((response) => {
            dispatch({ type: "NOMENCLATURE_CATEGORIES_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/nomencategories`, { params: { active: true } }).then((response) => {
            dispatch({ type: "NOMENCLATURE_CATEGORIES_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/categories`).then((response) => {
            dispatch({ type: "EQUIPMENT_CATEGORIES_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/categories`, { params: { active: true } }).then((response) => {
            dispatch({ type: "EQUIPMENT_CATEGORIES_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/nomenclatures`).then((response) => {
            dispatch({ type: "NOMENCLATURE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/nomenclatures`, { params: { active: true } }).then((response) => {
            dispatch({ type: "NOMENCLATURE_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/taxes`).then((response) => {
            dispatch({ type: "TAXES_LIST", payload: response.data });
        });
        axios.get(`${API_URL}equipments`, { params: { active: true } }).then((response) => {
            dispatch({ type: "EQUIPMENTS_ACTIVE_LIST", payload: response.data });
        });
        axios.get(`${API_URL}equipments`).then((response) => {
            dispatch({ type: "EQUIPMENTS_LIST", payload: response.data });
        });
        axios.get(`${API_URL}dict/regions`).then((response) => {
            dispatch({ type: "REGIONS_LIST", payload: response.data });
        });
        dispatch({ type: "PRELOADER", payload: true });
        setTimeout(() => {
            dispatch({ type: "UPDATE_BUTTON", payload: true });
        }, 10000);
    };
}

export function globalMonth(item) {
    return (dispatch) => {
        dispatch({ type: "MONTH", payload: item });
    };
}
