import React, { useEffect } from "react";
import { Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "./layout/index";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import { fetchList, fetchUnpaidList } from "actions/bills";
import { fetchMonthList, fetchProcessingList, fetchUnshipmentList } from "actions/orders";

const Dashboard = () => {
    const dispatch = useDispatch();
    const newOrdersList = useSelector((state) => state.orders.monthList);
    const processingOrdersList = useSelector((state) => state.orders.processingList);
    const unshipmenOrderstList = useSelector((state) => state.orders.unshipmentList);
    const newBillsList = useSelector((state) => state.bills.list);
    const unpaidBillsList = useSelector((state) => state.bills.unpaidList);

    useEffect(() => {
        let val = { month: dayjs().format(DATE_FORMAT) };

        dispatch(fetchProcessingList());
        dispatch(fetchUnshipmentList());
        dispatch(fetchMonthList(val));
        dispatch(fetchList({ ago: 7 }));
        dispatch(fetchUnpaidList());
    }, []);

    return (
        <MainLayout>
            <Row gutter={16} style={{ paddingBottom: "30px" }}>
                <Col span={8}>
                    <Card title="Новые заказы" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>
                            {newOrdersList.length
                                ? newOrdersList.map((item) => {
                                      return <span key={item.id}>{item.id} </span>;
                                  })
                                : "Нет заказов"}
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Заказы в работе" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>
                            {processingOrdersList.length
                                ? processingOrdersList.map((item) => {
                                      return <span key={item.id}>{item.id} </span>;
                                  })
                                : "Нет заказов"}
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Заказы без реализации" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>
                            {unshipmenOrderstList.length
                                ? unshipmenOrderstList.map((item) => {
                                      return <span key={item.id}>{item.id} </span>;
                                  })
                                : "Нет заказов"}
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Недавние счета" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>
                            {newBillsList.length
                                ? newBillsList.map((item) => {
                                      return <span key={item.id}>{item.comment} </span>;
                                  })
                                : "Нет счетов"}
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Неоплаченные счета" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>
                            {unpaidBillsList.length
                                ? unpaidBillsList.map((item) => {
                                      return <span key={item.id}>{item.comment} </span>;
                                  })
                                : "Нет счетов"}
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Должники" bordered={false}>
                        <div style={{ height: 260, overflow: "auto", overflowY: "scroll" }}>Card content</div>
                    </Card>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default Dashboard;
