import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => {

    return (
        <Footer style={{ textAlign: 'center' }}>FOOTER</Footer>
    )
}


export default AppFooter;
