import axios from "axios";
import { history } from "store";
import * as messages from "actions/handle_messages";
import { API_URL } from "config/conf";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}dict/categories`).then((response) => {
            dispatch({ type: "EQUIPMENT_CATEGORIES_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}dict/categories/${id}`).then((response) => {
            dispatch({ type: "EQUIPMENT_CATEGORIES_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}dict/categories`, item).then(() => {
            dispatch(fetchList());
            history.push("/dict/equipment-categories");
            dispatch(messages.success("Категория создана"));
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}dict/categories/${item.id}`, item).then(() => {
            dispatch(fetchList());
            history.push("/dict/equipment-categories");
            dispatch(messages.success("Категория обновлена"));
        });
    };
}
