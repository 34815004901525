import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "BILLS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function unpaidList(state = [], action) {
    switch (action.type) {
        case "BILLS_UNPAID_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "BILLS_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "BILLS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function positions(state = [], action) {
    switch (action.type) {
        case "BILL_POSITIONS":
            return action.payload;
        default:
            return state;
    }
}

export function position(state = [], action) {
    switch (action.type) {
        case "BILL_POSITION":
            return action.payload;
        default:
            return state;
    }
}

export const billsReducers = () =>
    combineReducers({
        list: list,
        unpaidList: unpaidList,
        filterValues: filterValues,
        item: item,
        positions: positions,
        position: position
    });
