import React from "react";
import { Tabs } from "antd";

const Add = () => {
    const tabs = [
        {
            key: "1",
            label: "Общая",
            children: <h1>common</h1>
        },
        {
            key: "2",
            label: "Перепродажа",
            children: <h1>resell</h1>
        }
    ];

    return <Tabs defaultActiveKey="1" items={tabs} />;
};

export default Add;
