import React from "react";
import MainLayout from "components/layout";
import { Routes, Route } from "react-router-dom";
import List from "../components/orders";
import Add from "../components/orders/add";
import Item from "../components/orders/item";

const Orders = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/add" element={<Add />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Orders;
