import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/sales/bills";
import Add from "../../components/sales/bills/add";
import Item from "../../components/sales/bills/item";

const Bills = () => {
    return (
        <Routes>
            <Route exact path="/" element={<List />} />
            <Route exact path="/add" element={<Add />} />
            <Route exact path="/:id" element={<Item />} />
        </Routes>
    );
};

export default Bills;
