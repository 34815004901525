import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "REPORTS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "REPORTS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "REPORTS_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function reportFile(state = [], action) {
    switch (action.type) {
        case "REPORT_FILE":
            return action.payload;
        default:
            return state;
    }
}

export const reportsReducers = () =>
    combineReducers({
        list: list,
        item: item,
        filterValues: filterValues,
        reportFile: reportFile
    });
