import axios from "axios";
import { API_URL } from "../config/conf";
import * as messages from "./handle_messages";

export function fetchProfileList(page) {
    return (dispatch) => {
        axios.get(`${API_URL}profile/notifications?page=${page}&limit=50`).then((response) => {
            dispatch({ type: "NOTIFICATIONS_PROFILE_LIST", payload: response.data });
        });
    };
}

export function fetchProfileItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}profile/notifications/${id}`).then((response) => {
            dispatch({ type: "NOTIFICATIONS_PROFILE_ITEM", payload: response.data });
        });
    };
}

export function updateProfileItem(id, page) {
    return (dispatch) => {
        axios.patch(`${API_URL}profile/notifications/${id}`).then(() => {
            dispatch(messages.success("Прочитано"));
            dispatch(fetchProfileList(page));
        });
    };
}

export function fetchProfileSettingsManagerList() {
    return (dispatch) => {
        axios.get(`${API_URL}profile/notification_settings?role=manager`).then((response) => {
            dispatch({ type: "NOTIFICATIONS_SETTINGS_PROFILE_MANAGER_LIST", payload: response.data });
        });
    };
}

export function fetchProfileSettingsAdminList() {
    return (dispatch) => {
        axios.get(`${API_URL}profile/notification_settings?role=admin`).then((response) => {
            dispatch({ type: "NOTIFICATIONS_SETTINGS_PROFILE_ADMIN_LIST", payload: response.data });
        });
    };
}

export function fetchProfileSettingsPerformerList() {
    return (dispatch) => {
        axios.get(`${API_URL}profile/notification_settings?role=performer`).then((response) => {
            dispatch({ type: "NOTIFICATIONS_SETTINGS_PROFILE_PERFORMER_LIST", payload: response.data });
        });
    };
}

export function updateProfileSettingsItem(id, item) {
    return () => {
        axios.patch(`${API_URL}profile/notification_settings/${id}`, item);
    };
}

export function getCount(item) {
    return (dispatch) => {
        dispatch({ type: "NOTIFICATIONS_COUNT", payload: item });
    };
}
