import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "components/layout";
import List from "../components/profile";

const Profile = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
            </Routes>
        </MainLayout>
    );
};

export default Profile;
