import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { fetchMonthList } from "actions/orders";
import Loading from "utils/loading";
import { DATE_FORMAT } from "constants/globals";
import { useNavigate } from "react-router-dom";

const Month = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const list = useSelector((state) => state.orders.monthList);
    const loading = useSelector((state) => state.globals.loadingList);
    const month = useSelector((state) => state.globals.month);

    useEffect(() => {
        let val = { ...props.filterValues, month: month.format(DATE_FORMAT), active: 1 };
        dispatch(fetchMonthList(val));
    }, [props.filterValues, month]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Table
                className={"data-table"}
                pagination={false}
                rowKey="id"
                columns={props.columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/orders/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default Month;
