import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "OBJECTS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "OBJECTS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function userObjects(state = [], action) {
    switch (action.type) {
        case "USER_OBJECTS":
            return action.payload;
        default:
            return state;
    }
}

export const objectsReducers = () =>
    combineReducers({
        list: list,
        item: item,
        userObjects: userObjects
    });
