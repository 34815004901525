import React from "react";
import { Typography } from "antd";

const Item = () => {

    return (
        <>
            <Typography.Title level={2}>Звонок №</Typography.Title>
        </>
    );
};

export default Item;
