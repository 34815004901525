import { combineReducers } from "redux";

export function recentList(state = [], action) {
    switch (action.type) {
        case "RECENT_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function approvedList(state = [], action) {
    switch (action.type) {
        case "APPROVED_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function canceledList(state = [], action) {
    switch (action.type) {
        case "CANCELED_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "OFFERS_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "OFFERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function recentCount(state = null, action) {
    switch (action.type) {
        case "OFFERS_RECENT_COUNT":
            return action.payload;
        default:
            return state;
    }
}

export const offersReducers = () =>
    combineReducers({
        recentList: recentList,
        approvedList: approvedList,
        canceledList: canceledList,
        filterValues: filterValues,
        item: item,
        recentCount: recentCount
    });
