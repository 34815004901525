import React, { useEffect } from "react";
import { Modal, Form, Select, Button, Input, Popover, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "actions/orders";
import { CurrencyFormat } from "utils/currency";
import dayjs from "dayjs";

const Filter = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const managers = useSelector((state) => state.users.managers);
    const performers = useSelector((state) => state.users.activePerformers);
    const clients = useSelector((state) => state.users.data);
    const filterValues = useSelector((state) => state.orders.filterValues);

    useEffect(() => {
        if (filterValues) {
            form.setFieldsValue(filterValues);
        } else {
            form.resetFields();
        }
    }, [filterValues]);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(setFilterValues(val));
        form.resetFields();
        props.setIsModalOpen(false);
    };

    const columns = [
        {
            title: "№",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Стоимость",
            dataIndex: "total",
            key: "total",
            render: (text) => {
                return <CurrencyFormat value={text.toFixed(2)} />;
            }
        },
        {
            title: "Время",
            dataIndex: "work_at",
            key: "work_at",
            render: (text) => {
                return dayjs(text).format("DD.MM.YYYY HH:mm");
            }
        },
        {
            title: "Клиент",
            dataIndex: "client",
            key: "client",
            render: (text, item) => {
                return (
                    <div>
                        {item.kind === "inner" ? (
                            <div>
                                Отсутствует<div style={{ fontSize: 12, color: "#777" }}>Внутреняя</div>
                            </div>
                        ) : (
                            text.name
                        )}
                    </div>
                );
            }
        },
        {
            title: "Техника",
            dataIndex: "equipment",
            key: "equipment",
            render: (text, item) => {
                return (
                    <div>
                        {text ? (
                            <Popover content={text.name} trigger="hover">
                                {text.number} {item.performer ? <div style={{ fontSize: 12, color: "#777" }}>{item.performer.name}</div> : null}
                            </Popover>
                        ) : item.kind === "supply" ? (
                            <div>Материал</div>
                        ) : (
                            <div>
                                Субподрядчик
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>
                                    {item.subcontractor ? item.subcontractor.name : "не выбран"}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                let icon = null;
                switch (text) {
                    case "processing":
                        icon = <Tag color="#f50">В работе</Tag>;
                        break;
                    case "completed":
                        icon = <Tag color="#87d068">Завершен</Tag>;
                        break;
                    case "canceled":
                        icon = <Tag color="#777777">Отменена</Tag>;
                        break;
                }

                return <div className="status">{icon}</div>;
            }
        },
        {
            title: "Отгрузка",
            dataIndex: "implementation_id",
            key: "implementation_id",
            render: (text) => {
                let status;
                switch (text) {
                    case null:
                        status = <Tag color="#f50">Не отгружен</Tag>;
                        break;
                    default:
                        status = <Tag color="#87d068">Отгрузка № {text}</Tag>;
                        break;
                }
                return <div className="status">{status}</div>;
            }
        }
    ];

    return (
        <Modal
            title="Фильтры"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отфильтровать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 2 }} form={form} onFinish={onFinish}>
                <Form.Item label="Номер" name="number">
                    <Input type="text" placeholder="" />
                </Form.Item>

                <Form.Item label="Клиент" name="client">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Менеджер" name="manager">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {managers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Водитель" name="performer">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {performers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Filter;
