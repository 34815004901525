import { Typography, Table, Tag, Space, Col, Button } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import { DATE_FORMAT } from "constants/globals";
import { CurrencyFormat } from "utils/currency";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "utils/loading";
import { clearFilterList } from "actions/bills";
import { fetchList } from "actions/bills";
import Filter from "./filter";

const List = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const list = useSelector((state) => state.bills.list);
    const loading = useSelector((state) => state.globals.loadingList);
    const filterValues = useSelector((state) => state.bills.filterValues);

    useEffect(() => {
        dispatch(fetchList(filterValues));
    }, [filterValues]);

    if (loading) {
        return <Loading />;
    }

    const columns = [
        {
            title: "Дата",
            dataIndex: "date",
            key: "date",
            width: 120,
            render: (text) => {
                return <div>{dayjs(text).format(DATE_FORMAT)}</div>;
            }
        },
        {
            title: "Номер",
            dataIndex: "uid",
            key: "uid",
            render: (text, item) => {
                return (
                    <div>
                        <div>{text}</div>
                        <div style={{ fontSize: 12, color: "#777" }}>{item.client.name}</div>
                    </div>
                );
            }
        },
        {
            title: "Сумма",
            dataIndex: "amount",
            key: "amount",
            render: (text) => {
                return (
                    <div>
                        <CurrencyFormat value={text} />
                    </div>
                );
            }
        },
        {
            title: "Оплата",
            dataIndex: "status",
            key: "status",
            width: 120,
            render: (text) => {
                let status;
                switch (text) {
                    case "unpaid":
                        status = <Tag color="#f50">Неоплачено</Tag>;
                        break;
                    case "paid":
                        status = <Tag color="#87d068">Оплачено</Tag>;
                        break;
                    case "half":
                        status = <Tag color="#f50">Частично оплачено</Tag>;
                        break;
                    case "canceled":
                        status = <Tag color="#f20">Отменен</Tag>;
                        break;
                }
                return <div className="status">{status}</div>;
            }
        },
        {
            title: "Отгрузка",
            dataIndex: "shipment_status",
            key: "shipment_status",
            width: 130,
            render: (text) => {
                let status;
                switch (text) {
                    case "unshipment":
                        status = <Tag color="#f50">Неотгружено</Tag>;
                        break;
                    case "shipment":
                        status = <Tag color="#87d068">Отгружено</Tag>;
                        break;
                    case "partialy_shipment":
                        status = <Tag color="#f50">Частично отгружен</Tag>;
                        break;
                }
                return <div className="shipment_status">{status}</div>;
            }
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
            key: "comment",
            width: 450,
            render: (text) => {
                return <div>{text}</div>;
            }
        }
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <div>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Счета</Typography.Title>
                </Col>

                <Col>
                    {filterValues ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                dispatch(clearFilterList());
                            }}
                        >
                            <CloseOutlined />
                        </Button>
                    ) : undefined}
                    <Button type="primary" onClick={showModal} style={{ marginRight: 5, marginLeft: 5 }}>
                        Фильтры
                    </Button>
                    <Link to="/sales/bills/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <Filter isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <br />
            <br />
            <Table
                className={"data-table"}
                pagination={false}
                bordered
                rowKey="id"
                tableLayout="fixed"
                columns={columns}
                dataSource={list}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
