import React, { useEffect } from "react";
import { Table, Tag, Popover, Badge } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPerformerOrders } from "actions/performers";
import { CurrencyFormat } from "utils/currency";
import { DocumentIcon, Documents } from "utils/svg";
import dayjs from "dayjs";
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from "constants/globals";
import { useDispatch, useSelector } from "react-redux";

const Orders = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const list = useSelector((state) => state.performers.orders);
    const month = useSelector((state) => state.globals.month);

    useEffect(() => {
        dispatch(fetchPerformerOrders(id, month.format(DATE_FORMAT)));
    }, [id, month]);

    const columns = [
        {
            title: "№",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Стоимость",
            dataIndex: "total",
            key: "total",
            render: (text) => {
                return <CurrencyFormat value={text.toFixed(2)} />;
            }
        },
        {
            title: "Время",
            dataIndex: "work_at",
            key: "work_at",
            render: (text, item) => {
                return (
                    <div>
                        <div>{dayjs(text).format(DATE_AND_TIME_FORMAT)}</div>
                        <div style={{ fontSize: 12, color: "#777" }}>{item.client?.phone}</div>
                    </div>
                );
            }
        },
        {
            title: "Клиент",
            dataIndex: "client",
            key: "client",
            render: (text, item) => {
                {
                    switch (item.kind) {
                        case "subcontractor":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Субподрядчик</div>
                                </div>
                            );
                        case "supply":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Материал</div>
                                </div>
                            );
                        case "inner":
                            return <div style={{ fontSize: 12, color: "#777" }}>Внутренняя</div>;
                        case "equipment":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Техника</div>
                                </div>
                            );
                        default:
                            "";
                    }
                }
            }
        },
        {
            title: "Техника",
            dataIndex: "equipment",
            key: "equipment",
            render: (text, item) => {
                return (
                    <div>
                        {text ? (
                            <Popover content={text.name} trigger="hover">
                                {text.number} {item.performer ? <div style={{ fontSize: 12, color: "#777" }}>{item.performer.name}</div> : null}
                            </Popover>
                        ) : item.kind === "supply" ? (
                            <div>Материал</div>
                        ) : (
                            <div>
                                Субподрядчик
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>
                                    {item.subcontractor ? item.subcontractor.name : "не выбран"}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                let icon = null;
                switch (text) {
                    case "processing":
                        icon = <Tag color="#f50">В работе</Tag>;
                        break;
                    case "completed":
                        icon = <Tag color="#87d068">Завершен</Tag>;
                        break;
                    case "canceled":
                        icon = <Tag color="#777777">Отменена</Tag>;
                        break;
                }

                return <div className="status">{icon}</div>;
            }
        },
        {
            title: "Рапорт",
            dataIndex: "reports",
            key: "reports",
            render: (text, item) => {
                let icon = null;
                switch (text.length) {
                    case 1:
                        icon = <DocumentIcon />;
                        break;
                    case 2:
                        icon = <DocumentIcon />;
                        break;
                    case 0:
                        icon = <Documents />;
                        break;
                }

                return <Badge count={item.rel_reports}>{icon}</Badge>;
            }
        }
    ];

    return (
        <Table
            className={"data-table"}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={list.orders}
            locale={{ emptyText: "Нет данных" }}
            onRow={({ id }) => ({
                onClick: () => {
                    navigate(`/orders/${id}`);
                }
            })}
        />
    );
};

export default Orders;
