import React, { useEffect, useState } from "react";
import { Typography, List, Space, Button, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "actions/users";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { fetchOrderPurchases, fetchOrderPurchase, deleteOrderPurchase } from "actions/orders";
import { PlusOutlined } from "@ant-design/icons";
import Add from "./add";
import Edit from "./edit";

const Sales = (props) => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.orders.purchases);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const users = useSelector((state) => state.users.data);

    useEffect(() => {
        dispatch(fetchOrderPurchases(props.id));
        dispatch(fetchList());
    }, [props.id]);

    const showAddModal = () => {
        setIsAddModalOpen(true);
    };

    const showEditModal = (id) => {
        setIsEditModalOpen(true);
        dispatch(fetchOrderPurchase(props.id, id));
    };

    const deleteSale = (id) => {
        dispatch(deleteOrderPurchase(props.id, id));
    };

    const contractorName = (id) => {
        const user = users.filter((item) => item.id === id);
        return user[0]?.name;
    };

    const total = (price, quantity) => {
        return price * quantity;
    };

    return (
        <div>
            <Space
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
                <Typography.Title level={3}>Покупки</Typography.Title>
                <Button type="primary" onClick={showAddModal} disabled={props.archived}>
                    <PlusOutlined />
                </Button>
            </Space>
            <Add
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
                nomenclature={props.nomenclature}
                taxes={props.taxes}
                id={props.id}
                users={users}
            />
            <Edit
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                nomenclature={props.nomenclature}
                taxes={props.taxes}
                id={props.id}
                users={users}
            />
            <List
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <List.Item
                        style={{
                            background: "#fff",
                            borderRadius: "8px",
                            padding: "16px",
                            cursor: "pointer"
                        }}
                    >
                        <List.Item.Meta
                            title={"Контрагент"}
                            description={
                                <Popover
                                    content={
                                        <Link to={`/users/${item.contractor_id}`}>
                                            {contractorName(item.contractor_id)}
                                        </Link>
                                    }
                                    trigger="hover"
                                >
                                    {contractorName(item.contractor_id)}
                                </Popover>
                            }
                            style={{ minWidth: "200px" }}
                        />
                        <List.Item.Meta
                            title={"Наименование"}
                            style={{ minWidth: "300px" }}
                            description={
                                <Popover
                                    content={
                                        <Link to={`/dict/nomeclature/${item.nomenclature?.id}`}>
                                            {item.nomenclature?.name}
                                        </Link>
                                    }
                                    trigger="hover"
                                >
                                    {item.name}
                                </Popover>
                            }
                        />
                        <List.Item.Meta
                            title={"Кол-во"}
                            style={{ minWidth: "50px" }}
                            description={
                                <span>
                                    {item.quantity} {item.nomenclature?.unit_name}
                                </span>
                            }
                        />
                        <List.Item.Meta
                            title={"Цена"}
                            style={{ minWidth: "150px" }}
                            description={
                                <span>
                                    {item.price} руб. ({item.vat} %)
                                </span>
                            }
                        />
                        <List.Item.Meta
                            title={"Сумма"}
                            style={{ minWidth: "150px" }}
                            description={<span>{total(item.price, item.quantity)} руб.</span>}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    showEditModal(item.id);
                                }}
                                disabled={props.archived}
                            >
                                <EditOutlined />
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    deleteSale(item.id);
                                }}
                                disabled={props.archived}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default Sales;
