import React from "react";
import { DATE_FORMAT } from "constants/globals";
import { Modal, Button, List, Row, Col, Typography } from "antd";
import { SmallCrossIcon } from "utils/svg";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteItem, clipItem } from "actions/reports";
import { CheckOutlined } from "@ant-design/icons";

const Reports = (props) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        props.setIsReportModalOpen();
    };

    const removeReport = (reportId) => {
        dispatch(deleteItem(props.id, reportId));
    };

    const clipReport = (reportId) => {
        dispatch(clipItem(props.id, reportId));
    };

    return (
        <div>
            <Modal title="Рапорта" open={props.isReportModalOpen} footer={""} onCancel={handleCancel} width={1000}>
                <Row gutter={30}>
                    <Col span={12}>
                        <Typography.Title level={4}>Прикрепленные</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={props.item.reports}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Link to={`/documents/reports/${item.id}`}>
                                                <h3>{item.number}</h3>
                                            </Link>
                                        }
                                        description={`Дата: ${dayjs(item.begin_at).format(DATE_FORMAT)} - ${dayjs(item.end_at).format(DATE_FORMAT)}`}
                                    />
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        {item.file_url ? (
                                            <Link target="_blank" to={item.file_url}>
                                                <Button type="primary">Показать рапорт</Button>
                                            </Link>
                                        ) : (
                                            <Button type="primary" disabled>
                                                Файл отсутствует
                                            </Button>
                                        )}
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                removeReport(item.id);
                                            }}
                                            style={{ width: "60px" }}
                                            disabled={props.item.archived}
                                        >
                                            <SmallCrossIcon />
                                        </Button>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={12}>
                        <Typography.Title level={4}>Возможные рапорта</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={props.item.relevant_reports}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Link to={`/documents/reports/${item.id}`}>
                                                <h3>{item.number}</h3>
                                            </Link>
                                        }
                                        description={`Дата: ${dayjs(item.begin_at).format(DATE_FORMAT)} - ${dayjs(item.end_at).format(DATE_FORMAT)}`}
                                    />
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        {item.file_url ? (
                                            <Link target="_blank" to={item.file_url}>
                                                <Button type="primary">Показать рапорт</Button>
                                            </Link>
                                        ) : (
                                            <Button type="primary" disabled>
                                                Файл отсутствует
                                            </Button>
                                        )}
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                clipReport(item.id);
                                            }}
                                            style={{ width: "60px" }}
                                            disabled={props.item.archived}
                                        >
                                            <CheckOutlined />
                                        </Button>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default Reports;
