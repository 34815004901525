import axios from "axios";
import {
    DEVICES_LIST,
    DEVICES_DATA_ARE_LOADING,
    DEVICE_BY_ID,
    STORED_DEVICE_BY_ID,
    DEVICE_BY_ID_LOADING,
    DEVICE_BUTTON_DISPLAY_STATUS,
    DEVICE_ADD
} from "../constants/devices";
import { message } from "antd";
import { API_URL } from "../config/conf";

const API_SECTION = "devices";

export function fetchDevices() {
    return (dispatch) => {
        axios.get(`${API_URL}profile/devices`).then((response) => {
            dispatch({ type: "DEVICES", payload: response.data });
        });
    };
}

export function deleteDevice(id) {
    return (dispatch) => {
        axios.delete(`${API_URL}profile/devices/${id}`).then(() => {
            dispatch(fetchDevices());
            dispatch(message.success("Устройство удалено"));
        });
    };
}

// НЕПЕРЕПИСАННЫЕ

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function fetchDevicesData() {
    return (dispatch) => {
        dispatch(DevicesDataAreLoading(true));

        axios
            .get(`${API_URL}${API_SECTION}`)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                return response;
            })
            .then((response) => {
                dispatch(fetchDevicesDataSuccess(response.data));
                dispatch(DevicesDataAreLoading(false));

                return response;
            });
    };
}

function fetchDevicesDataSuccess(data) {
    return {
        type: DEVICES_LIST,
        data
    };
}

function DevicesDataAreLoading(bool) {
    return {
        type: DEVICES_DATA_ARE_LOADING,
        bool
    };
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function fetchDeviceById(id) {
    return (dispatch) => {
        dispatch(DeviceByIdAreLoading(true));
        dispatch(DeviceButtonDisplay(false));

        axios
            .get(`${API_URL}${API_SECTION}/${id}`)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                return response;
            })
            .then((response) => {
                dispatch(fetchDeviceIdSuccess(response.data));
                dispatch(DeviceByIdAreLoading(false));
                return response;
            })
            .then((response) => dispatch(storedDeviceData(response.data)));
    };
}

function fetchDeviceIdSuccess(item) {
    return {
        type: DEVICE_BY_ID,
        item
    };
}

function storedDeviceData(item) {
    return {
        type: STORED_DEVICE_BY_ID,
        item
    };
}

function DeviceByIdAreLoading(bool) {
    return {
        type: DEVICE_BY_ID_LOADING,
        bool
    };
}

function DeviceButtonDisplay(bool) {
    return {
        type: DEVICE_BUTTON_DISPLAY_STATUS,
        bool
    };
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function addDevice(values) {
    return (dispatch) => {
        axios.post(`${API_URL}${API_SECTION}`, values).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(addDeviceSuccess(response.data));

            return response;
        });
    };
}

function addDeviceSuccess(data) {
    return {
        type: DEVICE_ADD,
        data
    };
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function updateDevice(item) {
    return (dispatch) => {
        dispatch(DeviceButtonDisplay(false));

        axios.patch(`${API_URL}${API_SECTION}/${item.id}`, item).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }

            dispatch(updateDeviceSuccess(response.data));
            dispatch(storedDeviceData(response.data));

            return response;
        });
    };
}

function updateDeviceSuccess(item) {
    return {
        type: DEVICE_BY_ID,
        item
    };
}
