import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "EQUIPMENTS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function activeList(state = [], action) {
    switch (action.type) {
        case "EQUIPMENTS_ACTIVE_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "EQUIPMENTS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function mileages(state = [], action) {
    switch (action.type) {
        case "MILEAGES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function mileage(state = [], action) {
    switch (action.type) {
        case "MILEAGES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function numbers(state = [], action) {
    switch (action.type) {
        case "NUMBERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function number(state = [], action) {
    switch (action.type) {
        case "NUMBERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function services(state = [], action) {
    switch (action.type) {
        case "SERVICES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function service(state = [], action) {
    switch (action.type) {
        case "SERVICES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function insurances(state = [], action) {
    switch (action.type) {
        case "INSURANCES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function insurance(state = [], action) {
    switch (action.type) {
        case "INSURANCES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export const equipmentsReducers = () =>
    combineReducers({
        list: list,
        activeList: activeList,
        item: item,
        mileages: mileages,
        mileage: mileage,
        numbers: numbers,
        number: number,
        services: services,
        service: service,
        insurances: insurances,
        insurance: insurance
    });
