import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import {
    addPurchasesPosition,
    deletePurchasesPosition,
    fetchItem,
    fetchPurchasesPosition,
    updateItem,
    updatePurchasesPosition
} from "actions/purchases";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CurrencyFormat } from "utils/currency";
import { Typography, Card, Form, Button, Select, Input, DatePicker } from "antd";
import Positions from "components/positions";
import dayjs from "dayjs";

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.purchases.item);
    const users = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.managers);

    useEffect(() => {
        dispatch(fetchItem(id));
    }, [id]);

    useEffect(() => {
        const new_item = {
            ...item,
            date: dayjs(item.date)
        };
        form.setFieldsValue(new_item);
    });

    const onFinish = (values) => {
        const val = { ...values };
        dispatch(updateItem(id, val));
    };

    const checkType = (item) => {
        switch (item) {
            case "order":
                return <div>Заказ</div>;
            case "resell":
                return <div>Перепродажа</div>;
            case "equipment":
                return <div>Техника</div>;
            case "common":
                return <div>Общая</div>;
        }
    };

    return (
        <>
            <Typography.Title level={2}>Приход {item.id}</Typography.Title>
            <Card
                extra={[
                    <Link to="/purchases/shipments">
                        <Button type="primary">Назад</Button>
                    </Link>
                ]}
            >
                <Form autoComplete="off" colon={false} labelCol={{ span: 3 }} onFinish={onFinish} form={form}>
                    <Form.Item label="Тип" name="group">
                        {checkType(item.group)}
                    </Form.Item>

                    <Form.Item label="Сумма" name="amount">
                        <CurrencyFormat value={item.amount} />
                    </Form.Item>

                    <Form.Item label="Дата и время" name="date" rules={[{ required: true, message: "Заполните поле" }]}>
                        <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                    </Form.Item>

                    <Form.Item label="Контрагент" name="contractor_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            showSearch
                            placeholder="Выберите контрагента"
                            allowClear
                            filterOption={(input, option) => (option?.children ?? "").toString().toLowerCase().includes(input.toLowerCase())}
                        >
                            {users?.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Менеджер" name="responsible_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            placeholder="Не указано"
                            showSearch
                            filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        >
                            {managers.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Комментарий" name="comment">
                        <Input.TextArea placeholder="Не указано" />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                        Обновить
                    </Button>
                </Form>
            </Card>
            <br />
            <br />
            <Positions
                type="purchases"
                fetchPosition={fetchPurchasesPosition}
                updatePosition={updatePurchasesPosition}
                deletePosition={deletePurchasesPosition}
                addPosition={addPurchasesPosition}
                id={id}
                list={item.positions}
            />
        </>
    );
};

export default Item;
