import React, { useEffect } from "react";
import { Typography, Button, Tabs } from "antd";
import { useParams, Link } from "react-router-dom";
import Orders from "./orders";
import Accruals from "./accruals";
import { useDispatch, useSelector } from "react-redux";
import { fetchItem } from "actions/performers";

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const item = useSelector((state) => state.performers.item);

    useEffect(() => {
        dispatch(fetchItem(id));
    }, []);

    const button = (
        <Link to="/performers">
            <Button type="primary">Назад</Button>
        </Link>
    );

    const tabs = [
        {
            key: "1",
            label: "Начисления",
            children: <Accruals id={id} />
        },
        {
            key: "2",
            label: "Выплаты",
            children: "выплаты"
        },
        {
            key: "3",
            label: "Информация",
            children: "инфо"
        },
        {
            key: "4",
            label: "Документы",
            children: "документы"
        },
        {
            key: "5",
            label: "Заказы",
            children: <Orders />
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>{item.name}</Typography.Title>
            <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={button} />
        </div>
    );
};

export default Item;
