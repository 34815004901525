import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmail, fetchMails } from "actions/users";
import { Button, List, Card, Space } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Add from "./add";

const Mails = (props) => {
    const dispatch = useDispatch();
    const mails = useSelector((state) => state.users.mails);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchMails(props.id));
    }, []);

    const showAddModal = () => {
        setIsAddModalOpen(true);
    };

    return (
        <div>
            <Add id={props.id} isAddModalOpen={isAddModalOpen} setIsAddModalOpen={setIsAddModalOpen} />
            <Card
                title="Почта"
                extra={
                    <Button type="primary" onClick={showAddModal} disabled={props.archived}>
                        <PlusOutlined />
                    </Button>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={mails}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                padding: "16px"
                            }}
                        >
                            <List.Item.Meta title={item.name} style={{ minWidth: "50px" }} />
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        dispatch(deleteEmail(props.id, item.id));
                                    }}
                                    disabled={props.archived}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Space>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

export default Mails;
