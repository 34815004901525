import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Space } from "antd";
import { Link } from "react-router-dom";
import { login } from "actions/login";
import AuthLayout from "../layout/auth_layout";
import { MaskedInput } from "antd-mask-input";

const Login = () => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(login(values));
    };

    return (
        <AuthLayout>
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} onFinish={onFinish} autoComplete="on">
                <h2 style={{ textAlign: "center" }}>Вход</h2>
                <Form.Item label="Телефон" name="email" rules={[{ required: true, message: "Введите номер телефона" }]}>
                    <MaskedInput mask="+70000000000" />
                </Form.Item>

                <Form.Item label="Пароль" name="password" rules={[{ required: true, message: "Введите пароль" }]}>
                    <Input type="password" />
                </Form.Item>

                <Space style={{ alignItems: "center", display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <Link to="/recovery">Восстановить</Link>
                    <Button type="primary" htmlType="submit" style={{ maxHeight: "32px" }}>
                        Вход
                    </Button>
                </Space>
            </Form>
        </AuthLayout>
    );
};

export default Login;
