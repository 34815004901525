import axios from "axios";
import * as message from "./handle_messages";
import { history } from "../store";
import { API_URL } from "../config/conf";

export function fetchList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}purchases/purchases`, { params: { ...item } }).then((response) => {
            dispatch({ type: "PURCHASES_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "PURCHASES_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "PURCHASES_FILTER_VALUES", payload: null });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}purchases/purchases/${id}`).then((response) => {
            dispatch({ type: "PURCHASES_ITEM", payload: response.data });
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}purchases/purchases/${id}`, item).then(() => {
            dispatch(message.success("Приход обновлен"));
            history.push("/purchases/shipments");
        });
    };
}

export function fetchPurchasesPosition(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}purchases/purchases/${id}/positions/${itemId}`).then((response) => {
            dispatch({ type: "PURCHASE_POSITIONS_ITEM", payload: response.data });
        });
    };
}

export function addPurchasesPosition(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}purchases/purchases/${id}/positions`, item).then((response) => {
            dispatch({ type: "PURCHASES_ITEM", payload: response.data });
        });
    };
}

export function updatePurchasesPosition(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}purchases/purchases/${id}/positions/${itemId}`, item).then((response) => {
            dispatch(message.success("Позиция обновлена"));
            dispatch({ type: "PURCHASES_ITEM", payload: response.data });
        });
    };
}

export function deletePurchasesPosition(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}purchases/purchases/${id}/positions/${itemId}`).then((response) => {
            dispatch(message.success("Позиция удалена"));
            dispatch({ type: "PURCHASES_ITEM", payload: response.data });
        });
    };
}
