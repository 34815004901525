import React from "react";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { addItem } from "actions/taxes"
import { useDispatch } from "react-redux";

const Add = () => {
	const dispatch = useDispatch();
	
	const onFinish = (values) => {
		dispatch(addItem(values))
	};

	return (
		<Card title="Создание ставки" extra={<Link to="/dict/taxes"><Button type='primary'>Назад</Button></Link>}>
			<Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 4 }}>
				<Form.Item label="Значение ставки" name="nds">
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
			</Form>
		</Card>
	);
};

export default Add;
