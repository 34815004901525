import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { Card, Form, Button, Select, Input, DatePicker, Radio } from "antd";
import { Link } from "react-router-dom";
import { addItem } from "actions/offers";

const Add = () => {
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.activeManagers);
    const cities = useSelector((state) => state.globals.citiesActive);
    const user = useSelector((state) => state.globals.current_user);

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(addItem(val));
    };

    const initialValues = { status: "recent" };

    return (
        <Card
            title="Создание заявки"
            extra={
                <Link to="/offers">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form
                autoComplete="off"
                labelCol={{ span: 5 }}
                onFinish={onFinish}
                initialValues={initialValues}
                fields={[
                    {
                        name: ["manager_id"],
                        value: user?.id
                    }
                ]}
            >
                <Form.Item label="Дата и время работ" name="date" rules={[{ required: true, message: "Заполните поле" }]}>
                    <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                </Form.Item>

                <Form.Item label="Статус" name="status">
                    <Radio.Group>
                        <Radio.Button value="recent">Новая</Radio.Button>
                        <Radio.Button value="approved">В работе</Radio.Button>
                        <Radio.Button value="canceled">Завершенная</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {clients?.length ? (
                    <Form.Item label="Клиент" name="user_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            showSearch
                            placeholder="Выберите клиента"
                            allowClear
                            filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        >
                            {clients.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item label="Клиент">
                        <Input type="text" placeholder="Нет клиентов" disabled />
                    </Form.Item>
                )}

                {cities?.length ? (
                    <Form.Item label="Город" name="city_id" rules={[{ required: true, message: "Выберите город" }]}>
                        <Select placeholder="Не указано">
                            {cities.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item label="Город">
                        <Input type="text" placeholder="Нет городов" disabled />
                    </Form.Item>
                )}

                <Form.Item label="Адрес" name="address" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Описание" name="description" rules={[{ required: true, message: "Заполните описание" }]}>
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Менеджер" name="manager_id" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Select showSearch filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}>
                        {managers.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                    Создать
                </Button>
            </Form>
        </Card>
    );
};

export default Add;
