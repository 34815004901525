import React from "react";
import { Routes, Route } from "react-router-dom";
import Shipments from "./shipments";
import Bills from "./bills";
import MainLayout from "components/layout";

const Sales = () => {
    return (
        <MainLayout>
            <Routes>
                <Route path="shipments/*" element={<Shipments />} />
                <Route path="bills/*" element={<Bills />} />
            </Routes>
        </MainLayout>
    );
};

export default Sales;
