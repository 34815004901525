import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDevice, fetchDevices } from "actions/devices";
import dayjs from "dayjs";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { Button, Table } from "antd";

const Devices = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state) => state.devices.devices);

    useEffect(() => {
        dispatch(fetchDevices());
    }, []);

    const removeDevice = (id) => {
        dispatch(deleteDevice(id));
    };

    const columns = [
        {
            title: <div className="header__title">Клиент</div>,
            dataIndex: "client",
            key: "client",
            render: (text) => {
                return (
                    <div>
                        {text.name} {text.version} {text.os_name} {text.os_version}
                    </div>
                );
            }
        },
        {
            title: "User-agent",
            dataIndex: "user_agent",
            key: "user_agent",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Последний вход",
            dataIndex: "updated_at",
            key: "updated_at",
            render: (text) => {
                return (
                    <div style={{ minWidth: "200px" }}>
                        {dayjs(text).format(DATE_AND_TIME_FORMAT)}
                    </div>
                );
            }
        },
        {
            title: "Доступ",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            removeDevice(text);
                        }}
                    >
                        Удалить
                    </Button>
                );
            }
        }
    ];

    return (
        <Table
            className={"data-table"}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={devices}
            locale={{ emptyText: "Нет данных" }}
        />
    );
};

export default Devices;
