import React from "react";
import { Card, Form, Select, Input, DatePicker, Button } from "antd";
import { Link } from "react-router-dom";
import { fetchUserObjects } from "actions/objects";
import { useDispatch, useSelector } from "react-redux";
import { DATE_AND_TIME_FORMAT } from "constants/globals";

const Material = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const clientObjects = useSelector(state => state.objects.userObjects);

    const onClientChange = (id) => {
        form.setFieldsValue({
            project_id: ""
        });
        dispatch(fetchUserObjects(id));
    };

    return (
        <Card title="Создание Заказа" extra={<Link to="/orders"><Button type='primary'>Назад</Button></Link>}>
            <Form autoComplete="off" labelCol={{ span: 5 }} form={form} onFinish={(values) => {
                props.onFinish(values, "supply")
            }}
                fields={
                    [
                        {
                            name: ["manager_id"],
                            value: props.user?.id
                        },
                    ]}
            >

                <Form.Item label="Дата и время работ" name="work_at" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                </Form.Item>

                <Form.Item label="Клиент" name="client_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Select
                        showSearch
                        onChange={onClientChange}
                        placeholder="Выберите клиента"
                        allowClear
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? '').includes(input.toLowerCase())}
                    >
                        {props.clients.map((item) => (<Select.Option label={item.name + " " + item.phone} key={item.id} value={item.id}>{item.name + " " + item.phone}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Form.Item label="Адрес" name="address" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                {clientObjects?.length ? (
                    <Form.Item label="Объект" name="project_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                        <Select placeholder="Не указано">
                            {clientObjects.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item label="Объект" >
                        <Input type="text" placeholder="У конграгента нет объктов" disabled />
                    </Form.Item>
                )}

                <Form.Item label="Описание" name="description">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Менеджер" name="manager_id" rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? '').includes(input.toLowerCase())}
                    >
                        {props.managers.map((item) => (<Select.Option label={item.name} key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
            </Form>
        </Card >
    );
};

export default Material;