import React, { useState, useEffect } from "react";
import { Card, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchNumbers, fetchNumber } from "actions/equipments";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import Add from "./add";
import Edit from "./edit";

const Numbers = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const list = useSelector(state => state.equipments.numbers);
  const users = useSelector((state) => state.users.data);

  useEffect(() => {
    dispatch(fetchNumbers(id));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showItemModal = (item) => {
    dispatch(fetchNumber(id, item.id));
    setIsItemModalOpen(true)
  };

  const contractorName = (value) => {
    if (value !== null) {
      const user = users.filter((item) => item.id === value.id);
      return user[0]?.name;
    }
    return "Нет ответственного";
  };

  const columns = [
    {
      title: "Дата",
      dataIndex: 'date',
      key: 'date',
      render: (text) => {
        return <div
          style={{ width: '84px' }}
        >
          {dayjs(text).format(DATE_FORMAT)}
        </div>
      }
    },
    {
      title: "Госномер",
      dataIndex: 'number',
      key: 'number',
      render: (text) => {
        return <div
          style={{ width: '84px' }}
        >
          {text}
        </div>
      }
    },
    {
      title: "Ответственный",
      dataIndex: 'user',
      key: 'user',
      render: (text) => {
        return <div>
          {contractorName(text)}
        </div>
      }
    }
  ];

  return (
    <Card title="Номера" extra={<Button type='primary' onClick={showModal}>Создать</Button>}>
      <Add setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={id} />
      <Edit setIsItemModalOpen={setIsItemModalOpen} isItemModalOpen={isItemModalOpen} id={id} />
      <Table
        className={'data-table data-table'}
        bordered
        pagination={false}
        rowKey='id'
        columns={columns}
        dataSource={list}
        locale={{ 'emptyText': 'Нет данных' }}
        onRow={(item) => {
          return {
            onClick: () => { showItemModal(item) },
          };
        }}
      />
    </Card>
  );
};

export default Numbers;