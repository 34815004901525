import axios from "axios";
import * as messages from "./handle_messages";
import { history } from "store";
import { API_URL } from "config/conf";
import { getToken } from "actions/login";

export function fetchList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}documents/reports`, { params: { ...item } }).then((response) => {
            dispatch({ type: "REPORTS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}documents/reports/${id}`).then((response) => {
            dispatch({ type: "REPORTS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "REPORTS_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "REPORTS_FILTER_VALUES", payload: null });
    };
}

export function fetchFile(id) {
    return (dispatch) => {
        axios.get(`${API_URL}documents/reports/${id}/pdf`).then((response) => {
            dispatch({ type: "REPORT_FILE", payload: response.data.url });
        });
    };
}

export function deleteFile(id) {
    return (dispatch) => {
        axios.post(`${API_URL}documents/reports/${id}/remove_file`).then((response) => {
            dispatch({ type: "REPORTS_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    if (item.file === undefined) {
        return (dispatch) => {
            axios.post(`${API_URL}documents/reports`, item).then((response) => {
                dispatch({ type: "REPORTS_ITEM", payload: response.data });
                dispatch(messages.success("Рапорт добавлен"));
            });
        };
    } else {
        const formData = new FormData();

        for (var key in item) {
            formData.append(key, item[key]);
        }

        return (dispatch) => {
            axios
                .post(`${API_URL}documents/reports`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `JWT ${getToken()}`
                    }
                })
                .then((response) => {
                    dispatch({ type: "REPORTS_ITEM", payload: response.data });
                    dispatch(messages.success("Запись добавлена и файл-рапорт загружен"));
                    history.push("/documents/reports");
                });
        };
    }
}

export function deleteItem(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}orders/${id}/reports/${itemId}`).then((response) => {
            dispatch({ type: "ORDERS_ITEM", payload: response.data });
        });
    };
}

export function clipItem(id, itemId) {
    return (dispatch) => {
        axios.post(`${API_URL}orders/${id}/reports`, { id: itemId }).then((response) => {
            dispatch({ type: "ORDERS_ITEM", payload: response.data });
        });
    };
}

export function updateItem(item) {
    if (item.file === undefined) {
        return (dispatch) => {
            axios.patch(`${API_URL}documents/reports/${item.id}`, item).then((response) => {
                dispatch({ type: "REPORTS_ITEM", payload: response.data });
                dispatch(messages.success("Запись сохранена"));
            });
        };
    } else {
        const formData = new FormData();

        for (var key in item) {
            formData.append(key, item[key]);
        }

        return (dispatch) => {
            axios
                .patch(`${API_URL}documents/reports/${item.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `JWT ${getToken()}`
                    }
                })
                .then((response) => {
                    dispatch({ type: "REPORTS_ITEM", payload: response.data });
                    dispatch(messages.success("Запись сохранена и файл-рапорт загружен"));
                });
        };
    }
}
