import React from "react";
import Own from "./own";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "actions/reports";
import Subcontractor from "./subcontractor";
import { Tabs } from "antd";

const Add = () => {
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.activeManagers);
    const cities = useSelector((state) => state.globals.citiesActive);
    const equipments = useSelector((state) => state.equipments.activeList);
    const performers = useSelector((state) => state.users.activePerformers);
    const user = useSelector((state) => state.globals.current_user);

    const onFinish = (values) => {
        let val = {
            ...values,
            begin_at: values.date[0].format(),
            end_at: values.date[1].format(),
            date: "",
            comment: values.comment ? values.comment : ""
        };

        dispatch(addItem(val));
    };

    const items = [
        {
            key: "1",
            label: "Собственные",
            children: (
                <Own
                    cities={cities}
                    clients={clients}
                    managers={managers}
                    equipments={equipments}
                    performers={performers}
                    onFinish={onFinish}
                    user={user}
                />
            )
        },
        {
            key: "2",
            label: "Субподрядчик",
            children: <Subcontractor cities={cities} clients={clients} managers={managers} equipments={equipments} onFinish={onFinish} user={user} />
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    );
};

export default Add;
