import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkCompanyINN } from "actions/users";
import { Form, Input, Button, Switch } from "antd";

const Company = (props) => {
    const dispatch = useDispatch();
    const checkedINN = useSelector((state) => state.users.checkedCompanyINN);

    useEffect(() => {
        if (checkedINN) {
            props.form.setFieldsValue(checkedINN);
        }
    }, [checkedINN]);

    const validateINN = async () => {
        const inn = props.form.getFieldValue("inn");
        dispatch(checkCompanyINN(inn));
    };

    return (
        <div>
            <Form autoComplete="off" onFinish={props.onFinish} form={props.form} labelCol={{ span: 6 }}>
                <Form.Item label="ИНН" name="inn" rules={[{ pattern: /^\d+$/, message: "Только цифры" }]}>
                    <Input.Search placeholder="Не указано" enterButton="Проверить ИНН" size="middle" maxLength={10} onSearch={validateINN} />
                </Form.Item>

                <Form.Item initialValue="Юридическое лицо" label="Тип" name="kind_text">
                    <Input disabled />
                </Form.Item>

                <Form.Item label="Наименование" name="name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Наименование для документов" name="official_name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="КПП" name="kpp">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="ОГРН" name="ogrn">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Юридический адрес" name="legal_address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Почтовый адрес" name="postal_address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Фактический адрес" name="address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Адрес для документов" name="address_for_docs">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Электронная почта" name="email">
                    <Input placeholder="Не указано" type="email" />
                </Form.Item>

                <Form.Item label="Телефон" name="phone">
                    <Input />
                </Form.Item>

                <Form.Item label="Описание" name="comment">
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label="Вход в систему" name="locked" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                    Сохранить
                </Button>
            </Form>
        </div>
    );
};

export default Company;
