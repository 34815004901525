import { combineReducers } from 'redux';

export function list(state = [], action) {
    switch (action.type) {
        case "STATEMENTS_LIST":
            return action.payload;
        default:
            return state;
    };
};

export function item(state = [], action) {
    switch (action.type) {
        case "STATEMENT_ITEM":
            return action.payload;
        default:
            return state;
    };
};

export const banksReducers = () => combineReducers({
    list: list,
    item: item
});