import { combineReducers } from "redux";

export function item(state = [], action) {
    switch (action.type) {
        case "PERFORMERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function accruals(state = [], action) {
    switch (action.type) {
        case "PERFORMERS_ACCRUALS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function orders(state = [], action) {
    switch (action.type) {
        case "PERFORMERS_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export const performersReducers = () =>
    combineReducers({
        item: item,
        orders: orders,
        accruals: accruals
    });
