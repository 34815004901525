import axios from "axios";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";
import { history } from "store";

export function fetchList() {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}accruals`).then((response) => {
            dispatch({ type: "ACCRUALS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}accruals/${id}`).then((response) => {
            dispatch({ type: "ACCRUALS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}accruals`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Начисление создано"));
            history.push("/accruals");
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}accruals/${id}`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Начисление обновлено"));
            history.push("/accruals");
        });
    };
}
