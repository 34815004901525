import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { globalsReducers } from "./globals";
import { usersReducers } from "./users";
import { ordersReducers } from "./orders";
import { equipmentsReducers } from "./equipments";
import { nomenclatureReducers } from "./nomenclature";
import { taxesReducers } from "./taxes";
import { reportsReducers } from "./reports";
import { salesReducers } from "./sales";
import { agreementsReducers } from "./agreements";
import { implementationsReducers } from "./implementations";
import { equipmentсategoriesReducers } from "./equipment_categories";
import { nomсategoriesReducers } from "./nomenclature_categories";
import { regionsReducers } from "./regions";
import { citiesReducers } from "./cities";
import { devicesReducers } from "./devices";
import { offersReducers } from "./offers";
import { objectsReducers } from "./objects";
import { messagesReducers } from "./handler_messages";
import { notificationsReducers } from "./notifications";
import { billsReducers } from "./bills";
import { purchasesReducers } from "./purchases";
import { performersReducers } from "./performers";
import { managersReducers } from "./managers";
import { apikeysReducers } from "./api_keys";
import { cashReducers } from "./cash";
import { notificationtypesReducers } from "./notification_types";
import { unitsReducers } from "reducers/units";
import { banksReducers } from "./bank";
import { accrualsReducer } from "./accruals";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        globals: globalsReducers(),
        users: usersReducers(),
        orders: ordersReducers(),
        equipments: equipmentsReducers(),
        nomenclature: nomenclatureReducers(),
        taxes: taxesReducers(),
        reports: reportsReducers(),
        sales: salesReducers(),
        reports: reportsReducers(),
        equipmentcategories: equipmentсategoriesReducers(),
        nomсategories: nomсategoriesReducers(),
        regions: regionsReducers(),
        cities: citiesReducers(),
        devices: devicesReducers(),
        offers: offersReducers(),
        objects: objectsReducers(),
        messages: messagesReducers(),
        notifications: notificationsReducers(),
        bills: billsReducers(),
        agreements: agreementsReducers(),
        implementations: implementationsReducers(),
        purchases: purchasesReducers(),
        performers: performersReducers(),
        managers: managersReducers(),
        api_keys: apikeysReducers(),
        cash: cashReducers(),
        notificationtypes: notificationtypesReducers(),
        units: unitsReducers(),
        banks: banksReducers(),
        accruals: accrualsReducer()
    });
