import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchItem, fetchUserOrders, fetchUserObjects, fetchUserBankAccounts } from "actions/users";
import Typography from "antd/es/typography/Typography";
import { Link } from "react-router-dom";
import Orders from "./orders";
import Info from "./info";
import Objects from "./objects";
import Numbers from "./numbers";
import Mails from "./mails";
import BankAccounts from "./bank_accounts";
import Access from "./access";

const Item = () => {
    const user = useSelector((state) => state.users.item);
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(fetchUserOrders(id));
        dispatch(fetchItem(id));
        dispatch(fetchUserObjects(id));
        dispatch(fetchUserBankAccounts(id));
    }, [id]);

    const button = (
        <Link to="/users">
            <Button type="primary">Назад</Button>
        </Link>
    );
    const tabs = [
        {
            key: "1",
            label: "Информация",
            children: <Info user={user} />
        },
        {
            key: "2",
            label: "Почта",
            children: <Mails user={user} id={id} />
        },
        {
            key: "3",
            label: "Телефоны",
            children: <Numbers user={user} id={id} />
        },
        {
            key: "4",
            label: "Доступ",
            children: <Access user={user} id={id} />
        },
        {
            key: "5",
            label: `Заказы`,
            children: <Orders />
        },
        {
            key: "6",
            label: "Объекты",
            children: <Objects id={id} />
        },
        {
            key: "7",
            label: "Банковские счета",
            children: <BankAccounts id={id} />
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>{user.name}</Typography.Title>
            <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={button} />
        </div>
    );
};

export default Item;
