import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Typography, Button, Select, Switch } from "antd";
import { addContractor, fetchItem, updateItem } from "actions/bank";
import Loading from "utils/loading";

const Item = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.data);
    const item = useSelector((state) => state.banks.item);

    useEffect(() => {
        dispatch(fetchItem(id));
    }, [id]);

    useEffect(() => {
        form.setFieldsValue({ ...item, contractor_id: item.contractor?.id });
    });

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(updateItem(id, val));
    };

    if (!item.document) {
        return <Loading />;
    }

    const addContractorHandler = () => {
        dispatch(addContractor(id, { inn: item.inn }));
    };

    return (
        <>
            <Typography.Title level={2}>{item.kind === "credit" ? "Списание со счета" : "Поступление на счет"}</Typography.Title>
            <Card
                extra={
                    <Link to="/bank/statements">
                        <Button type="primary">Назад</Button>
                    </Link>
                }
            >
                <Form autoComplete="off" colon={false} labelCol={{ span: 4 }} onFinish={onFinish} form={form}>
                    <Form.Item label="Контрагент по выписке">
                        <span>{item.kind === "credit" ? item.document.beneficiaryInfo.name : item.document.payerInfo.name}</span>
                    </Form.Item>

                    <Form.Item label="Сумма">
                        <span>{item.amount} рублей.</span>
                    </Form.Item>

                    <Form.Item label="Назначение платежа" name="purpose">
                        <span>{item.document.paymentPurpose}</span>
                    </Form.Item>

                    <Form.Item label="ИНН">
                        <span>{item.inn}</span>
                    </Form.Item>

                    <Form.Item label="Контрагент в системе" name="contractor_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            showSearch
                            allowClear
                            filterOption={(input, option) => (option?.children ?? "").toString().toLowerCase().includes(input.toLowerCase())}
                        >
                            {users.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Контрагент" name="has_contractor">
                        {item.has_contractor ? (
                            <div>Есть в базе данных</div>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => {
                                    addContractorHandler();
                                }}
                            >
                                Создать контрагента
                            </Button>
                        )}
                    </Form.Item>

                    <Form.Item label="Отмечено" name="review" valuePropName="checked" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Switch />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                        Сохранить
                    </Button>
                </Form>
            </Card>
        </>
    );
};

export default Item;
