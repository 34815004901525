import React from "react";
import { Card, Table, Popover, Tag } from "antd";
import dayjs from "dayjs";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { useSelector } from "react-redux";
import { CurrencyFormat } from "../../../utils/currency";

const Orders = () => {
    const orders = useSelector((state) => state.users.orders.orders);

    const columns = [
        {
            title: "Номер",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Время",
            dataIndex: "work_at",
            key: "work_at",
            render: (text, item) => {
                return (
                    <div>
                        <div>{dayjs(text).format(DATE_AND_TIME_FORMAT)}</div>
                        <div style={{ fontSize: 12, color: "#777" }}>{item.client?.phone}</div>
                    </div>
                );
            }
        },
        {
            title: "Сумма",
            dataIndex: "income_operations_amount",
            key: "income_operations_amount",
            render: (text) => {
                return <CurrencyFormat value={text.toString()} />;
            }
        },
        {
            title: "Клиент",
            dataIndex: "client",
            key: "client",
            render: (text, item) => {
                {
                    switch (item.kind) {
                        case "subcontractor":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Субподрядчик</div>
                                </div>
                            );
                        case "supply":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Материал</div>
                                </div>
                            );
                        case "inner":
                            return <div style={{ fontSize: 12, color: "#777" }}>Внутренняя</div>;
                        case "equipment":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Техника</div>
                                </div>
                            );
                        default:
                            "";
                    }
                }
            }
        },
        {
            title: "Техника",
            dataIndex: "equipment",
            key: "equipment",
            render: (text, item) => {
                return (
                    <div>
                        {text ? (
                            <Popover content={text.name} trigger="hover">
                                {text.number} {item.performer ? <div style={{ fontSize: 12, color: "#777" }}>{item.performer.name}</div> : null}
                            </Popover>
                        ) : item.kind === "supply" ? (
                            <div>Материал</div>
                        ) : (
                            <div>
                                Субподрядчик
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>
                                    {item.subcontractor ? item.subcontractor.name : "не выбран"}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                let icon = null;
                switch (text) {
                    case "processing":
                        icon = <Tag color="#f50">В работе</Tag>;
                        break;
                    case "completed":
                        icon = <Tag color="#87d068">Завершен</Tag>;
                        break;
                    case "canceled":
                        icon = <Tag color="#777777">Отменена</Tag>;
                        break;
                }

                return icon;
            }
        },
        {
            title: "Отгрузка",
            dataIndex: "implementation_id",
            key: "implementation_id",
            render: (text) => {
                let status;
                switch (text) {
                    case null:
                        status = <Tag color="#f50">Не отгружен</Tag>;
                        break;
                    default:
                        status = <Tag color="#87d068">Отгрузка № {text}</Tag>;
                        break;
                }
                return status;
            }
        }
    ];

    return (
        <Card title="Заказы">
            <Table
                className={"data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={orders}
                locale={{ emptyText: "Нет данных" }}
            />
        </Card>
    );
};

export default Orders;
