import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "components/layout";
import List from "../../components/dict/regions";
import Item from "../../components/dict/regions/item";
import Add from "../../components/dict/regions/add";

const Regions = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/add" element={<Add />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Regions;
