import { Layout, Card, Col, Row } from 'antd';

const { Content } = Layout;

const AuthLayout = ({ children }) => {
  return (
    <Content style={{ marginTop: 150 }}>
      <Row>
        <Col span={8} offset={8}>
          <Card style={{ maxWidth: "500px", background: "#f5f5f5", margin: "0 auto" }}>
            {children}
          </Card>
        </Col>
      </Row>
    </Content>
  )
}

export default AuthLayout;
