import React, { useEffect } from "react";
import { Button, Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateUserObject } from "actions/users";

const Edit = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const item = useSelector(state => state.objects.item);

  useEffect(() => {
    form.setFieldsValue({
      'name': item.name,
      'description': item.description
    })
  });

  const handleCancel = () => {
    props.setIsItemModalOpen(false);
  };

  const onFinish = (values) => {
    let val = { ...values };
    dispatch(updateUserObject(props.id, item.id, val));
    props.setIsItemModalOpen(false);
  };

  return (
    <Modal
      title="Редактировать объект"
      open={props.isItemModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" onClick={() => { form.submit() }}>Редактировать</Button>,
        <Button key="back" onClick={handleCancel}>Закрыть</Button>
      ]}
      width={700}
    >
      <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} form={form} onFinish={onFinish}>

        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Описание" name="description">
          <Input placeholder="Не указано" />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default Edit;