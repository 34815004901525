import React, { useEffect } from "react";
import { Modal, Form, Button, Input, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "actions/reports";
import { DATE_FORMAT } from "constants/globals";

const Filter = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const filterValues = useSelector((state) => state.reports.filterValues);
    const performers = useSelector((state) => state.users.activePerformers);
    const clients = useSelector((state) => state.users.data);
    const equipments = useSelector((state) => state.equipments.list);

    useEffect(() => {
        if (filterValues) {
            form.setFieldsValue(filterValues);
        } else {
            form.resetFields();
        }
    }, [filterValues]);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let val = {
            ...values,
            begin_at: values.date && values.date[0]?.format(),
            end_at: values.date && values.date[1].format(),
            date: ""
        };
        dispatch(setFilterValues(val));
        form.resetFields();
        props.setIsModalOpen(false);
    };

    return (
        <Modal
            title="Фильтры"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отфильтровать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 3 }} form={form} onFinish={onFinish}>
                <Form.Item label="Дата" name="date">
                    <DatePicker.RangePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item label="Номер" name="number">
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Водитель" name="performer">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {performers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Клиент" name="client">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Техника" name="equipment">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {equipments.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Субподрядчик" name="subcontractor">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Filter;
