import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "actions/equipments";
import Equipment from "./equipment";
import Material from "./material";
import Subcontractor from "./subcontractor";
import Inner from "./inner";
import { addItem } from "actions/orders";

const Add = () => {
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.activeManagers);
    const equipments = useSelector((state) => state.equipments.activeList);
    const performers = useSelector((state) => state.users.activePerformers);
    const user = useSelector((state) => state.globals.current_user);

    useEffect(() => {
        dispatch(fetchList({ active: 1 }));
    }, []);

    const onFinish = (values, type) => {
        let val = {
            ...values,
            kind: type,
            work_at: values.work_at.format()
        };
        dispatch(addItem(val));
    };

    const items = [
        {
            key: "1",
            label: "На технику",
            children: (
                <Equipment clients={clients} managers={managers} equipments={equipments} performers={performers} onFinish={onFinish} user={user} />
            )
        },
        {
            key: "2",
            label: "На материал",
            children: <Material clients={clients} managers={managers} onFinish={onFinish} user={user} />
        },
        {
            key: "3",
            label: "Субподрядчик",
            children: <Subcontractor clients={clients} managers={managers} onFinish={onFinish} user={user} />
        },
        {
            key: "4",
            label: "Внутренняя",
            children: <Inner managers={managers} equipments={equipments} performers={performers} onFinish={onFinish} user={user} />
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    );
};

export default Add;
