import axios from "axios";
import * as messages from "./handle_messages";
import { history } from "store";
import { API_URL } from "config/conf";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}dict/regions`).then((response) => {
            dispatch({ type: "REGIONS_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}dict/regions/${id}`).then((response) => {
            dispatch({ type: "REGIONS_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}dict/regions`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Регион добавлен"));
            history.push("/dict/regions");
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}dict/regions/${item.id}`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Регион обновлен"));
            history.push("/dict/regions");
        });
    };
}
