import React from "react";
import { ActionCableProvider, ActionCableConsumer } from "react-actioncable-provider";
import { getToken } from "actions/login";
import { WEBSOCKET } from "config/conf";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { getCount } from "actions/notifications";

const Notifications = () => {
    const token = getToken();
    const dispatch = useDispatch();

    const infoNotification = (message) => {
        if (message.object_model && message.object_id)
            notification.info({
                message: message.title,
                description: message.body
            });
    };

    const successNotification = (message) => {
        if (message.object_model && message.object_id)
            notification.success({
                message: message.title,
                description: message.body
            });
    };

    const warningNotification = (message) => {
        if (message.object_model && message.object_id)
            notification.warning({
                message: message.title,
                description: message.body
            });
    };

    const errorNotification = (message) => {
        if (message.object_model && message.object_id)
            notification.error({
                message: message.title,
                description: message.body
            });
    };

    const onReceived = (message) => {
        if (message.unread) {
            dispatch(getCount(message.unread));
        };

        if (message.notification) {
            switch (message.notification.kind) {
                case "info":
                    infoNotification(message.notification);
                    break;
                case "warning":
                    warningNotification(message.notification);
                    break;
                case "error":
                    errorNotification(message.notification);
                    break;
                case "success":
                    successNotification(message.notification);
                    break;
                default:
                    alert("Нет таких значений notification_type");
            };
        };
    };

    return (
        <ActionCableProvider url={`${WEBSOCKET}?token=${token}`}>
            <ActionCableConsumer channel="NotificationsChannel" onReceived={onReceived} />
        </ActionCableProvider>
    );
};

export default Notifications;
