import React, { useEffect } from "react";
import { Card, Button, Form, DatePicker, Select, Radio, Input, Typography } from "antd";
import { useParams, Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { fetchItem, updateItem } from "actions/offers";
import Loading from "utils/loading";

const { TextArea } = Input;

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const item = useSelector((state) => state.offers.item);
    const clients = useSelector((state) => state.users.data);
    const cities = useSelector((state) => state.users.cities);
    const managers = useSelector((state) => state.users.managers);
    const loading = useSelector((state) => state.globals.loadingList);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchItem(id));
    }, [id]);

    useEffect(() => {
        let new_item = { ...item, date: dayjs(item.date) };
        form.setFieldsValue(new_item);
    });

    if (loading) {
        return <Loading />;
    }

    const onFinish = (values) => {
        let val = { ...values, id: item.id, date: values.date.format() };
        dispatch(updateItem(val));
    };

    return (
        <div>
            <Typography.Title level={2}>Заявка {item.id}</Typography.Title>
            <Card
                extra={
                    <Link to="/offers">
                        <Button type="primary">Назад</Button>
                    </Link>
                }
            >
                <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} onFinish={onFinish} form={form}>
                    <Form.Item label="Дата и время работ" name="date">
                        <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                    </Form.Item>

                    <Form.Item label="Статус" name="status">
                        <Radio.Group>
                            <Radio.Button value="recent">Новая</Radio.Button>
                            <Radio.Button value="approved">Подтвержденная</Radio.Button>
                            <Radio.Button value="canceled">Отмененная</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {clients?.length ? (
                        <Form.Item label="Клиент" name="user_id" rules={[{ required: true, message: "Заполните поле" }]}>
                            <Select
                                showSearch
                                placeholder="Выберите клиента"
                                allowClear
                                filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                            >
                                {clients.map((item) => (
                                    <Select.Option label={item.name} key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item label="Клиент">
                            <Input type="text" placeholder="Нет клиентов" disabled />
                        </Form.Item>
                    )}

                    {cities?.length ? (
                        <Form.Item label="Город" name="city_id" rules={[{ required: true, message: "Выберите город" }]}>
                            <Select placeholder="Не указано">
                                {cities.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item label="Город">
                            <Input type="text" placeholder="Нет городов" disabled />
                        </Form.Item>
                    )}

                    <Form.Item label="Телефон" name="phone">
                        <Input type="text" placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Эл. почта" name="email">
                        <Input type="text" placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Адрес" name="address">
                        <Input type="text" placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Описание" name="description">
                        <TextArea placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Менеджер" name="manager_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select showSearch filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}>
                            {managers.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                        Обновить
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default Item;
