import React from "react";
import { Modal, Form, Button, Input } from "antd";
import { useDispatch } from "react-redux";
import { sendEmail } from "actions/bills";

const Mail = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCancel = () => {
        props.setIsMailModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(sendEmail(props.id, val));
        props.setIsMailModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Отправить по почте"
            open={props.isMailModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отправить
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 5 }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Электронная почта"
                    name="email"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="" />
                </Form.Item>

                <Form.Item
                    label="Сообщение"
                    name="message"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input.TextArea type="text" placeholder="Сообщение" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Mail;
