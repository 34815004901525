import { combineReducers } from "redux";

export function data(state = [], action) {
    switch (action.type) {
        case "USERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterList(state = [], action) {
    switch (action.type) {
        case "USERS_FILTER_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "USERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function cities(state = [], action) {
    switch (action.type) {
        case "CITIES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function administrations(state = [], action) {
    switch (action.type) {
        case "USER_BY_TYPE_ADMINISTRATIONS":
            return action.payload;
        default:
            return state;
    }
}

export function managers(state = [], action) {
    switch (action.type) {
        case "MANAGERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function activeManagers(state = [], action) {
    switch (action.type) {
        case "MANAGERS_ACTIVE_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function clients(state = [], action) {
    switch (action.type) {
        case "USER_BY_TYPE_CLIENTS":
            return action.payload;
        default:
            return state;
    }
}

export function performers(state = [], action) {
    switch (action.type) {
        case "PERFORMERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function activePerformers(state = [], action) {
    switch (action.type) {
        case "PERFORMERS_ACTIVE_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function checkedIndividualINN(state = [], action) {
    switch (action.type) {
        case "CHECK_INDIVIDUAL_INN":
            return action.payload;
        default:
            return state;
    }
}

export function checkedCompanyINN(state = [], action) {
    switch (action.type) {
        case "CHECK_COMPANY_INN":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "USERS_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function objectsById(state = [], action) {
    switch (action.type) {
        case "USER_OBJECTS_BY_ID":
            return action.payload;
        default:
            return state;
    }
}

export function bankAccountsById(state = [], action) {
    switch (action.type) {
        case "USER_BANK_ACCOUNTS_BY_ID":
            return action.payload;
        default:
            return state;
    }
}

export function bankAccountById(state = [], action) {
    switch (action.type) {
        case "USER_BANK_ACCOUNT_BY_ID":
            return action.payload;
        default:
            return state;
    }
}

export function checkedBic(state = [], action) {
    switch (action.type) {
        case "CHECK_BIC":
            return action.payload;
        default:
            return state;
    }
}

export function mails(state = [], action) {
    switch (action.type) {
        case "USER_EMAILS":
            return action.payload;
        default:
            return state;
    }
}

export function numbers(state = [], action) {
    switch (action.type) {
        case "USER_NUMBERS":
            return action.payload;
        default:
            return state;
    }
}

export function orders(state = [], action) {
    switch (action.type) {
        case "USER_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function companyUsers(state = [], action) {
    switch (action.type) {
        case "COMPANY_USERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function companyUser(state = [], action) {
    switch (action.type) {
        case "COMPANY_USERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function recoveryType(state = [], action) {
    switch (action.type) {
        case "RECOVERY_PASSWORD_TYPE":
            return action.payload;
        default:
            return state;
    }
}
export const usersReducers = () =>
    combineReducers({
        filterList: filterList,
        item: item,
        cities: cities,
        checkedIndividualINN: checkedIndividualINN,
        checkedCompanyINN: checkedCompanyINN,
        data: data,
        filterValues: filterValues,
        administrations: administrations,
        managers: managers,
        activeManagers: activeManagers,
        managers: managers,
        performers: performers,
        activePerformers: activePerformers,
        objectsById: objectsById,
        bankAccountsById: bankAccountsById,
        bankAccountById: bankAccountById,
        checkedBic: checkedBic,
        orders: orders,
        mails: mails,
        numbers: numbers,
        companyUsers: companyUsers,
        companyUser: companyUser,
        recoveryType: recoveryType
    });
