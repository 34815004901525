import React, { useEffect, useState } from "react";
import Equipment from "./equipment";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchItem } from "actions/orders";
import { updateItem } from "actions/orders";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { fetchUserObjects } from "actions/objects";
import Loading from "utils/loading";
import dayjs from "dayjs";
import Material from "./material";
import Subcontractor from "./subcontractor";
import Inner from "./inner";
import Sales from "./sales";
import Purchases from "./purchases";

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const item = useSelector((state) => state.orders.item);
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.managers);
    const equipments = useSelector((state) => state.equipments.list);
    const performers = useSelector((state) => state.users.performers);
    const loading = useSelector((state) => state.globals.loadingList);
    const nomenclature = useSelector((state) => state.nomenclature.list);
    const taxes = useSelector((state) => state.taxes.list);
    const clientObjects = useSelector((state) => state.objects.userObjects);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchItem(id));
        dispatch(fetchUserObjects(item.client_id));
    }, [id, item.client_id]);

    const handleArchive = () => {
        const val = { archived: !item.archived };
        dispatch(updateItem(id, val));
    };

    const onFinish = (values) => {
        const val = { ...values, work_at: dayjs(values.work_at).format() };
        dispatch(updateItem(id, val));
    };

    const showModal = () => {
        setIsReportModalOpen(true);
    };

    const form = () => {
        switch (item.kind) {
            case "equipment":
                return (
                    <Equipment
                        item={item}
                        handleArchive={handleArchive}
                        onFinish={onFinish}
                        DATE_AND_TIME_FORMAT={DATE_AND_TIME_FORMAT}
                        clients={clients}
                        managers={managers}
                        equipments={equipments}
                        performers={performers}
                        showModal={showModal}
                        setIsReportModalOpen={setIsReportModalOpen}
                        isReportModalOpen={isReportModalOpen}
                        id={id}
                        clientObjects={clientObjects}
                    />
                );
            case "supply":
                return (
                    <Material
                        item={item}
                        onFinish={onFinish}
                        clients={clients}
                        managers={managers}
                        handleArchive={handleArchive}
                        clientObjects={clientObjects}
                        showModal={showModal}
                        id={id}
                        DATE_AND_TIME_FORMAT={DATE_AND_TIME_FORMAT}
                        setIsReportModalOpen={setIsReportModalOpen}
                        isReportModalOpen={isReportModalOpen}
                    />
                );
            case "subcontractor":
                return (
                    <Subcontractor
                        item={item}
                        clients={clients}
                        managers={managers}
                        onFinish={onFinish}
                        handleArchive={handleArchive}
                        clientObjects={clientObjects}
                        showModal={showModal}
                        id={id}
                        DATE_AND_TIME_FORMAT={DATE_AND_TIME_FORMAT}
                        setIsReportModalOpen={setIsReportModalOpen}
                        isReportModalOpen={isReportModalOpen}
                    />
                );
            case "inner":
                return (
                    <Inner
                        item={item}
                        handleArchive={handleArchive}
                        onFinish={onFinish}
                        DATE_AND_TIME_FORMAT={DATE_AND_TIME_FORMAT}
                        managers={managers}
                        equipments={equipments}
                        performers={performers}
                        showModal={showModal}
                        id={id}
                        setIsReportModalOpen={setIsReportModalOpen}
                        isReportModalOpen={isReportModalOpen}
                    />
                );
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Typography.Title level={2}>Заказ {id}</Typography.Title>
            {form()}
            <br />
            <br />
            <Sales id={id} nomenclature={nomenclature} taxes={taxes} archived={item.archived} />
            <br />
            <br />
            <Purchases id={id} nomenclature={nomenclature} taxes={taxes} archived={item.archived} />
        </>
    );
};

export default Item;
