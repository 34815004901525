import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { clearFilterList, recentCounts } from "actions/offers";
import RecentTable from "./recent";
import ApprovedTable from "./approved";
import CanceledTable from "./canceled";
import Filter from "./../filter";

const OffersTabs = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const counts = useSelector((state) => state.offers.recentCount);
    const filterValues = useSelector((state) => state.offers.filterValues);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const buttons = (
        <>
            {filterValues ? (
                <Button
                    type="primary"
                    onClick={() => {
                        dispatch(clearFilterList());
                    }}
                >
                    <CloseOutlined />
                </Button>
            ) : undefined}
            <Button type="primary" onClick={showModal} style={{ marginLeft: 5 }}>
                Фильтры
            </Button>
            <Link to="/offers/add" style={{ marginLeft: 5 }}>
                <Button type="primary">Создать</Button>
            </Link>
        </>
    );

    useEffect(() => {
        if (counts == null) {
            dispatch(recentCounts());
        }
    }, []);

    const tabs = [
        {
            key: "1",
            label: `Новые ${counts ? counts : ""}`,
            children: <RecentTable filterValues={filterValues} />
        },
        {
            key: "2",
            label: "Подтвержденные",
            children: <ApprovedTable filterValues={filterValues} />
        },
        {
            key: "3",
            label: "Отмененные",
            children: <CanceledTable filterValues={filterValues} />
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={buttons} />
            <Filter setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
        </div>
    );
};

export default OffersTabs;
