import React from "react";

class RecoverySmsCodeForm extends React.Component {
    render() {
        return (
            <div>sms form</div>
        )
    }
}

export default RecoverySmsCodeForm;