import axios from "axios";
import { API_URL } from "../config/conf";

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}`).then((response) => {
            dispatch({ type: "MANAGERS_ITEM", payload: response.data });
        });
    };
}

export function fetchManagerAccruals(id) {
    return (dispatch) => {
        axios.get(`${API_URL}managers/${id}/accruals`).then((response) => {
            dispatch({ type: "MANAGERS_ACCRUALS_LIST", payload: response.data });
        });
    };
}

export function fetchManagerOrders(id, item) {
    return (dispatch) => {
        axios.get(`${API_URL}managers/${id}/orders`, { params: { month: item } }).then((response) => {
            dispatch({ type: "MANAGERS_ORDERS_LIST", payload: response.data });
        });
    };
}
