import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchItem } from "actions/reports";
import { fetchList } from "actions/equipments";
import Subcontractor from "./subcontractor";
import Own from "./own";
import Loading from "utils/loading";
import { Card, List } from "antd";

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const item = useSelector((state) => state.reports.item);
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.managers);
    const cities = useSelector((state) => state.users.cities);
    const equipments = useSelector((state) => state.equipments.list);
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {
        dispatch(fetchItem(id));
        dispatch(fetchList({ active: 1 }));
    }, [id]);

    if (loading) {
        return <Loading />;
    }

    const forms = () => {
        if (item.subcontractor) {
            return <Subcontractor item={item} id={id} cities={cities} equipments={equipments} managers={managers} clients={clients} />;
        } else {
            return <Own item={item} id={id} cities={cities} equipments={equipments} managers={managers} clients={clients} />;
        }
    };

    const kind = (type) => {
        switch (type.kind) {
            case "subcontractor":
                return "Субподрядчик";
            case "equipment":
                return "На технику";
            case "inner":
                return "Внутренняя";
            case "supply":
                return "На материал";
            default:
                return "";
        }
    };

    const status = (status) => {
        switch (status.status) {
            case "processing":
                return "В работе";
            case "completed":
                return "Завершенная";
            default:
                return "";
        }
    };

    return (
        <div>
            {forms()}
            <br />
            <br />
            <Card title="Прикреплён к заказам">
                <List
                    itemLayout="horizontal"
                    dataSource={item.orders}
                    renderItem={(item) => (
                        <List.Item>
                            <Link to={`/orders/${item.id}`} style={{ width: "100%" }}>
                                <List.Item.Meta title={<h3>{item.id}</h3>} description={`Тип: ${kind(item)}, Статус: ${status(item)}`} />
                            </Link>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

export default Item;
