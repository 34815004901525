import React, { useEffect } from "react";
import { Card, Button, Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItem, checkCompanyINN } from "actions/users";

const Company = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const cities = useSelector(state => state.globals.citiesActive);
  const checkedINN = useSelector(state => state.users.checkedCompanyINN);

  useEffect(() => {
    if (checkedINN) {
      let new_item = { ...checkedINN, 'name': typeof checkedINN.name === 'object' ? checkedINN.name.official_name : checkedINN.name, }
      form.setFieldsValue(new_item);
    }
  });

  const validateINN = () => {
    const inn = form.getFieldValue("inn");
    dispatch(checkCompanyINN(inn));
  };

  const onFinish = (values) => {
    const val = { ...values, kind: "company", kind_text: "Юридическое лицо", phone: "" };
    dispatch(addItem(val));
  };

  return (
    <Card title="Создание пользователя" extra={<Link to="/users"><Button type='primary'>Назад</Button></Link>}>
      <Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 5 }} form={form}>

        <Form.Item
          label="ИНН"
          name="inn"
          rules={[{ pattern: /^\d+$/, message: "только цифры" }]}
        >
          <Input.Search
            placeholder="Не указано"
            enterButton="Проверить ИНН"
            size="middle"
            maxLength={10}
            onSearch={validateINN}
          />
        </Form.Item>

        <Form.Item label="Наименование в системе" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Наименование в документах" name="official_name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="КПП" name="kpp" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="ОГРН" name="ogrn" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Юридический адрес" name="legal_address" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Почтовый адрес" name="postal_address" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Фактический адрес" name="address" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Адрес для документов" name="address_for_docs">
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Описание" name="comment">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Город" name="city_id">
          <Select placeholder="Не указано">
            {cities.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
      </Form>
    </Card >
  );
};

export default Company;