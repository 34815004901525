import React from "react";
import { Button, Modal, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addUserObject } from "actions/users";

const Add = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  const onFinish = (values) => {
    let val = { ...values };
    dispatch(addUserObject(props.id, val));
    props.setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Создать объект"
      open={props.isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" onClick={() => { form.submit() }}>Создать</Button>,
        <Button key="back" onClick={handleCancel}>Закрыть</Button>
      ]}
      width={700}
    >
      <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} form={form} onFinish={onFinish}>

        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Описание" name="description">
          <Input placeholder="Не указано" />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default Add;