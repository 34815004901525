import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "PURCHASES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "PURCHASES_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "PURCHASES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function positions(state = [], action) {
    switch (action.type) {
        case "PURCHASE_POSITIONS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function position(state = [], action) {
    switch (action.type) {
        case "PURCHASE_POSITIONS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export const purchasesReducers = () =>
    combineReducers({
        list: list,
        filterValues: filterValues,
        item: item,
        positions: positions,
        position: position
    });
