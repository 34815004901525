import React from "react";
import { string } from "prop-types";
import padEnd from "lodash/padEnd";

export const CurrencyFormat = ({ value }) => {
    if (!value) value = "0.00";

    const data = value.split(".");

    let rubles = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let penny = "00";
    if (data.length === 2) {
        penny = padEnd(data[1], 2, "0");
    }

    return (
        <span>
            {rubles} руб. {penny} коп.
        </span>
    );
};

CurrencyFormat.propTypes = {
    value: string
};
