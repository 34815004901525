import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

const Edit = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const BillsItem = useSelector((state) => state.bills.position);
    const SalesItem = useSelector((state) => state.sales.position);
    const PurchasesItem = useSelector((state) => state.purchases.position);
    const [nomenclature, setNomenclature] = useState();

    const checkItem = () => {
        switch (props.type) {
            case "bills":
                return { ...BillsItem, nomenclature_id: BillsItem.nomenclature?.id };
            case "shipments":
                return { ...SalesItem, nomenclature_id: SalesItem.nomenclature?.id };
            case "purchases":
                return { ...PurchasesItem, nomenclature_id: PurchasesItem.nomenclature?.id };
            default:
                return "undefined";
        }
    };

    useEffect(() => {
        if (!nomenclature) {
            let new_item = checkItem();
            form.setFieldsValue(new_item);
        }
    });

    const handleCancel = () => {
        props.setIsEditModalOpen(false);
    };

    const handleSelect = (values, option) => {
        setNomenclature(option.label);
    };

    const onFinish = (values) => {
        const val = { ...values };
        switch (props.type) {
            case "bills":
                dispatch(props.updatePosition(props.id, BillsItem.id, val));
                break;
            case "shipments":
                dispatch(props.updatePosition(props.id, SalesItem.id, val));
                break;
            case "purchases":
                dispatch(props.updatePosition(props.id, PurchasesItem.id, val));
                break;
            default:
                return undefined;
        }
        setNomenclature(undefined);
        props.setIsEditModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Редактировать позицию"
            open={props.isEditModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Обновить
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 5 }}
                form={form}
                onFinish={onFinish}
                fields={[
                    {
                        name: ["name"],
                        value: nomenclature
                    }
                ]}
            >
                <Form.Item label="Номенклатура" name="nomenclature_id" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        onSelect={handleSelect}
                        placeholder="Не указано"
                    >
                        {props.nomenclature.map((item) => (
                            <Select.Option label={item.name + ", " + item.unit_name} key={item.id} value={item.id}>
                                {item.name + ", " + item.unit_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Наименование" name="name" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Ставка налога" name="vat" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Select placeholder="Не указано">
                        {props.taxes.map((item) => (
                            <Select.Option key={item.id} value={item.nds}>
                                {item.nds}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Количество" name="quantity" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input type="text" placeholder="0" />
                </Form.Item>

                <Form.Item label="Цена" name="price" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input type="text" placeholder="0 руб. 00 коп." />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
