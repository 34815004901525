import { combineReducers } from 'redux';
import {
    DEVICES_LIST,
    DEVICES_DATA_ARE_LOADING,
    DEVICE_BY_ID,
    STORED_DEVICE_BY_ID,
    DEVICE_BY_ID_LOADING,
    DEVICE_BUTTON_DISPLAY_STATUS,
    DEVICE_ADD
} from "../constants/devices";



export function devices(state = [], action) {
    switch (action.type) {
        case "DEVICES":
            return action.payload;
        default:
            return state;
    }
}


// НЕПЕРПИСАННЫЕ

export function data(state = [], action) {
    switch (action.type) {
        case DEVICES_LIST:
            return action.data;
        default:
            return state;
    }
}

export function dataAreLoading(state = true, action) {
    switch (action.type) {
        case DEVICES_DATA_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function itemByID(state = [], action) {
    switch (action.type) {
        case DEVICE_BY_ID:
            return action.item;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case DEVICE_ADD:
            return action.data;
        default:
            return state;
    }
}


export function storedItemById(state = [], action) {
    switch (action.type) {
        case STORED_DEVICE_BY_ID:
            return action.item;
        default:
            return state;
    }
}

export function itemButtonDisplay(state = [], action) {
    switch (action.type) {
        case DEVICE_BUTTON_DISPLAY_STATUS:
            return action.bool;
        default:
            return state;
    }
}

export function itemByIdAreLoading(state = [], action) {
    switch (action.type) {
        case DEVICE_BY_ID_LOADING:
            return action.bool;
        default:
            return state;
    }
}


export const devicesReducers = () => combineReducers({
    data: data,
    dataAreLoading: dataAreLoading,
    item: itemByID,
    storedItem: storedItemById,
    itemButtonDisplay: itemButtonDisplay,
    itemLoading: itemByIdAreLoading,
    add: add,

    devices: devices
});
