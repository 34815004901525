import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchProcessingList } from "actions/orders";
import Loading from "utils/loading";
import { useNavigate } from "react-router-dom";

const Processing = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const list = useSelector((state) => state.orders.processingList);
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {
        dispatch(fetchProcessingList(props.filterValues));
    }, [props.filterValues]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Table
                className={"data-table"}
                pagination={false}
                rowKey="id"
                columns={props.columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/orders/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default Processing;
