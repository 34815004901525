import React from "react";
import { Modal, Button, Form, Select } from "antd";
import { useDispatch } from "react-redux";
import { addCompanyUser } from "actions/users";
import { useSelector } from "react-redux";

const Add = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.data);

    const handleCancel = () => {
        props.setIsAddModalOpen(false);
    };

    const onFinish = (values) => {
        const val = { ...values };
        dispatch(addCompanyUser(props.id, val));
        props.setIsAddModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Добавить пользователя"
            open={props.isAddModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Создать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
                <Form.Item label="Пользователь" name="company_id">
                    <Select placeholder="Не указано">
                        {users.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
