import React, { useEffect, useState } from "react";
import { fetchProfileList, updateProfileItem } from "actions/notifications";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import { Table, Pagination, Button } from "antd";
import { DATE_AND_TIME_FORMAT } from "constants/globals";

const Notifications = () => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(1);
    const list = useSelector((state) => state.notifications.profileNotifications);

    useEffect(() => {
        dispatch(fetchProfileList(current));
    }, [current]);

    const checkNotification = (id) => {
        dispatch(updateProfileItem(id, current));
    };

    const columns = [
        {
            title: "Наименование",
            dataIndex: "notification",
            key: "title",
            render: (text) => {
                return text.title;
            }
        },
        {
            title: "Дата",
            dataIndex: "notification",
            key: "created_at",
            render: (text) => {
                return dayjs(text.created_at).format(DATE_AND_TIME_FORMAT);
            }
        },
        {
            title: "Статус",
            dataIndex: "read",
            key: "read",
            render: (text, item) => {
                return text ? <EyeOutlined style={{ marginLeft: "20px" }} /> : <Button type="primary" onClick={() => {checkNotification(item.id)}}>Прочитать</Button>
            }
        }
    ];

    const onChange = (page) => {
        setCurrent(page);
        document.documentElement.scrollTop = 0;
    };

    return (
        <div>
            <Table
                className={"data-table"}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list.users_notifications}
                locale={{ emptyText: "Нет данных" }}
                rowClassName={(record) => record.read ? "disabled-row" : ""}
            />
            <br />
            <br />
            <Pagination
                style={{ textAlign: "center" }}
                current={current}
                onChange={onChange}
                total={list.meta?.total}
                pageSize={50}
                showSizeChanger={false}
                hideOnSinglePage
            />
        </div>
    );
};

export default Notifications;
