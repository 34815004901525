import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/sales/shipments";
import Add from "../../components/sales/shipments/add";
import Item from "../../components/sales/shipments/item";

const Shipments = () => {
    return (
        <Routes>
            <Route exact path="/" element={<List />} />
            <Route exact path="/add" element={<Add />} />
            <Route exact path="/:id" element={<Item />} />
        </Routes>
    );
};

export default Shipments;
