import { Layout } from "antd";
import Mainmenu from "./mainmenu";
import AppFooter from "./footer";
import AppHeader from "./header";
import Preloader from "./preloader";
import { useSelector } from "react-redux";

const { Content, Sider } = Layout;

const MainLayout = ({ children }) => {
    const preloading = useSelector((state) => state.globals.preloading);

    const main = () => {
        {
            if (!preloading) {
                return <Preloader />;
            } else {
                return (
                    <Layout>
                        <Sider width={260}>
                            <Mainmenu />
                        </Sider>

                        <Layout>
                            <AppHeader />
                            <Content style={{ padding: 50 }}>{children}</Content>
                            <AppFooter />
                        </Layout>
                    </Layout>
                );
            }
        }
    };

    return <Layout style={{ maxWidth: "1600px", margin: "0 auto" }}>{main()}</Layout>;
};

export default MainLayout;
