import React from "react";
import { Tabs } from "antd";
import Individual from "./individual";
import Company from "./company";

const Add = () => {
    const items = [
        {
            key: '1',
            label: "Физическое лицо",
            children: <Individual />
        },
        {
            key: '2',
            label: "Юридическое лицо",
            children: <Company />
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    );
};

export default Add;