import AuthLayout from "components/layout/auth_layout";
import { Link } from "react-router-dom";

const RecoveryEmailNote = () => {
    return (
        <AuthLayout>
            <h2 style={{ textAlign: "center" }}>Спасибо!</h2>
            <p style={{ textAlign: "center" }}>Вам отправлено письмо со ссылкой, перейдя по которой, вы окажетесь на форме ввода нового пароля.</p>

            <Link to="/login" style={{ textAlign: "center", display: "block" }}>
                Вход
            </Link>
        </AuthLayout>
    );
};

export default RecoveryEmailNote;
