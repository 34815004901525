import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/nomenclature";

const Add = () => {
	const dispatch = useDispatch();
	const units = useSelector(state => state.units.list)
	const categories = useSelector(state => state.nomсategories.list)

	const onFinish = (values) => {
		dispatch(addItem(values));
	};

	return (
		<Card title="Создание региона" extra={<Link to="/dict/nomenclature"><Button type='primary'>Назад</Button></Link>}>
			<Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 5 }}>
				<Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Form.Item label="Наименование для поиска" name="sname" rules={[{ required: true, message: 'Заполните поле' }]}>
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Form.Item
					label="Единица измерения"
					name="unit_id"
					rules={[{ required: true, message: 'Выберите единицу измерения' }]}
				>
					<Select>
						{units.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
					</Select>
				</Form.Item>

				<Form.Item
					label="Категория"
					name="category_id"
					rules={[{ required: true, message: 'Выберите категорию' }]}
				>
					<Select>
						{categories.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
					</Select>
				</Form.Item>

				<Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
			</Form>
		</Card >
	);
};

export default Add;
