import React from "react";
import { Tabs } from "antd";
import Password from "./password";
import Notifications from "./notifications";
import NotificationsSettings from "./notifiatonsSettings";
import Devices from "./devices";
import Info from "./info";

const ProfileTabs = () => {
    const tabs = [
        {
            key: "1",
            label: "Уведомления",
            children: <Notifications />
        },
        {
            key: "2",
            label: "Настройки уведомлений",
            children: <NotificationsSettings />
        },
        {
            key: "3",
            label: "Информация",
            children: <Info />
        },
        {
            key: "4",
            label: "Устройства",
            children: <Devices />
        },
        {
            key: "5",
            label: "Cмена пароля",
            children: <Password />
        }
    ];

    return <Tabs defaultActiveKey="1" items={tabs} />
};

export default ProfileTabs;
