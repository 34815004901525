import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography, Table, Space, Col } from "antd";
import { clearFilterList, fetchList } from "actions/reports";
import { DATE_FORMAT } from "constants/globals";
import Loading from "utils/loading";
import { CheckCircleFilled, FileOutlined, CloseOutlined } from "@ant-design/icons";
import Filter from "./filter";

const List = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const list = useSelector((state) => state.reports.list);
    const loading = useSelector((state) => state.globals.loadingList);
    const filterValues = useSelector((state) => state.reports.filterValues);

    useEffect(() => {
        dispatch(fetchList(filterValues));
    }, [filterValues]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const columns = [
        {
            title: "Период",
            dataIndex: "number",
            key: "number",
            render: (_, record) => {
                return (
                    <div>
                        {dayjs(record.begin_at).format(DATE_FORMAT)} - {dayjs(record.end_at).format(DATE_FORMAT)}
                    </div>
                );
            }
        },
        {
            title: "Номер",
            dataIndex: "number",
            key: "number",
            render: (text) => {
                return <div>{text}</div>;
            }
        },
        {
            title: "Техника",
            dataIndex: "equipment",
            key: "equipment",
            render: (_, record) => {
                return <div>{record.subcontractor ? record.subcontractor_equipment : record.equipment.name}</div>;
            }
        },
        {
            title: "Контрагент",
            dataIndex: "client",
            key: "client",
            render: (text) => {
                return <div>{text ? text.name : "-"}</div>;
            }
        },
        {
            title: "Субподрядчик",
            dataIndex: "subcontractor",
            key: "subcontractor",
            render: (text) => {
                return <div>{text ? text.name : "-"}</div>;
            }
        },
        {
            title: "Файл",
            dataIndex: "file_url",
            key: "file_url",
            render: (text) => {
                return (
                    <div>
                        {text ? (
                            <Link target="_blank" to={text}>
                                <Button type="primary">
                                    <FileOutlined />
                                </Button>
                            </Link>
                        ) : (
                            "-"
                        )}
                    </div>
                );
            }
        },
        {
            title: "Заказ",
            dataIndex: "order",
            key: "order",
            render: (_, record) => {
                return <div>{record.blocked ? <CheckCircleFilled style={{ fontSize: "20px", color: "green" }} /> : "-"}</div>;
            }
        }
    ];

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Рапорта</Typography.Title>
                </Col>

                <Col>
                    {filterValues ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                dispatch(clearFilterList());
                            }}
                        >
                            <CloseOutlined />
                        </Button>
                    ) : undefined}
                    <Button type="primary" onClick={showModal} style={{ marginRight: 5, marginLeft: 5 }}>
                        Фильтры
                    </Button>
                    <Link to="/documents/reports/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <Filter isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <br />
            <br />
            <Table
                className={"data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onDoubleClick: () => {
                        navigate(`/documents/reports/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
