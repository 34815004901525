import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPerformerAccruals } from "actions/performers";
import { List, Card } from "antd";
import dayjs from "dayjs";
import { CurrencyFormat } from "utils/currency";
import { DATE_FORMAT } from "constants/globals";

const Accruals = (props) => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.performers.accruals);

    useEffect(() => {
        dispatch(fetchPerformerAccruals(props.id));
    }, []);

    return (
        <div>
            <Card title="Начисления">
                <List
                    itemLayout="horizontal"
                    dataSource={list?.accruals}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                padding: "16px"
                            }}
                        >
                            <List.Item.Meta title={dayjs(item.date).format(DATE_FORMAT)} style={{ minWidth: "50px" }} />
                            <List.Item.Meta title={<CurrencyFormat value={item.amount} />} style={{ minWidth: "50px" }} />
                            <List.Item.Meta title={item.comment} style={{ minWidth: "50px" }} />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

export default Accruals;
