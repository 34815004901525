import axios from "axios";
import { history } from "store";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";

export function fetchMonthList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}orders`, { params: item }).then((response) => {
            dispatch({ type: "MONTH_ORDERS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchDayList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}orders/group_by_day`, { params: { ...item } }).then((response) => {
            dispatch({ type: "DAY_ORDERS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchProcessingList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}orders`, { params: { ...item, status: "processing" } }).then((response) => {
            dispatch({ type: "PROCESSING_ORDERS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchUnshipmentList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}orders`, { params: { ...item, unshipment: 1 } }).then((response) => {
            dispatch({ type: "UNSHIPMENT_ORDERS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "FILTER_VALUES", payload: null });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}orders/${id}`).then((response) => {
            dispatch({ type: "ORDERS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}orders`, item).then(() => {
            dispatch(messages.success("Заявка добавлена"));
            history.push("/orders");
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}orders/${id}`, item).then(() => {
            dispatch(messages.success("Заказ обновлён"));
        });
    };
}

export function fetchOrderPurchases(id) {
    return (dispatch) => {
        axios.get(`${API_URL}orders/${id}/purchases`).then((response) => {
            dispatch({ type: "ORDER_PURCHASES_LIST", payload: response.data });
        });
    };
}

export function fetchOrderPurchase(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}orders/${id}/purchases/${itemId}`).then((response) => {
            dispatch({ type: "ORDER_PURCHASES_ITEM", payload: response.data });
        });
    };
}

export function addOrderPurchase(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}orders/${id}/purchases`, item).then(() => {
            dispatch(fetchOrderPurchases(id));
        });
    };
}

export function updateOrderPurchase(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}orders/${id}/purchases/${itemId}`, item).then(() => {
            dispatch(fetchOrderPurchases(id));
        });
    };
}

export function deleteOrderPurchase(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}orders/${id}/purchases/${itemId}`).then(() => {
            dispatch(fetchOrderPurchases(id));
        });
    };
}

export function fetchOrderSales(id) {
    return (dispatch) => {
        axios.get(`${API_URL}orders/${id}/sales`).then((response) => {
            dispatch({ type: "ORDER_SALES_LIST", payload: response.data });
        });
    };
}

export function fetchOrderSale(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}orders/${id}/sales/${itemId}`).then((response) => {
            dispatch({ type: "ORDER_SALES_ITEM", payload: response.data });
        });
    };
}

export function addOrderSale(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}orders/${id}/sales`, item).then(() => {
            dispatch(fetchOrderSales(id));
        });
    };
}

export function updateOrderSale(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}orders/${id}/sales/${itemId}`, item).then(() => {
            dispatch(fetchOrderSales(id));
        });
    };
}

export function deleteOrderSale(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}orders/${id}/sales/${itemId}`).then(() => {
            dispatch(fetchOrderSales(id));
        });
    };
}
