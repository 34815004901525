import React, { useEffect } from "react";
import { Modal, Form, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "actions/users";

const Filter = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const filterValues = useSelector((state) => state.users.filterValues);

    useEffect(() => {
        if (filterValues) {
            form.setFieldsValue(filterValues);
        } else {
            form.resetFields();
        }
    }, [filterValues]);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(setFilterValues(val));
        form.resetFields();
        props.setIsModalOpen(false);
    };

    return (
        <Modal
            title="Фильтры"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отфильтровать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 2 }} form={form} onFinish={onFinish}>
                <Form.Item label="Поиск" name="q">
                    <Input type="text" placeholder="" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Filter;
