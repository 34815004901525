export const AGREEMENT_TYPES = "AGREEMENT_TYPES";

export const AGREEMENTS_DATA = "AGREEMENTS_DATA";
export const AGREEMENTS_DATA_ARE_LOADING = "AGREEMENTS_DATA_ARE_LOADING";

export const AGREEMENTS_ADD = "AGREEMENTS_ADD";
export const AGREEMENTS_ADD_STAY = "AGREEMENTS_ADD_STAY";

export const AGREEMENT_BY_ID = "AGREEMENT_BY_ID";
export const STORED_AGREEMENT_BY_ID = "STORED_AGREEMENT_BY_ID";
export const AGREEMENT_BY_ID_LOADING = "AGREEMENT_BY_ID_LOADING";
export const AGREEMENT_BY_ID_NOT_FOUND = "AGREEMENT_BY_ID_NOT_FOUND";
export const AGREEMENT_BUTTON_DISPLAY_STATUS = "AGREEMENT_BUTTON_DISPLAY_STATUS";

export const AGREEMENT_CONDITIONS = "AGREEMENT_CONDITIONS";
export const AGREEMENT_CONDITIONS_ARE_LOADING = "AGREEMENT_CONDITIONS_ARE_LOADING";
export const AGREEMENT_CONDITION_ADDED = "AGREEMENT_CONDITION_ADDED";

export const AGREEMENTS_TYPES = [
    {
        key: "manager",
        value: "С менеджером"
    },
    {
        key: "performer",
        value: "C водителем"
    }
];

export const CONDITION_TYPES = [
    {
        key: "hourly",
        value: "Почасовая"
    },
    {
        key: "percent",
        value: "Процент"
    }
];