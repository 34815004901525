import React, { useEffect } from "react";
import Reports from "../modals/reports";
import { Card, Button, Form, Select, Input, DatePicker, Radio } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { CurrencyFormat } from "utils/currency";
import { useDispatch } from "react-redux";
import { fetchUserObjects } from "actions/objects";

const Material = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        let new_item = { ...props.item, work_at: dayjs(props.item.work_at) };
        form.setFieldsValue(new_item);
    }, [props.item.id, props.item.total]);

    const onClientChange = (id) => {
        form.setFieldsValue({
            project_id: ""
        });
        dispatch(fetchUserObjects(id));
    };

    return (
        <div>
            <Card
                title="На материал"
                extra={[
                    <Button type="primary" style={{ marginRight: 5 }} onClick={props.handleArchive}>
                        {props.item.archived ? "Разархивировать" : "Архивировать"}
                    </Button>,
                    <Link to="/orders">
                        <Button type="primary">Назад</Button>
                    </Link>
                ]}
            >
                <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} onFinish={props.onFinish} form={form}>
                    <Form.Item label="Дата и время работ" name="work_at" rules={[{ required: true, message: "Заполните поле" }]}>
                        <DatePicker disabled={props.item.archived} showTime placeholder="Не указано" format={props.DATE_AND_TIME_FORMAT} />
                    </Form.Item>

                    <Form.Item label="Статус" name="status">
                        <Radio.Group disabled={props.item.archived}>
                            <Radio.Button value="processing">В работе</Radio.Button>
                            <Radio.Button value="completed">Завершенная</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Клиент" name="client_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            placeholder="Не указано"
                            disabled={props.item.archived}
                            onChange={onClientChange}
                            showSearch
                            filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        >
                            {props.clients.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {props.clientObjects.length ? (
                        <Form.Item label="Объект" name="project_id" rules={[{ required: true, message: "Заполните поле" }]}>
                            <Select placeholder="Выберите объект" disabled={props.item.archived}>
                                {props.clientObjects.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item label="Объект">
                            <Input type="text" placeholder="У конграгента нет объктов" disabled />
                        </Form.Item>
                    )}

                    <Form.Item label="Адрес" name="address" disabled={props.item.archived} rules={[{ required: true, message: "Заполните поле" }]}>
                        <Input type="text" disabled={props.item.archived} placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Описание" name="description">
                        <Input.TextArea placeholder="Не указано" disabled={props.item.archived} />
                    </Form.Item>

                    <Form.Item label="Менеджер" name="user_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            placeholder="Не указано"
                            disabled={props.item.archived}
                            showSearch
                            filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        >
                            {props.managers.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Итог">
                        <CurrencyFormat value={props.item.total.toFixed(2)} />
                    </Form.Item>

                    <Form.Item label="Рапорта">
                        <Button type="primary" htmlType="button" onClick={props.showModal}>
                            {props.item.reports && props.item.reports.length + " Шт."}
                        </Button>
                        <Reports
                            id={props.id}
                            item={props.item}
                            setIsReportModalOpen={props.setIsReportModalOpen}
                            isReportModalOpen={props.isReportModalOpen}
                        />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                        Сохранить
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default Material;
