import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "components/layout";
import Calls from "../components/crm/calls";
import Leads from "../components/crm/leads";
import Requests from "../components/crm/requests";

const Crm = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/calls/*" element={<Calls />} />
                <Route exact path="/leads/*" element={<Leads />} />
                <Route exact path="/requests/*" element={<Requests />} />
            </Routes>
        </MainLayout>
    );
};

export default Crm;
