import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "SALES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "SALES_FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "SALES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function positions(state = [], action) {
    switch (action.type) {
        case "SALES_POSITIONS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function position(state = [], action) {
    switch (action.type) {
        case "SALES_POSITIONS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export const salesReducers = () =>
    combineReducers({
        list: list,
        filterValues: filterValues,
        item: item,
        positions: positions,
        position: position
    });
