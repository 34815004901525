import axios from "axios";
import { API_URL } from "config/conf";
import * as messages from "./handle_messages";
import { history } from "store";

export function fetchList(page) {
    return (dispatch) => {
        axios.get(`${API_URL}bank/statements?page=${page}&limit=50`).then((response) => {
            dispatch({
                type: "STATEMENTS_LIST",
                payload: response.data
            });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}bank/statements/${id}`).then((response) => {
            dispatch({
                type: "STATEMENT_ITEM",
                payload: response.data
            });
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}bank/statements/${id}`, item).then(() => {
            dispatch(messages.success("Выписка обновлена"));
            history.push("/bank/statements");
        });
    };
}


export function addContractor(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}create_contractor`, item).then(() => {
            dispatch(fetchItem(id));
            dispatch(messages.success("Контрагент создан"));
        });
    };
}