import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkIndividualINN } from "actions/users";
import { Form, Input, Button, Select, Switch } from "antd";

const Individual = (props) => {
    const dispatch = useDispatch();
    const checkedINN = useSelector((state) => state.users.checkedIndividualINN);

    useEffect(() => {
        if (checkedINN) {
            props.form.setFieldsValue(checkedINN);
        }
    }, [checkedINN]);

    const validateINN = async () => {
        const inn = props.form.getFieldValue("inn");
        dispatch(checkIndividualINN(inn));
    };

    return (
        <div>
            <Form autoComplete="off" onFinish={props.onFinish} form={props.form} labelCol={{ span: 6 }}>
                <Form.Item label="ИНН" name="inn" rules={[{ pattern: /^\d+$/, message: "только цифры" }]}>
                    <Input.Search placeholder="Не указано" enterButton="Проверить ИНН" size="middle" maxLength={12} onSearch={validateINN} />
                </Form.Item>

                <Form.Item initialValue="Физическое лицо" label="Тип" name="kind_text">
                    <Input disabled />
                </Form.Item>

                <Form.Item label="Наименование" name="name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Наименование для документов" name="official_name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Фамилия" name="last_name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Имя" name="first_name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Отчество" name="middle_name">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Юридический адрес" name="legal_address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Почтовый адрес" name="postal_address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Фактический адрес" name="address">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Адрес для документов" name="address_for_docs">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Электронная почта" name="email">
                    <Input placeholder="Не указано" type="email" />
                </Form.Item>

                <Form.Item label="Телефон" name="phone">
                    <Input />
                </Form.Item>

                <Form.Item label="Описание" name="comment">
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label="Город" name="city_id">
                    <Select placeholder="Не указано">
                        {props.cities.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Вход в систему" name="locked" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                    Сохранить
                </Button>
            </Form>
        </div>
    );
};

export default Individual;
