import axios from "axios";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";
import { history } from "store";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}users`).then((response) => {
            dispatch({ type: "USERS_LIST", payload: response.data });
        });
    };
}

export function fetchFilterList(item) {
    return (dispatch) => {
        axios.get(`${API_URL}users`, { params: { ...item } }).then((response) => {
            dispatch({ type: "USERS_FILTER_LIST", payload: response.data });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "USERS_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "USERS_FILTER_VALUES", payload: null });
        dispatch({ type: "USERS_FILTER_LIST", payload: [] });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}users/${id}`).then((response) => {
            dispatch({ type: "USERS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}users`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Контрагент добавлен"));
            history.push("/users");
        });
    };
}

export function updateItem(id, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}users/${id}`, values).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Контрагент обновлён"));
            history.push("/users");
        });
    };
}

export function checkIndividualINN(inn) {
    return (dispatch) => {
        axios.post(`${API_URL}check_inn`, { inn: inn }).then((response) => {
            dispatch({ type: "CHECK_INDIVIDUAL_INN", payload: response.data });
        });
    };
}

export function checkCompanyINN(inn) {
    return (dispatch) => {
        axios.post(`${API_URL}check_inn`, { inn: inn }).then((response) => {
            dispatch({ type: "CHECK_COMPANY_INN", payload: response.data });
        });
    };
}

// export function fetchManagersData(q = []) {
//     return (dispatch) => {
//         axios.get(`${API_URL}users/managers`, { q: q }).then((response) => {
//             dispatch({ type: "USER_BY_TYPE_MANAGERS", payload: response.data });
//         });
//     };
// }

export function fetchClientsData(q = null) {
    return (dispatch) => {
        axios.get(`${API_URL}users/clients`, { params: { q: q } }).then((response) => {
            dispatch({ type: "USER_BY_TYPE_CLIENTS", payload: response.data });
        });
    };
}

// export function fetchPerformersData(archived) {
//     return (dispatch) => {
//         axios.get(`${API_URL}users/performers`).then((response) => {
//             dispatch({ type: "USER_BY_TYPE_PERFORMERS", payload: response.data });
//         });
//     };
// }

export function fetchUserObjects(userId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${userId}/projects`).then((response) => {
            dispatch({ type: "USER_OBJECTS_BY_ID", payload: response.data });
        });
    };
}

export function addUserObject(userId, values) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${userId}/projects`, values).then(() => {
            dispatch(fetchUserObjects(userId));
            dispatch(messages.success("Объект добавлен"));
        });
    };
}

export function updateUserObject(userId, objectId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}users/${userId}/projects/${objectId}`, values).then(() => {
            dispatch(fetchUserObjects(userId));
            dispatch(messages.success("Объект обновлён"));
        });
    };
}

export function fetchUserOrders(userId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${userId}/orders`).then((response) => {
            dispatch({ type: "USER_ORDERS_LIST", payload: response.data });
        });
    };
}

export function fetchUserBankAccounts(userId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${userId}/bank_accounts`).then((response) => {
            dispatch({ type: "USER_BANK_ACCOUNTS_BY_ID", payload: response.data });
        });
    };
}

export function fetchUserBankAccount(userId, bankAccountId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${userId}/bank_accounts/${bankAccountId}`).then((response) => {
            dispatch({ type: "USER_BANK_ACCOUNT_BY_ID", payload: response.data });
        });
    };
}

export function addUserBankAccount(userId, values) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${userId}/bank_accounts`, values).then(() => {
            dispatch(fetchUserBankAccounts(userId));
            dispatch(messages.success("Банковский счёт добавлен"));
        });
    };
}

export function updateUserBankAccount(userId, objectId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}users/${userId}/bank_accounts/${objectId}`, values).then(() => {
            dispatch(fetchUserBankAccounts(userId));
            dispatch(messages.success("Банковский счёт обновлён"));
        });
    };
}

export function checkBic(id, bic) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${id}/bank_accounts/check_bic`, { bic: bic }).then((response) => {
            dispatch({ type: "CHECK_BIC", payload: response.data });
        });
    };
}

export function fetchMails(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/emails`).then((response) => {
            dispatch({ type: "USER_EMAILS", payload: response.data });
        });
    };
}

export function addEmail(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${id}/emails`, item).then(() => {
            dispatch(fetchMails(id));
            dispatch(messages.success("Почта добавлена"));
        });
    };
}

export function deleteEmail(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}users/${id}/emails/${itemId}`).then(() => {
            dispatch(fetchMails(id));
            dispatch(messages.success("Почта удалена"));
        });
    };
}

export function fetchNumbers(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/phones`).then((response) => {
            dispatch({ type: "USER_NUMBERS", payload: response.data });
        });
    };
}

export function addNumber(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${id}/phones`, item).then(() => {
            dispatch(fetchNumbers(id));
            dispatch(messages.success("Номер добавлен"));
        });
    };
}

export function deleteNumber(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}users/${id}/phones/${itemId}`).then(() => {
            dispatch(fetchNumbers(id));
            dispatch(messages.success("Номер удален"));
        });
    };
}

export function fetchCompanyUsers(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/company_users`).then((response) => {
            dispatch({ type: "COMPANY_USERS_LIST", payload: response.data });
        });
    };
}

export function fetchCompanyUser(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/company_users/${itemId}`).then((response) => {
            dispatch({ type: "COMPANY_USERS_ITEM", payload: response.data });
        });
    };
}

export function deleteCompanyUser(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}users/${id}/company_users/${itemId}`).then(() => {
            dispatch(fetchCompanyUsers(id));
            dispatch(messages.success("Пользователь удален"));
        });
    };
}

export function addCompanyUser(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}users/${id}/company_users`, item).then(() => {
            dispatch(fetchCompanyUsers(id));
            dispatch(messages.success("Пользователь добавлен"));
        });
    };
}

export function updateCompanyUser(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}users/${id}/company_users/${itemId}`, item).then(() => {
            dispatch(fetchCompanyUsers(id));
            dispatch(messages.success("Пользователь добавлен"));
        });
    };
}
