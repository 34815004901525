import React, { useEffect } from "react";
import { Modal, Form, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "actions/sales";

const Filter = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.data);
    const filterValues = useSelector((state) => state.sales.filterValues);

    useEffect(() => {
        if (filterValues) {
            form.setFieldsValue(filterValues);
        } else {
            form.resetFields();
        }
    }, [filterValues]);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        dispatch(setFilterValues(values));
        props.setIsModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Фильтры"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отфильтровать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 3 }} form={form} onFinish={onFinish}>
                <Form.Item label="Контрагент" name="client">
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        placeholder="Не указано"
                    >
                        {users.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Filter;
