import axios from "axios";
import { API_URL } from "../config/conf";

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}`).then((response) => {
            dispatch({ type: "PERFORMERS_ITEM", payload: response.data });
        });
    };
}

export function fetchPerformerAccruals(id) {
    return (dispatch) => {
        axios.get(`${API_URL}performers/${id}/accruals`).then((response) => {
            dispatch({ type: "PERFORMERS_ACCRUALS_LIST", payload: response.data });
        });
    };
}

export function fetchPerformerOrders(id, item) {
    return (dispatch) => {
        axios.get(`${API_URL}performers/${id}/orders`, { params: { month: item } }).then((response) => {
            dispatch({ type: "PERFORMERS_ORDERS_LIST", payload: response.data });
        });
    };
}
