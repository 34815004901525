import React, { useEffect } from "react";
import { Collapse, Button, Empty } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { fetchApprovedList } from "actions/offers";
import Loading from "utils/loading";
import { DATE_FORMAT } from "constants/globals";
import { EditOutlined } from "@ant-design/icons";

const RequestsApprovedTable = (props) => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.offers.approvedList);
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {
        dispatch(fetchApprovedList(props.filterValues));
    }, [props.filterValues]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Collapse bordered={false} style={{ background: "none" }}>
            {!list.length ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ padding: 50 }} />
            ) : (
                list.map((item) => (
                    <Collapse.Panel
                        key={item.id}
                        header={`${item.id}, 
                            ${dayjs(item.date).format(DATE_FORMAT)}, 
                            ${item.user ? item.user.name : "нет клиента"}, 
                            ${item.phone ? item.phone : "нет телефона"}, 
                            ${item.city ? item.city.name : "нет города"}`}
                        extra={
                            <Link to={`/offers/${item.id}`}>
                                <Button type="primary">
                                    <EditOutlined />
                                </Button>
                            </Link>
                        }
                        style={{ border: "none", marginBottom: "10px", background: "white", borderRadius: "10px", textAlign: "start" }}
                    >
                        <p>Менеджер: {item.manager?.name}</p>
                        <p>Адрес: {item.address}</p>
                        <p>Описание: {item.description}</p>
                    </Collapse.Panel>
                ))
            )}
        </Collapse>
    );
};

export default RequestsApprovedTable;
