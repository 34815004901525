import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "components/layout";
import List from "../components/managers";
import Item from "../components/managers/item";

const Managers = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Managers;
