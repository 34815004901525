import MainLayout from "components/layout";
import React from "react";
import { Routes, Route } from "react-router-dom";
import List from "components/accruals";
import Add from "components/accruals/add";
import Item from "components/accruals/item";

const Accruals = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/add" element={<Add />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Accruals;
