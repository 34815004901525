import { combineReducers } from "redux";


export function item(state = [], action) {
    switch (action.type) {
        case "MANAGERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function accruals(state = [], action) {
    switch (action.type) {
        case "MANAGERS_ACCRUALS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function orders(state = [], action) {
    switch (action.type) {
        case "MANAGERS_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export const managersReducers = () =>
    combineReducers({
        item: item,
        accruals: accruals,
        orders: orders
    });
