import axios from "axios";
import { API_URL } from "../config/conf";
import * as messages from "actions/handle_messages";
import { history } from "store";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}dict/nomencategories`).then((response) => {
            dispatch({ type: "NOMENCLATURE_CATEGORIES_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}dict/nomencategories/${id}`).then((response) => {
            dispatch({ type: "NOMENCLATURE_CATEGORIES_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}dict/nomencategories`, item).then(() => {
            dispatch(fetchList());
            history.push("/dict/nomenclature-categories");
            dispatch(messages.success("Категория создана"));
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}dict/nomencategories/${item.id}`, item).then(() => {
            dispatch(fetchList());
            history.push("/dict/nomenclature-categories");
            dispatch(messages.success("Категория обновлена"));
        });
    };
}
