import React, { useState } from "react";
import { DATE_FORMAT } from "constants/globals";
import { Card, Table, Button } from "antd";
import dayjs from "dayjs";
import Add from "./add";
import { fetchItem } from "actions/objects";
import { useDispatch, useSelector } from "react-redux";
import Edit from "./edit";

const Objects = (props) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const objects = useSelector(state => state.users.objectsById);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showItemModal = (item) => {
    dispatch(fetchItem(props.id, item.id));
    setIsItemModalOpen(true);
  };

  const columns = [
    {
      title: (
        <div className="header__title">
          Дата
        </div>),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => {
        return <div>
          {dayjs(text).format(DATE_FORMAT)}
        </div>
      }
    },
    {
      title: (
        <div className="header__title">
          Наименование
        </div>),
      dataIndex: 'name',
      key: 'name',
      render: (text) => {
        return <div>
          {text}
        </div>
      }
    },
    {
      title: (
        <div className="header__title">
          Описание
        </div>),
      dataIndex: 'description',
      key: 'description',
      render: (text) => {
        return <div>
          {text ? text : '-'}
        </div>
      }
    }
  ];

  return (
    <Card title="Объекты" extra={<Button type='primary' onClick={showModal}>Создать</Button>}>
      <Add setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={props.id} />
      <Edit setIsItemModalOpen={setIsItemModalOpen} isItemModalOpen={isItemModalOpen} id={props.id} />
      <Table
        className={'data-table data-table'}
        bordered
        pagination={false}
        rowKey='id'
        columns={columns}
        dataSource={objects}
        locale={{ 'emptyText': 'Нет данных' }}
        onRow={(item) => {
          return {
            onClick: () => { showItemModal(item) },
          };
        }}
      />
    </Card>
  );
};

export default Objects;