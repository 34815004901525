import React from "react";
import { Typography } from "antd";

const List = () => {
    
    return (
        <>
            <Typography.Title level={2}>Заявки</Typography.Title>
        </>
    );
};

export default List;
