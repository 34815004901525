import axios from "axios";
import { API_URL } from "../config/conf";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}orders/projects`).then((response) => {
            dispatch({ type: "OBJECTS_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id, objectId) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/projects/${objectId}`).then((response) => {
            dispatch({ type: "OBJECTS_ITEM", payload: response.data });
        });
    };
}

export function fetchUserObjects(id) {
    return (dispatch) => {
        axios.get(`${API_URL}users/${id}/projects`).then((response) => {
            dispatch({ type: "USER_OBJECTS", payload: response.data });
        });
    };
}
