import React from "react";
import MainLayout from "components/layout";
import { Routes, Route } from "react-router-dom";
import List from "../../components/bank/statements";
import Item from "../../components/bank/statements/item";

const Statements = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Statements;
