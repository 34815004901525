import { combineReducers } from 'redux';

export function list(state = [], action) {
  switch (action.type) {
    case "NOMENCLATURE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function item(state = [], action) {
  switch (action.type) {
    case "NOMENCLATURE_ITEM":
      return action.payload;
    default:
      return state;
  };
};


export const nomenclatureReducers = () => combineReducers({
  list: list,
  item: item
});
