import { isLoggedIn, logout } from 'actions/login'
import * as message from "actions/handle_messages";

export default class AuthMiddleware {

  constructor(store, history) {
    this.store = store;
    this.history = history;
  };

  onSync(promise) {
    return promise;
  }

  onResponseError(err) {
    if (err.response.status === 401 && err.config) {
      if (isLoggedIn()) {
        this.history.push('/login');
        logout();
        this.store.dispatch(message.error("Токен более не действителен. Необходимо еще раз войти."));
      }
    } else if (err.response.status === 500) {
      this.store.dispatch(message.error("Ошибка сервера 500"));
    }
    else if (err.response.status === 404) {
      this.store.dispatch(message.error("Ошибка сервера 404"));
    }
    else {
      Object.keys(err).map(function(objectKey, index) {
        var value = err[objectKey];
        console.log(objectKey, value);
      });
      this.store.dispatch(message.error(err.response.data));
    }
    throw err;
  }
}
