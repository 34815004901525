import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Input, Button, Switch, Select } from "antd";
import { fetchItem, updateItem } from "actions/cities";
import { fetchList } from "actions/regions";

const Item = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.cities.item);
    const list = useSelector(state => state.regions.list);

    useEffect(() => {
        dispatch(fetchItem(id));
        dispatch(fetchList());
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(item);
    });

    const onFinish = (values) => {
        let val = { ...values, id: item.id };
        dispatch(updateItem(val));
    };

    return (
        <Card
            title="Редактирование"
            extra={
                <Link to="/dict/cities">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form
                autoComplete="off"
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 5 }}
            >
                <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Выберите регион' }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Регион" name="region_id" rules={[{ required: true, message: 'Выберите регион' }]}>
                    <Select>
                        {list.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>

                <Form.Item label="Столица" name="capital" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item label="Архивирован" name="archived" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Сохранить</Button>
            </Form>
        </Card>
    );
};

export default Item;
