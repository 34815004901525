import React, { useState, useEffect } from "react";
import { Card, Table, Button } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import { fetchInsurance, fetchInsurances } from "actions/equipments";
import Add from "./add";
import Edit from "./edit";

const Insurances = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItemModalOpen, setIsItemModalOpen] = useState(false);
    const list = useSelector((state) => state.equipments.insurances);
    const users = useSelector((state) => state.users.data);

    useEffect(() => {
        dispatch(fetchInsurances(id));
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showItemModal = (item) => {
        dispatch(fetchInsurance(id, item.id));
        setIsItemModalOpen(true);
    };

    const contractorName = (id) => {
        if (id !== null) {
            const user = users.filter((item) => item.id === id);
            return user[0]?.name;
        }
        return "Нет ответственного";
    };

    const columns = [
        {
            title: "Начало страховки",
            dataIndex: "start_date",
            key: "end_date",
            render: (text) => {
                return <div style={{ width: "84px" }}>{dayjs(text).format(DATE_FORMAT)}</div>;
            }
        },
        {
            title: "Окончание страховки",
            dataIndex: "end_date",
            key: "end_date",
            render: (text) => {
                return <div style={{ width: "84px" }}>{dayjs(text).format(DATE_FORMAT)}</div>;
            }
        },
        {
            title: "Номер",
            dataIndex: "number",
            key: "number",
            render: (text) => {
                return <div style={{ width: "84px" }}>{text}</div>;
            }
        },
        {
            title: "Ответственный",
            dataIndex: "user_id",
            key: "user_id",
            render: (text) => {
                return <div>{contractorName(text)}</div>;
            }
        }
    ];

    return (
        <Card
            title="Страховка"
            extra={
                <Button type="primary" onClick={showModal}>
                    Создать
                </Button>
            }
        >
            <Add setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={id} />
            <Edit setIsItemModalOpen={setIsItemModalOpen} isItemModalOpen={isItemModalOpen} id={id} />
            <Table
                className={"data-table data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
                onRow={(item) => {
                    return {
                        onClick: () => {
                            showItemModal(item);
                        }
                    };
                }}
            />
        </Card>
    );
};

export default Insurances;
