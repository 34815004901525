import { useEffect } from "react";
import { Dropdown, Space, } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { current_user } from 'actions/login';
import { Link } from "react-router-dom";
import { logout } from "actions/login";

const Profilemenu = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.globals.current_user)

  useEffect(() => {
    if (!user) {
      dispatch(current_user())
    }
  }, [user]);

  const items = [
    {
      key: '1',
      label: <Link to="/profile">Профиль</Link>
    },
    {
      key: '2',
      label: <div onClick={logout}>Выход</div>
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
    >
      <Space style={{ cursor: "pointer" }}>
        {user ? user.name : "Профиль"}
        <DownOutlined />
      </Space>
    </Dropdown >
  )
}

export default Profilemenu;
