import axios from "axios";
import { history } from "store";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";

export function fetchList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}sales/sales`, { params: { ...item } }).then((response) => {
            dispatch({ type: "SALES_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "SALES_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "SALES_FILTER_VALUES", payload: null });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}sales/sales/${id}`).then((response) => {
            dispatch({ type: "SALES_ITEM", payload: response.data });
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}sales/sales/${id}`, item).then(() => {
            dispatch(messages.success("Отгрузка обновлена"));
            history.push("/sales/shipments");
        });
    };
}

export function fetchSalesPosition(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}sales/sales/${id}/positions/${itemId}`).then((response) => {
            dispatch({ type: "SALES_POSITIONS_ITEM", payload: response.data });
        });
    };
}

export function addSalesPosition(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}sales/sales/${id}/positions`, item).then((response) => {
            dispatch({ type: "SALES_ITEM", payload: response.data });
        });
    };
}

export function updateSalesPosition(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}sales/sales/${id}/positions/${itemId}`, item).then((response) => {
            dispatch({ type: "SALES_ITEM", payload: response.data });
        });
    };
}

export function deleteSalesPosition(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}sales/sales/${id}/positions/${itemId}`).then((response) => {
            dispatch({ type: "SALES_ITEM", payload: response.data });
        });
    };
}
