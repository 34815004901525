import { combineReducers } from "redux";

export function profileNotifications(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_PROFILE_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function profileItem(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_PROFILE_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function count(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_COUNT":
            return action.payload;
        default:
            return state;
    }
}

export function profileNotificationsManagerSettings(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_SETTINGS_PROFILE_MANAGER_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function profileNotificationsAdminSettings(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_SETTINGS_PROFILE_ADMIN_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function profileNotificationsPerformerSettings(state = [], action) {
    switch (action.type) {
        case "NOTIFICATIONS_SETTINGS_PROFILE_PERFORMER_LIST":
            return action.payload;
        default:
            return state;
    }
}

export const notificationsReducers = () =>
    combineReducers({
        profileNotifications: profileNotifications,
        profileNotificationsManagerSettings: profileNotificationsManagerSettings,
        profileNotificationsAdminSettings: profileNotificationsAdminSettings,
        profileNotificationsPerformerSettings: profileNotificationsPerformerSettings,
        profileItem: profileItem,
        count: count
    });
