import React, { useEffect } from "react";
import { Button, Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateUserBankAccount, checkBic } from "actions/users";

const Edit = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.users.bankAccountById);
    const checkedBic = useSelector((state) => state.users.checkedBic);

    useEffect(() => {
        form.setFieldsValue(item);
    });

    useEffect(() => {
        if (checkedBic) {
            form.setFieldsValue(checkedBic);
        }
    }, [checkBic]);

    const handleCancel = () => {
        props.setIsItemModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(updateUserBankAccount(props.id, item.id, val));
        props.setIsItemModalOpen(false);
    };

    const validateBIC = () => {
        const bic = form.getFieldValue("bic");
        dispatch(checkBic(props.id, bic));
    };

    return (
        <Modal
            title="Редактировать банковский счёт"
            open={props.isItemModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Редактировать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} form={form} onFinish={onFinish}>
                <Form.Item label="БИК" name="bic" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input.Search placeholder="Не указано" enterButton="Проверить БИК" size="middle" onSearch={validateBIC} />
                </Form.Item>

                <Form.Item label="Номер счёта" name="number" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Корреспондентский счёт" name="correspondent_account" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Наименование банка" name="name" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Город банка" name="payment_city" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input placeholder="Не указано" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
