import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Input, Select, Button, Switch, Form, Card } from "antd";
import { fetchItem, updateItem } from "actions/equipments";

const Info = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.equipments.item);
    const categories = useSelector((state) => state.equipmentcategories.list);
    const cities = useSelector((state) => state.users.cities);

    useEffect(() => {
        dispatch(fetchItem(id));
        let new_item = { ...item, last_number: item.last_number?.number };
        form.setFieldsValue(new_item);
    }, [item.id]);

    useEffect(() => {
        let new_item = { ...item, last_number: item.last_number?.number };
        form.setFieldsValue(new_item);
    });

    const onFinish = (values) => {
        let val = { ...values, id: item.id };
        dispatch(updateItem(val));
    };

    return (
        <Card type="inner" title="Информация">
            <Form
                autoComplete="off"
                layout="vertical"
                form={form}
                labelCol={{
                    span: 5
                }}
                onFinish={onFinish}
            >
                <Form.Item label="Последний номер" name="last_number">
                    <Input type="text" placeholder="Не указано" disabled />
                </Form.Item>

                <Form.Item
                    label="Категория"
                    name="category_id"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <Select placeholder="Не указано">
                        {categories.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Наименование"
                    name="name"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>
                <Form.Item
                    label="Город"
                    name="city_id"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <Select placeholder="Не указано">
                        {cities.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Комментарии" name="description">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Интервал пробега" name="period_mileage">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Интервал моточасов" name="period_hours">
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Архивированна" name="archived" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Сохранить
                </Button>
            </Form>
        </Card>
    );
};

export default Info;
