import React, { useState } from "react";
import { Typography, Table, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchList } from "actions/bank";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import { CurrencyFormat } from "utils/currency";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const List = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(1);
    const list = useSelector((state) => state.banks.list);

    useEffect(() => {
        dispatch(fetchList(current));
    }, [current]);

    const columns = [
        {
            title: "Дата",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => {
                return dayjs(text).format(DATE_FORMAT);
            }
        },
        {
            title: "Контрагент",
            dataIndex: "document",
            key: "contractor",
            render: (text, record) => {
                switch (record.kind) {
                    case "credit":
                        return (
                            <div>
                                {"❌ " + text.beneficiaryInfo.name}
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>{record.inn ? record.inn : ""}</div>
                            </div>
                        );
                    case "debit":
                        return (
                            <div>
                                {"🍋 " + text.payerInfo.name}
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>{record.inn ? record.inn : ""}</div>
                            </div>
                        );
                }
            }
        },
        {
            title: "Назначение платежа",
            dataIndex: "document",
            key: "document",
            render: (text) => {
                return text.paymentPurpose;
            }
        },
        {
            title: "Сумма",
            dataIndex: "amount_float",
            key: "amount_float",
            render: (text) => {
                return (
                    <div style={{ minWidth: "150px" }}>
                        <CurrencyFormat value={text.toFixed(2)} />
                    </div>
                );
            }
        },
        {
            title: "Контрагент в системе",
            dataIndex: "contractor",
            key: "contractor",
            render: (text) => {
                return text ? text.name : <CloseOutlined style={{ color: "#f50" }} />;
            }
        },
        {
            title: "Отмечено",
            dataIndex: "review",
            key: "review",
            render: (text) => {
                switch (text) {
                    case true:
                        return <CheckOutlined style={{ color: "#87d068" }} />;
                    case false:
                        return <CloseOutlined style={{ color: "#f50" }} />;
                }
            }
        }
    ];

    const onChange = (page) => {
        setCurrent(page);
        document.documentElement.scrollTop = 0;
    };

    return (
        <>
            <Typography.Title level={2}>Банковские выписки</Typography.Title>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list.bank_statements}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`${id}`);
                    }
                })}
            />
            <br />
            <br />
            <Pagination
                style={{ textAlign: "center" }}
                current={current}
                onChange={onChange}
                total={list.meta?.total}
                pageSize={50}
                showSizeChanger={false}
                hideOnSinglePage
            />
        </>
    );
};

export default List;
