import { combineReducers } from 'redux';
import {
  NOTIFICATION_TYPES_ITEM,
  NOTIFICATION_TYPES_LIST,
  NOTIFICATION_TYPES_ADD
} from "../constants/notification_types";

export function list(state = [], action) {
    switch (action.type) {
        case NOTIFICATION_TYPES_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case NOTIFICATION_TYPES_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case NOTIFICATION_TYPES_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const notificationtypesReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
