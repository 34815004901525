import React from "react";
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { addItem } from "actions/equipment_categories";

const Add = () => {
	const dispatch = useDispatch();

	const onFinish = (values) => {
		dispatch(addItem(values));
	};

	return (
	<Card title="Создание категории" extra={<Link to="/dict/equipment-categories"><Button type='primary'>Назад</Button></Link>}> 
			<Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 5 }}>
				<Form.Item label="Наименование" name="name">
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
			</Form>
		</Card>
	);
};

export default Add;
