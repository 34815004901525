import React from "react";
import { Route, Routes } from "react-router-dom";
import CallsTabs from "./tabs";
import Item from "./item";

const Calls = () => {

    return (
        <Routes>
            <Route exact path="/" element={<CallsTabs />} />
            <Route exact path="/:id" element={<Item />} />
        </Routes>
    );
};

export default Calls;
