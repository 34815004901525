import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteFile } from "actions/reports";
import { CloseOutlined } from "@ant-design/icons";

const ShowReport = (props) => {
    const dispatch = useDispatch();

    const deleteReportFile = () => {
        dispatch(deleteFile(props.itemId));
    };

    return (
        <div>
            <Link target="_blank" to={props.file}>
                <Button type="primary">Показать рапорт</Button>
            </Link>
            <Button type="default" style={{ marginLeft: "5px" }} onClick={deleteReportFile}>
                <CloseOutlined />
            </Button>
        </div>
    );
};

export default ShowReport;
