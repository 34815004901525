import axios from "axios";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";
import { history } from "store";

export function fetchList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}sales/bills`, { params: { ...item } }).then((response) => {
            dispatch({ type: "BILLS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchUnpaidList() {
    return (dispatch) => {
        axios.get(`${API_URL}sales/bills`, { params: { status: "unpaid" } }).then((response) => {
            dispatch({ type: "BILLS_UNPAID_LIST", payload: response.data });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "BILLS_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "BILLS_FILTER_VALUES", payload: null });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}sales/bills/${id}`).then((response) => {
            dispatch({ type: "BILLS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}sales/bills`, item).then(() => {
            dispatch(messages.success("Счет создан"));
            history.push("/sales/bills");
        });
    };
}

export function updateItem(id, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}sales/bills/${id}`, item).then(() => {
            dispatch(fetchItem(id));
            dispatch(messages.success("Счет обновлен"));
            history.push("/sales/bills");
        });
    };
}

export function archiveItem(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}sales/bills/${id}/archive`, item).then(() => {
            dispatch(fetchItem(id));
        });
    };
}

export function sendEmail(id, email) {
    return (dispatch) => {
        axios.post(`${API_URL}sales/bills/${id}/send_bill`, email).then(() => {
            dispatch(messages.success("Счет отправлен на почту"));
        });
    };
}

export function fetchBillPosition(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}sales/bills/${id}/positions/${itemId}`).then((response) => {
            dispatch({ type: "BILL_POSITION", payload: response.data });
        });
    };
}

export function addBillPosition(id, item) {
    return (dispatch) => {
        axios.post(`${API_URL}sales/bills/${id}/positions`, item).then((response) => {
            dispatch({ type: "BILLS_ITEM", payload: response.data });
        });
    };
}

export function updateBillPosition(id, itemId, item) {
    return (dispatch) => {
        axios.patch(`${API_URL}sales/bills/${id}/positions/${itemId}`, item).then((response) => {
            dispatch({ type: "BILLS_ITEM", payload: response.data });
        });
    };
}

export function deleteBillPosition(id, itemId) {
    return (dispatch) => {
        axios.delete(`${API_URL}sales/bills/${id}/positions/${itemId}`).then((response) => {
            dispatch({ type: "BILLS_ITEM", payload: response.data });
        });
    };
}
