import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RecoveryForm from "./recovery";
import RecoveryEmailNote from "./email_note";
import RecoverySmsCodeForm from "./sms_form";

const Recovery = () => {
  const type = useSelector(state => state.users.recoveryType)

  console.log(type);

  const form = () => {
    switch (type) {
      case 'email':
        return <RecoveryEmailNote />
      case 'phone':
        return <RecoverySmsCodeForm />
      default:
        return <RecoveryForm />
    };
  };

  // if () {
  //   return <Navigate to="/" />
  // }

  return (
    form()
  );
};

export default Recovery;
