import React, { useEffect } from "react";
import { Modal, Button, Form, DatePicker, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addNumber } from "actions/equipments";

const Add = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const managers = useSelector((state) => state.users.managers);
    const user = useSelector((state) => state.globals.current_user);

    useEffect(() => {
        form.setFieldsValue({
            user_id: user?.id
        });
    }, []);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values, user: values.user };
        dispatch(addNumber(props.id, val));
        props.setIsModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Создать госномер"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Создать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={700}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 4 }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Дата"
                    name="date"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <DatePicker format={"DD.MM.YYYY"} />
                </Form.Item>

                <Form.Item
                    label="Госномер"
                    name="number"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Менеджер"
                    name="user_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано" disabled>
                        {managers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
