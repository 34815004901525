import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";

const items = [
    {
        label: <NavLink to="/">Главная</NavLink>,
        key: "main"
    },
    {
        label: "CRM",
        key: "crm",
        children: [
            {
                label: <NavLink to="/crm/calls">Звонки</NavLink>,
                key: "crm:calss"
            },
            {
                label: <NavLink to="/crm/leads">Лиды</NavLink>,
                key: "crm:leads"
            },
            {
                label: <NavLink to="/crm/requests">Заявки</NavLink>,
                key: "crm:requests"
            }
        ]
    },
    {
        label: <NavLink to="/offers">Заявки</NavLink>,
        key: "offers"
    },
    {
        label: <NavLink to="/users">Контрагенты</NavLink>,
        key: "users"
    },
    {
        label: "Сотрудники",
        key: "employees",
        children: [
            {
                label: <NavLink to="/managers">Менеджеры</NavLink>,
                key: "employees:managers"
            },
            {
                label: <NavLink to="/performers">Водители</NavLink>,
                key: "employees:performers"
            },
            {
                label: <NavLink to="/accruals">Начисления</NavLink>,
                key: "employees:accruals"
            }
        ]
    },
    {
        label: <NavLink to="/orders">Заказы</NavLink>,
        key: "orders"
    },
    {
        label: "Банк",
        key: "bankSubMenu",
        children: [
            {
                label: <NavLink to="/bank/statements">Банковские выписки</NavLink>,
                key: "statements"
            }
        ]
    },
    {
        label: "Продажи",
        key: "salesSubMenu",
        children: [
            {
                label: <NavLink to="/sales/shipments">Отгрузки</NavLink>,
                key: "sales"
            },
            {
                label: <NavLink to="/sales/bills">Счета</NavLink>,
                key: "bills"
            }
        ]
    },
    {
        label: "Покупки",
        key: "SubMenu",
        children: [
            {
                label: <NavLink to="/purchases/shipments">Приход</NavLink>,
                key: "admins"
            }
        ]
    },
    {
        label: <NavLink to="/equipments">Техника</NavLink>,
        key: "equipments"
    },
    {
        label: "Документы",
        key: "documents",
        children: [
            {
                label: <NavLink to="/documents/reports">Рапорта</NavLink>,
                key: "documents:reports"
            }
        ]
    },
    {
        label: "Справочники",
        key: "dict",
        children: [
            {
                label: <NavLink to="/dict/regions">Регионы</NavLink>,
                key: "dict:regions"
            },
            {
                label: <NavLink to="/dict/cities">Города</NavLink>,
                key: "dict:cities"
            },
            {
                label: <NavLink to="/dict/equipment-categories">Категории техники</NavLink>,
                key: "dict:equipmentcategories"
            },
            {
                label: <NavLink to="/dict/units">Единицы измерения</NavLink>,
                key: "dict:units"
            },
            {
                label: <NavLink to="/dict/nomenclature-categories">Категории номенклатуры</NavLink>,
                key: "dict:nomenclaturecategories"
            },
            {
                label: <NavLink to="/dict/nomenclature">Номенклатура</NavLink>,
                key: "dict:nomenclature"
            },
            {
                label: <NavLink to="/dict/taxes">Ставка налога</NavLink>,
                key: "dict:taxes"
            }
        ]
    }
];

function Mainmenu() {
    const dispatch = useDispatch();
    const key = useSelector((state) => state.globals.current_menu_item);

    const onClick = (e) => {
        dispatch({ type: "CURRENT_MENU_ITEM", payload: e.key });
    };

    return <Menu items={items} theme="dark" selectedKeys={key} onClick={onClick} style={{ minHeight: "1000px" }} />;
}

export default Mainmenu;
