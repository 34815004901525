import axios from "axios";
import * as messages from "./handle_messages";
import { API_URL } from "../config/conf";
import { history } from "store";

export function fetchList() {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}equipments`).then((response) => {
            dispatch({ type: "EQUIPMENTS_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}`).then((response) => {
            dispatch({ type: "EQUIPMENTS_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}equipments`, item).then(() => {
            dispatch(messages.success("Техника добавлена"));
            history.push("/equipments");
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}equipments/${item.id}`, item).then(() => {
            dispatch(messages.success("Техника обновлена"));
            history.push("/equipments");
        });
    };
}

export function fetchMileages(id) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/mileages`).then((response) => {
            dispatch({ type: "MILEAGES_LIST", payload: response.data });
        });
    };
}

export function fetchMileage(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/mileages/${itemId}`).then((response) => {
            dispatch({ type: "MILEAGES_ITEM", payload: response.data });
        });
    };
}

export function addMileage(id, values) {
    return (dispatch) => {
        axios.post(`${API_URL}equipments/${id}/mileages`, values).then(() => {
            dispatch(fetchMileages(id));
            dispatch(messages.success("Пробег добавлен"));
        });
    };
}

export function updateMileage(id, itemId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}equipments/${id}/mileages/${itemId}`, values).then(() => {
            dispatch(fetchMileages(id));
            dispatch(messages.success("Пробег обновлен"));
        });
    };
}

export function fetchNumbers(id) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/numbers`).then((response) => {
            dispatch({ type: "NUMBERS_LIST", payload: response.data });
        });
    };
}

export function fetchNumber(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/numbers/${itemId}`).then((response) => {
            dispatch({ type: "NUMBERS_ITEM", payload: response.data });
        });
    };
}

export function addNumber(id, values) {
    return (dispatch) => {
        axios.post(`${API_URL}equipments/${id}/numbers`, values).then(() => {
            dispatch(fetchNumbers(id));
            dispatch(messages.success("Номер добавлен"));
        });
    };
}

export function updateNumber(id, itemId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}equipments/${id}/numbers/${itemId}`, values).then((response) => {
            dispatch(fetchNumbers(id));
            dispatch(messages.success("Номер обновлен"));
        });
    };
}

export function fetchServices(id) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/services`).then((response) => {
            dispatch({ type: "SERVICES_LIST", payload: response.data });
        });
    };
}

export function fetchService(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/services/${itemId}`).then((response) => {
            dispatch({ type: "SERVICES_ITEM", payload: response.data });
        });
    };
}

export function addService(id, values) {
    return (dispatch) => {
        axios.post(`${API_URL}equipments/${id}/services`, values).then(() => {
            dispatch(fetchServices(id));
            dispatch(messages.success("То добавлено"));
        });
    };
}

export function updateService(id, itemId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}equipments/${id}/services/${itemId}`, values).then((response) => {
            dispatch(fetchServices(id));
            dispatch(messages.success("То обновлено"));
        });
    };
}

export function fetchInsurances(id) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/ensurences`).then((response) => {
            dispatch({ type: "INSURANCES_LIST", payload: response.data });
        });
    };
}

export function fetchInsurance(id, itemId) {
    return (dispatch) => {
        axios.get(`${API_URL}equipments/${id}/ensurences/${itemId}`).then((response) => {
            dispatch({ type: "INSURANCES_ITEM", payload: response.data });
        });
    };
}

export function addInsurance(id, values) {
    return (dispatch) => {
        axios.post(`${API_URL}equipments/${id}/ensurences`, values).then(() => {
            dispatch(fetchInsurances(id));
            dispatch(messages.success("Страховка добавлена"));
        });
    };
}

export function updateInsurance(id, itemId, values) {
    return (dispatch) => {
        axios.patch(`${API_URL}equipments/${id}/ensurences/${itemId}`, values).then((response) => {
            dispatch(fetchInsurances(id));
            dispatch(messages.success("Страховка обновлена"));
        });
    };
}
