import React from "react";
import "antd/dist/reset.css";
import "./global.css";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import configureStore, { history } from "./store";
import { ConfigProvider } from "antd";
import Main from "navigation/routing";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { Service } from "axios-middleware";
import AuthMiddleware from "middlewares/auth_middleware";
import TokenMiddleware from "middlewares/token_middleware";
import HandlerMessages from "components/ui/handler_messages";
import Notifications from "components/notifications";
import locale from "antd/locale/ru_RU";
import "dayjs/locale/ru";

export const store = configureStore();

const service = new Service(axios);
service.register([new TokenMiddleware(store), new AuthMiddleware(store, history)]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <HistoryRouter history={history}>
            <ConfigProvider locale={locale}>
                <Main />
            </ConfigProvider>
            <HandlerMessages />
            <Notifications />
        </HistoryRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
