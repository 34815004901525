import { combineReducers } from 'redux';

export function success(state = null, action) {
  switch (action.type) {
    case "SUCCESS_MESSAGE":
      return action.message;
    default:
      return state;
  };
};

export function error(state = null, action) {
  switch (action.type) {
    case "ERROR_MESSAGE":
      return action.message;
    default:
      return state;
  };
};

export const messagesReducers = () => combineReducers({
  success: success,
  error: error,
});
