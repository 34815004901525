import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { FastAddUserIcon } from "utils/svg";
import { useDispatch } from "react-redux";
import { addItem } from "actions/users";
import { MaskedInput } from "antd-mask-input";

const User = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState();
    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values) => {
        const val = { ...values, kind: "individual", kind_text: "Физическое лицо" };
        dispatch(addItem(val));
        form.resetFields();
        setIsModalOpen(false);
    };

    return (
        <>
            <div onClick={showModal} style={{ display: "flex", cursor: "pointer" }}>
                <FastAddUserIcon />
            </div>

            <Modal
                title="Создать физ. лицо"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Создать
                    </Button>,
                    <Button key="back" onClick={handleCancel}>
                        Закрыть
                    </Button>
                ]}
                width={800}
            >
                <Form autoComplete="off" colon={false} labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
                    <Form.Item label="Наименование" name="name" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Input placeholder="Не указано" />
                    </Form.Item>

                    <Form.Item label="Телефон" name="phone" rules={[{ required: true, message: "Заполните поле" }]}>
                        <MaskedInput mask="+7(000)000-00-00" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default User;
