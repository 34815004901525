import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "components/layout";
import List from "../../components/dict/units";
import Item from "../../components/dict/units/item";
import Add from "../../components/dict/units/add";

const Units = () => {
    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<List />} />
                <Route exact path="/add" element={<Add />} />
                <Route exact path="/:id" element={<Item />} />
            </Routes>
        </MainLayout>
    );
};

export default Units;
