import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { Tag, Popover, Badge } from "antd";
import { Link } from "react-router-dom";
import Month from "./month";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { CurrencyFormat } from "../../../utils/currency";
import { DocumentIcon, Documents } from "utils/svg";
import Processing from "./processing";
import Unshipment from "./unshipment";
import { clearFilterList } from "actions/orders";
import Filter from "../filter";
import { CloseOutlined } from "@ant-design/icons";
import { DATE_AND_TIME_FORMAT } from "constants/globals";

const OrdersTabs = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const filterValues = useSelector((state) => state.orders.filterValues);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const totalPrice = (orders) => {
        let total = 0;
        Object.keys(orders).map((key) => (total += orders[key]["total"]));
        return <CurrencyFormat value={total.toFixed(2)} />;
    };

    const buttons = (
        <>
            {filterValues ? (
                <Button
                    type="primary"
                    onClick={() => {
                        dispatch(clearFilterList());
                    }}
                >
                    <CloseOutlined />
                </Button>
            ) : undefined}
            <Button type="primary" onClick={showModal} style={{ marginLeft: 5 }}>
                Фильтры
            </Button>
            <Link to="/orders/add" style={{ marginLeft: 5 }}>
                <Button type="primary">Создать</Button>
            </Link>
        </>
    );

    const columns = [
        {
            title: "№",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Стоимость",
            dataIndex: "",
            key: "total",
            render: (text) => {
                return (
                    <div>
                        <CurrencyFormat value={text.total.toFixed(2)} />
                        <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>{text.user.name ? text.user.name : "не выбран"}</div>
                    </div>
                );
            }
        },
        {
            title: "Время",
            dataIndex: "work_at",
            key: "work_at",
            render: (text, item) => {
                return (
                    <div>
                        <div>{dayjs(text).format(DATE_AND_TIME_FORMAT)}</div>
                        <div style={{ fontSize: 12, color: "#777" }}>{item.client?.phone}</div>
                    </div>
                );
            }
        },
        {
            title: "Клиент",
            dataIndex: "client",
            key: "client",
            render: (text, item) => {
                {
                    switch (item.kind) {
                        case "subcontractor":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Субподрядчик</div>
                                </div>
                            );
                        case "supply":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Материал</div>
                                </div>
                            );
                        case "inner":
                            return <div style={{ fontSize: 12, color: "#777" }}>Внутренняя</div>;
                        case "equipment":
                            return (
                                <div>
                                    <div>{text.name}</div>
                                    <div style={{ fontSize: 12, color: "#777" }}>Техника</div>
                                </div>
                            );
                        default:
                            "";
                    }
                }
            }
        },
        {
            title: "Техника",
            dataIndex: "equipment",
            key: "equipment",
            render: (text, item) => {
                return (
                    <div>
                        {text ? (
                            <Popover content={text.name} trigger="hover">
                                {text.number} {item.performer ? <div style={{ fontSize: 12, color: "#777" }}>{item.performer.name}</div> : null}
                            </Popover>
                        ) : item.kind === "supply" ? (
                            <div>Материал</div>
                        ) : (
                            <div>
                                Субподрядчик
                                <div style={{ fontSize: 12, marginTop: 3, color: "#777" }}>
                                    {item.subcontractor ? item.subcontractor.name : "не выбран"}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                let icon = null;
                switch (text) {
                    case "processing":
                        icon = <Tag color="#f50">В работе</Tag>;
                        break;
                    case "completed":
                        icon = <Tag color="#87d068">Завершен</Tag>;
                        break;
                    case "canceled":
                        icon = <Tag color="#777777">Отменена</Tag>;
                        break;
                }

                return <div className="status">{icon}</div>;
            }
        },
        {
            title: "Рапорт",
            dataIndex: "reports",
            key: "reports",
            render: (text, item) => {
                let icon = null;
                switch (text.length) {
                    case 1:
                        icon = <DocumentIcon />;
                        break;
                    case 2:
                        icon = <DocumentIcon />;
                        break;
                    case 0:
                        icon = <Documents />;
                        break;
                }

                return <Badge count={item.rel_reports}>{icon}</Badge>;
            }
        }
    ];

    const tabs = [
        {
            key: "1",
            label: `За месяц`,
            children: <Month columns={columns} totalPrice={totalPrice} filterValues={filterValues} />
        },
        // {
        //     key: "2",
        //     label: "По дням",
        //     children: <Day columns={columns} totalPrice={totalPrice} />
        // },
        {
            key: "3",
            label: "В работе",
            children: <Processing columns={columns} totalPrice={totalPrice} filterValues={filterValues} />
        },
        {
            key: "4",
            label: "Не отгруженные",
            children: <Unshipment columns={columns} totalPrice={totalPrice} filterValues={filterValues} />
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={buttons} />
            <Filter setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} columns={columns} />
        </div>
    );
};

export default OrdersTabs;
