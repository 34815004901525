import React, { useState, useEffect } from "react";
import { Card, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchService, fetchServices } from "actions/equipments";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import Add from "./add";
import Edit from "./edit";

const Services = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItemModalOpen, setIsItemModalOpen] = useState(false);
    const list = useSelector((state) => state.equipments.services);
    const users = useSelector((state) => state.users.data);

    useEffect(() => {
        dispatch(fetchServices(id));
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showItemModal = (item) => {
        dispatch(fetchService(id, item.id));
        setIsItemModalOpen(true);
    };

    const contractorName = (value) => {
        if (value !== null) {
            const user = users.filter((item) => item.id === value.id);
            return user[0]?.name;
        }
        return "Нет ответственного";
    };

    const columns = [
        {
            title: "Дата",
            dataIndex: "date",
            key: "date",
            render: (text) => {
                return dayjs(text).format(DATE_FORMAT);
            }
        },
        {
            title: "Моточасы",
            dataIndex: "hours",
            key: "hours",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Пробег",
            dataIndex: "mileage",
            key: "mileage",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Ответственный",
            dataIndex: "user",
            key: "user",
            render: (text) => {
                return contractorName(text);
            }
        }
    ];

    return (
        <Card
            title="То"
            extra={
                <Button type="primary" onClick={showModal}>
                    Создать
                </Button>
            }
        >
            <Add setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={id} />
            <Edit
                setIsItemModalOpen={setIsItemModalOpen}
                isItemModalOpen={isItemModalOpen}
                id={id}
            />
            <Table
                className={"data-table data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
                onRow={(item) => {
                    return {
                        onClick: () => {
                            showItemModal(item);
                        }
                    };
                }}
            />
        </Card>
    );
};

export default Services;
