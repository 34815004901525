import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { Card, Form, Switch, Button, Input, Select } from "antd";
import { addItem } from "actions/cities";
import { fetchList } from "actions/regions";
const Add = () => {
  const dispatch = useDispatch();
  const list = useSelector(state => state.regions.list);

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  const onFinish = (values) => {
    dispatch(addItem(values))
  };

  return (
    <Card title="Создание города" extra={<Link to="/dict/cities"><Button type='primary'>Назад</Button></Link>}>
      <Form autoComplete="off" onFinish={onFinish}
        labelCol={{ span: 5 }}>

        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Выберите регион' }]}>
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Регион" name="region_id" rules={[{ required: true, message: 'Выберите регион' }]}>
          <Select>
            {list.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
          </Select>
        </Form.Item>

        <Form.Item label="Столица" name="capital" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
      </Form>
    </Card>
  );
};

export default Add;
