import axios from "axios";
import * as messages from "./handle_messages";
import { history } from "store";
import { API_URL } from "config/conf";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}dict/taxes`).then((response) => {
            dispatch({ type: "TAXES_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}dict/taxes/${id}`).then((response) => {
            dispatch({ type: "TAXES_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}dict/taxes`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Ставка добавлена"));
            history.push("/dict/taxes");
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}dict/taxes/${item.id}`, item).then(() => {
            dispatch(fetchList());
            dispatch(messages.success("Ставка обновлена"));
            history.push("/dict/taxes");
        });
    };
}
