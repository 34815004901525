import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logout } from '../../actions/login';

const LogoutComponent = () => {

  useEffect(() => {
    logout()
  });

  return (
      <Navigate to="/"/>
  );

}

export default LogoutComponent;