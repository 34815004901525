import { updateProfileSettingsItem } from "actions/notifications";
import React from "react";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ManagerNotificationsSettings from "./manager";
import AdminNotificationsSettings from "./admin";
import PerformerNotificationsSettings from "./performer";

const NotificationsSettings = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.globals.current_user);

    const changeNotification = (e, id, type) => {
        if (type === "telegram") {
            dispatch(updateProfileSettingsItem(id, { telegram: e.target.checked }));
        } else if (type === "email") {
            dispatch(updateProfileSettingsItem(id, { email: e.target.checked }));
        } else {
            dispatch(updateProfileSettingsItem(id, { websoket: e.target.checked }));
        };
    };

    const columns = [
        {
            title: "Наименование",
            dataIndex: "notification_type",
            key: "title",
            render: (text) => {
                return text.description;
            }
        },
        {
            title: "Телеграм",
            dataIndex: "telegram",
            key: "telegram",
            width: 100,
            render: (text, item) => {
                return <Checkbox defaultChecked={text} onChange={(e) => { changeNotification(e, item.id, "telegram") }} />;
            }
        },
        {
            title: "Почта",
            dataIndex: "email",
            key: "email",
            width: 100,
            render: (text, item) => {
                return <Checkbox defaultChecked={text} onChange={(e) => { changeNotification(e, item.id, "email") }} />;
            }
        },
        {
            title: "Сайт",
            dataIndex: "websoket",
            key: "websoket",
            width: 100,
            render: (text, item) => {
                return <Checkbox defaultChecked={text} onChange={(e) => { changeNotification(e, item.id, "websoket") }} />;
            }
        },
    ];


    const checkRole = (item) => {
        switch (item) {
            case "manager":
                return <ManagerNotificationsSettings columns={columns} />
            case "admin":
                return <AdminNotificationsSettings columns={columns} />
            case "performer":
                return <PerformerNotificationsSettings columns={columns} />
            default:
                undefined
        };
    };

    return (
        <>
            {
                user.user_roles.map((item) => {
                    return checkRole(item)
                })
            }
        </>
    );
};

export default NotificationsSettings;