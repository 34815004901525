import React, { useEffect } from "react";
import { Typography, Table } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";
import { CurrencyFormat } from "utils/currency";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "actions/accruals";

const List = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const list = useSelector((state) => state.accruals.list);

    useEffect(() => {
        dispatch(fetchList());
    }, []);

    const columns = [
        {
            title: "Дата",
            dataIndex: "date",
            key: "date",
            render: (text) => {
                return <div>{dayjs(text).format(DATE_FORMAT)}</div>;
            }
        },
        {
            title: "Сумма",
            dataIndex: "amount",
            key: "amount",
            render: (text) => {
                return <div>{<CurrencyFormat value={text} />}</div>;
            }
        },
        {
            title: "Водитель",
            dataIndex: "user",
            key: "user",
            render: (text) => {
                return <div>{text.name}</div>;
            }
        },
        {
            title: "Описание",
            dataIndex: "comment",
            key: "comment",
            render: (text) => {
                return <div>{text}</div>;
            }
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>Начисления</Typography.Title>
            <Table
                className={"data-table"}
                bordered
                rowKey="id"
                columns={columns}
                dataSource={list}
                pagination={false}
                locale={{ emptyText: "Нет данных" }}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/accruals/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
