import React, { useEffect } from "react";
import { Modal, Form, Select, Button, DatePicker, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues } from "actions/offers";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";

const Filter = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.users.data);
    const managers = useSelector((state) => state.users.managers);
    const filterValues = useSelector((state) => state.offers.filterValues);

    useEffect(() => {
        if (filterValues) {
            form.setFieldsValue(filterValues);
        } else {
            form.resetFields();
        }
    }, [filterValues]);

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let val = {
            ...values,
            date: values.date ? `${dayjs(values.date[0]).format(DATE_FORMAT)}-${dayjs(values.date[1]).format(DATE_FORMAT)}` : undefined
        };
        dispatch(setFilterValues(val));
        form.resetFields();
        props.setIsModalOpen(false);
    };

    return (
        <Modal
            title="Фильтры"
            open={props.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Отфильтровать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 2 }} form={form} onFinish={onFinish}>
                <Form.Item label="Дата" name="date">
                    <DatePicker.RangePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item label="Поиск" name="q">
                    <Input type="text" placeholder="" />
                </Form.Item>

                <Form.Item label="Клиент" name="client">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Менеджер" name="manager">
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {managers.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Filter;
