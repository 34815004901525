import React from "react";
import { Typography } from "antd";
import OffersTabs from "./tabs";

const List = () => {
    return (
        <div>
            <Typography.Title level={2}>Заявки</Typography.Title>
            <OffersTabs />
        </div>
    );
};

export default List;
