import React from 'react'
import { DatePicker } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { globalMonth } from "actions/globals";

const MonthPicker = () => {
  const dispatch = useDispatch();
  const month = useSelector(state => state.globals.month);

  const handleChangeMonth = (item) => {
    dispatch(globalMonth(item));
  };

  return (
    <DatePicker.MonthPicker onChange={handleChangeMonth} placeholder="Месяц" format="MMMM YYYY" defaultValue={month} value={month} mode={"month"} allowClear={false} />
  )
}

export default MonthPicker;



