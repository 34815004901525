import React from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { changePassword } from "actions/login";

const Password = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const checkConfirmPassword = (rule, value) => {
        if (value && value !== form.getFieldValue("password")) {
            return Promise.reject("Пароли не совпадают!");
        } else {
            return Promise.resolve();
        }
    };

    const onFinish = (values) => {
        const val = { ...values, confirm_password: null };
        dispatch(changePassword(val));
        form.resetFields();
    };

    return (
        <Form autoComplete="off" labelCol={{ span: 5 }} onFinish={onFinish} form={form}>
            <Form.Item
                label="Старый пароль"
                name="old_password"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Новый пароль"
                name="password"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Повтор нового пароля"
                name="confirm_password"
                rules={[
                    { required: true, message: "Заполните поле" },
                    { validator: checkConfirmPassword }
                ]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", marginLeft: "auto" }}
            >
                Обновить
            </Button>
        </Form>
    );
};

export default Password;
