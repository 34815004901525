import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Typography, Col, Space, Table } from "antd";

const List = () => {
    const navigate = useNavigate();
    const list = useSelector((state) => state.equipmentcategories.list);

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return <div>{text}</div>;
            }
        }
    ];

    return (
        <div>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Категории техники</Typography.Title>
                </Col>

                <Col>
                    <Link to="/dict/equipment-categories/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <br />
            <br />
            <Table
                className={"data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowClassName={(record) => (!record.active ? "archive" : "active")}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/dict/equipment-categories/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
