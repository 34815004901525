import React, { useEffect } from "react";
import { Card, Button, Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItem, checkIndividualINN } from "actions/users";
import { MaskedInput } from "antd-mask-input"

const Individual = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const cities = useSelector(state => state.globals.citiesActive);
  const checkedINN = useSelector(state => state.users.checkedIndividualINN);

  useEffect(() => {
    if (checkedINN) {
      form.setFieldsValue(checkedINN);
    }
  });

  const validateINN = () => {
    const inn = form.getFieldValue("inn");
    dispatch(checkIndividualINN(inn));
  };

  const onFinish = (values) => {
    const val = { ...values, kind: "individual", kind_text: "Физическое лицо" };
    dispatch(addItem(val));
  };

  return (
    <Card title="Создание пользователя" extra={<Link to="/users"><Button type='primary'>Назад</Button></Link>}>
      <Form autoComplete="off" onFinish={onFinish} form={form} labelCol={{ span: 5 }}>

        <Form.Item
          label="ИНН"
          name="inn"
          rules={[{ pattern: /^\d+$/, message: "только цифры" }]}
        >
          <Input.Search
            placeholder="Не указано"
            enterButton="Проверить ИНН"
            size="middle"
            maxLength={12}
            onSearch={validateINN}
          />
        </Form.Item>

        <Form.Item label="Фамилия" name="last_name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Имя" name="first_name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Отчество" name="middle_name">
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Наименование в системе" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Наименование в документах" name="official_name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Электронная почта" name="email" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input placeholder="Не указано" type="email" />
        </Form.Item>

        <Form.Item label="Телефон" name="phone" rules={[{ required: true, message: 'Заполните поле' }]}>
          <MaskedInput mask={'+7(000)000-00-00'} />
        </Form.Item>

        <Form.Item label="Описание" name="comment">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Город" name="city_id">
          <Select placeholder="Не указано">
            {cities.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
      </Form>
    </Card>
  );
};

export default Individual;
