import { combineReducers } from 'redux';
import dayjs from "dayjs";

export function month(state = dayjs(), action) {
  switch (action.type) {
    case "MONTH":
      return action.payload;
    default:
      return state;
  };
};

export function daysInMonth(state = [], action) {
  switch (action.type) {
    case "DAYS_IN_MONTH":
      return action.payload;
    default:
      return state;
  };
};

export function current_user(state = null, action) {
  switch (action.type) {
    case "CURRENT_USER":
      return action.payload;
    default:
      return state;
  };
};

export function current_menu_item(state = [], action) {
  switch (action.type) {
    case "CURRENT_MENU_ITEM":
      return action.payload;
    default:
      return state;
  };
};

export function loadingList(state = false, action) {
  switch (action.type) {
    case "LOADING_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function preloading(state = false, action) {
  switch (action.type) {
    case "PRELOADER":
      return action.payload;
    default:
      return state;
  };
};

export function updateButton(state = true, action) {
  switch (action.type) {
    case "UPDATE_BUTTON":
      return action.payload;
    default:
      return state;
  };
};

export function citiesActive(state = [], action) {
  switch (action.type) {
    case "CITIES_ACTIVE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function nomenclatureCategoriesActive(state = [], action) {
  switch (action.type) {
    case "NOMENCLATURE_CATEGORIES_ACTIVE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function equipmentCategoriesActive(state = [], action) {
  switch (action.type) {
    case "EQUIPMENT_CATEGORIES_ACTIVE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function equipmentActive(state = [], action) {
  switch (action.type) {
    case "EQUIPMENT_ACTIVE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export function nomenclatureActive(state = [], action) {
  switch (action.type) {
    case "NOMENCLATURE_ACTIVE_LIST":
      return action.payload;
    default:
      return state;
  };
};

export const globalsReducers = () => combineReducers({
  month: month,
  daysInMonth: daysInMonth,
  current_user: current_user,
  current_menu_item: current_menu_item,
  loadingList: loadingList,
  preloading: preloading,
  updateButton: updateButton,
  month: month,
  citiesActive: citiesActive,
  nomenclatureCategoriesActive: nomenclatureCategoriesActive,
  equipmentCategoriesActive: equipmentCategoriesActive,
  equipmentActive: equipmentActive
});
