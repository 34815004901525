import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, Form, Input, Button, Select } from "antd";
import { fetchItem, updateItem } from "actions/nomenclature";

const Item = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const item = useSelector(state => state.nomenclature.item)
  const units = useSelector(state => state.units.list)
  const categories = useSelector(state => state.nomсategories.list)

  useEffect(() => {
    dispatch(fetchItem(id));
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(item);
  });

  const onFinish = (values) => {
    let val = { ...values, id: item.id }
    dispatch(updateItem(val))
  };

  return (
    <Card title="Редактирование" extra={<Link to="/dict/nomenclature"><Button type="primary">Назад</Button></Link>}>

      <Form autoComplete="off" form={form} onFinish={onFinish} labelCol={{ span: 5 }}>
        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Наименование для поиска" name="sname" rules={[{ required: true, message: 'Заполните поле' }]}>
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Form.Item
          label="Единица измерения"
          name="unit_id"
          rules={[{ required: true, message: 'Выберите единицу измерения' }]}
        >
          <Select>
            {units.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Категория"
          name="category_id"
          rules={[{ required: true, message: 'Выберите категорию' }]}
        >
          <Select>
            {categories.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Сохранить</Button>
      </Form>
    </Card>
  );
};

export default Item;
