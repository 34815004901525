import React, { useEffect, useState } from "react";
import { Card, Button, Form, DatePicker, Input, Select, Radio } from "antd";
import { Link, useParams } from "react-router-dom";
import { DATE_FORMAT } from "constants/globals";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateItem, fetchItem, archiveItem, deleteBillPosition, fetchBillPosition, addBillPosition, updateBillPosition } from "actions/bills";
import { MailOutlined } from "@ant-design/icons";
import Mail from "./mail";
import { CurrencyFormat } from "utils/currency";
import Positions from "components/positions";

const Item = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const managers = useSelector((state) => state.users.managers);
    const clients = useSelector((state) => state.users.data);
    const item = useSelector((state) => state.bills.item);
    const [isMailModalOpen, setIsMailModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchItem(id));
    }, []);

    useEffect(() => {
        const new_item = { ...item, date: dayjs(item.date), client_id: item.client?.id, user_id: item.user?.id };
        form.setFieldsValue(new_item);
    });

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(updateItem(id, val));
    };

    const handleArchive = () => {
        const val = { archived: !item.archived };
        dispatch(archiveItem(id, val));
    };

    const showMailModal = () => {
        setIsMailModalOpen(true);
    };

    return (
        <div>
            <Card
                title="Редактирование счета"
                extra={[
                    <Button type="primary" style={{ marginRight: 5 }} onClick={showMailModal} disabled={item.archived || !item.positions?.length}>
                        <MailOutlined />
                    </Button>,
                    <Button type="primary" style={{ marginRight: 5 }} onClick={handleArchive}>
                        {item.archived ? "Разархивировать" : "Архивировать"}
                    </Button>,
                    <Link to="/sales/bills">
                        <Button type="primary">Назад</Button>
                    </Link>
                ]}
            >
                <Mail isMailModalOpen={isMailModalOpen} setIsMailModalOpen={setIsMailModalOpen} id={id} />
                <Form autoComplete="off" labelCol={{ span: 5 }} onFinish={onFinish} form={form}>
                    <Form.Item label="Дата счета" name="date" rules={[{ required: true, message: "Заполните поле" }]}>
                        <DatePicker placeholder="Не указано" format={DATE_FORMAT} disabled={item.archived} />
                    </Form.Item>

                    <Form.Item label="Статус оплаты" name="status">
                        <Radio.Group disabled={item.archived}>
                            <Radio.Button value="unpaid">Не оплаченная</Radio.Button>
                            <Radio.Button value="paid">Оплаченная</Radio.Button>
                            <Radio.Button value="partially_paid">Частично оплаченная</Radio.Button>
                            <Radio.Button value="canceled">Отменен</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Статус отгрузки" name="shipment_status">
                        <Radio.Group disabled={item.archived}>
                            <Radio.Button value="unshipment">Неотгружено</Radio.Button>
                            <Radio.Button value="shipment">Отгружено</Radio.Button>
                            <Radio.Button value="partialy_shipment">Частично отгружено</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Номер" name="uid" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Input type="text" placeholder="Не указано" disabled={item.archived} maxLength={20} minLength={20} />
                    </Form.Item>

                    <Form.Item label="Клиент" name="client_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select
                            showSearch
                            placeholder="Выберите клиента"
                            allowClear
                            filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                        >
                            {clients.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Описание" name="comment">
                        <Input.TextArea placeholder="Не указано" disabled={item.archived} />
                    </Form.Item>

                    <Form.Item label="Менеджер" name="user_id" rules={[{ required: true, message: "Заполните поле" }]}>
                        <Select showSearch filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}>
                            {managers.map((item) => (
                                <Select.Option label={item.name} key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Итог" name="amount">
                        <CurrencyFormat value={item.amount} />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" disabled={item.archived} style={{ display: "block", marginLeft: "auto" }}>
                        Обновить
                    </Button>
                </Form>
            </Card>
            <br />
            <br />
            <Positions
                type="bills"
                fetchPosition={fetchBillPosition}
                updatePosition={updateBillPosition}
                deletePosition={deleteBillPosition}
                addPosition={addBillPosition}
                id={id}
                archived={item.archived}
                list={item.positions}
            />
        </div>
    );
};

export default Item;
