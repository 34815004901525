import React from "react";
import { Tabs, Typography  } from "antd";
import All from "./all";
import Subscriptions from "./subscriptions";

const CallsTabs = () => {

    const items = [
        {
            key: "1",
            label: `Звонки`,
            children: <All />
        },
        {
            key: "2",
            label: `Подписки`,
            children: <Subscriptions />
        }
    ];

    return (
        <>
            <Typography.Title level={2}>Звонки</Typography.Title>
            <Tabs defaultActiveKey="1" items={items}/>
        </>
    );
};

export default CallsTabs;
