export const CASH_LIST = "CASH_LIST";
export const CASH_LIST_ARE_LOADING = "CASH_LIST_ARE_LOADING";
export const CASH_BY_ID = "CASH_BY_ID";
export const CASH_BY_ID_LOADING = "CASH_BY_ID_LOADING";
export const CASH_LAST_ADDED_ITEM = "CASH_LAST_ADDED_ITEM";
export const CASH_MANAGERS = 'CASH_MANAGERS';
export const OPERATION_TYPES = [
    {
        key: "income",
        value: "Входящий"
    },
    {
        key: "expense",
        value: "Исходящий"
    },
    {
        key: "transition",
        value: "Перевод между"
    }
];