import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Input, Button, Upload, Select, DatePicker } from "antd";
import { updateItem } from "actions/reports";
import ShowReport from "./show_report";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/globals";

const Own = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const performers = useSelector((state) => state.users.performers);

    useEffect(() => {
        form.setFieldsValue({
            ...props.item,
            begin_at: dayjs(props.item.begin_at),
            end_at: dayjs(props.item.end_at),
            performer_id: props.item.performer?.id
        });
    });

    const onFinish = (values) => {
        let val = {
            ...values,
            id: props.id,
            begin_at: values.begin_at.format(),
            end_at: values.end_at.format(),
            comment: values.comment ? values.comment : ""
        };
        dispatch(updateItem(val));
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList[0].originFileObj;
    };

    return (
        <Card
            title="Редактирование"
            extra={
                <Link to="/documents/reports">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form autoComplete="off" form={form} onFinish={onFinish} labelCol={{ span: 5 }}>
                <Form.Item label="Дата начала" name="begin_at">
                    <DatePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item label="Дата конца" name="end_at">
                    <DatePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item label="Номер" name="number">
                    <Input disabled placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Техника" name="equipment_id">
                    <Select>
                        {props.equipments.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Водитель" name="performer_id">
                    <Select>
                        {performers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {props.item.has_file ? (
                    <Form.Item label="Рапорт">
                        <ShowReport
                            id={props.id}
                            itemId={props.item.id}
                            file={props.item.file_url}
                        />
                    </Form.Item>
                ) : (
                    <Form.Item label="Файл" name="file" getValueFromEvent={normFile}>
                        <Upload maxCount={1} beforeUpload={() => false}>
                            <Button>Загрузить рапорт</Button>
                        </Upload>
                    </Form.Item>
                )}

                <Form.Item label="Описание" name="comment">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Город" name="city_id">
                    <Select>
                        {props.cities.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Менеджер" name="author_id">
                    <Select>
                        {props.managers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Сохранить
                </Button>
            </Form>
        </Card>
    );
};

export default Own;
