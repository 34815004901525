import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Select, Input } from "antd";
import { addItem } from "actions/equipments";

const EquipmentsAdd = () => {
    const dispatch = useDispatch();
    const cities = useSelector((state) => state.globals.citiesActive);
    const categories = useSelector((state) => state.globals.equipmentCategoriesActive);

    const onFinish = (values) => {
        const val = { ...values };
        dispatch(addItem(val));
    };

    return (
        <Card
            title="Создание техники"
            extra={
                <Link to="/equipments">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form autoComplete="off" labelCol={{ span: 5 }} onFinish={onFinish}>
                <Form.Item
                    label="Наименование"
                    name="name"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Категория"
                    name="category_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {categories.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Город"
                    name="city_id"
                    rules={[{ required: true, message: "Выберите город" }]}
                >
                    <Select placeholder="Не указано">
                        {cities.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Интервал пробега"
                    name="period_mileage"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Интервал моточасов"
                    name="period_hours"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Комментарии" name="description">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Создать
                </Button>
            </Form>
        </Card>
    );
};

export default EquipmentsAdd;
