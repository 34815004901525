import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, Form, Button, Input } from "antd"
import { addItem } from "actions/units"

const Add = () => {
	const dispatch = useDispatch();

	const onFinish = (values) => {
		dispatch(addItem(values))
	};

	return (
		<Card title="Создание единицы измерения" extra={<Link to="/dict/units"><Button type="primary">Назад</Button></Link>}>
			<Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 5 }}>
				<Form.Item label="Наименование" name="name" rules={[{ required: true, message: "Укажите наименование" }]}>
					<Input type="text" placeholder="Не указано" />
				</Form.Item>
				<Form.Item label="Условное обозначение" name="mark" rules={[{ required: true, message: "Укажите обозначение" }]}>
					<Input type="text" placeholder="Не указано" />
				</Form.Item>
				<Form.Item label="Код" name="code" rules={[{ required: true, message: "Укажите код" }]}>
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
			</Form>
		</Card>
	);
};

export default Add;
