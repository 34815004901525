import React from "react";
import { Modal, Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addNumber } from "actions/users";

const Add = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCancel = () => {
        props.setIsAddModalOpen(false);
    };

    const onFinish = (values) => {
        const val = { ...values, user_id: props.id };
        dispatch(addNumber(props.id, val));
        props.setIsAddModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Добавить номер"
            open={props.isAddModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Создать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 4 }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item label="Номер" name="number">
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
