import { useDispatch } from 'react-redux';
import { Form, Input, Button, Space } from "antd";
import { Link } from 'react-router-dom';
import AuthLayout from "components/layout/auth_layout";
import { resetpassword } from 'actions/login';

const Recovery = () => {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(resetpassword(values))
  };

  return (
    <AuthLayout>
      <Form initialValues={{ remember: true }} onFinish={onFinish}>
        <h2 style={{ textAlign: "center" }}>Восстановить пароль</h2>
        <p style={{ textAlign: "center" }} >Для восстановления пароля введите вашу почту или номер телефона, на который мы отправим вам дальнейшие инструкции</p>

        <Form.Item name="login" rules={[{ required: true, message: 'Введите логин' }]}>
          <Input autoComplete="off" />
        </Form.Item>

        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Link to="/login">Назад</Link>
          <Button type="primary" htmlType="submit">Отправить</Button>
        </Space>
      </Form>
    </AuthLayout>
  );
};

export default Recovery;
