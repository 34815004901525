import React from "react";
import { Card, Form, Button, Select, DatePicker, Input, Upload } from "antd";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "constants/globals";

const Subcontractor = (props) => {
    const [form] = Form.useForm();

    const normFile = (e) => {
        return e?.fileList[0].originFileObj;
    };

    return (
        <Card
            title="Создание рапорта"
            extra={
                <Link to="/documents/reports">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form
                autoComplete="off"
                labelCol={{ span: 5 }}
                onFinish={(values) => {
                    props.onFinish(values);
                }}
                fields={[
                    {
                        name: ["author_id"],
                        value: props.user?.name
                    },
                    {
                        name: ["city_id"],
                        value: props.user?.city?.name
                    }
                ]}
                form={form}
            >
                <Form.Item
                    label="Дата"
                    name="date"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <DatePicker.RangePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item
                    label="Клиент"
                    name="client_id"
                    rules={[{ required: true, message: "Выберите клиента" }]}
                >
                    <Select placeholder="Не указано">
                        {props.clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Субподрядчик"
                    name="subcontractor_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {props.clients.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Техника субподрядчика"
                    name="subcontractor_equipment"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {props.equipments.map((item) => (
                            <Select.Option key={item.id} value={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Файл"
                    name="file"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: "Загрузите файл" }]}
                >
                    <Upload maxCount={1} beforeUpload={() => false}>
                        <Button>Загрузить рапорт</Button>
                    </Upload>
                </Form.Item>

                <Form.Item label="Описание" name="comment">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Город"
                    name="city_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {props.cities.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Менеджер"
                    name="author_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {props.managers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Создать
                </Button>
            </Form>
        </Card>
    );
};

export default Subcontractor;
