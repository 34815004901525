import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompanyUser, fetchCompanyUsers, fetchCompanyUser } from "actions/users";
import { Button, List, Card, Space } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Add from "./add";
import Edit from "./edit";

const Access = (props) => {
    const dispatch = useDispatch();
    const numbers = useSelector((state) => state.users.companyUsers);
    const users = useSelector((state) => state.users.data);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isItemModalOpen, setIsItemModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchCompanyUsers(props.id));
    }, []);

    const showAddModal = () => {
        setIsAddModalOpen(true);
    };

    const showItemModal = (item) => {
        dispatch(fetchCompanyUser(props.id, item));
        setIsItemModalOpen(true);
    };

    const filterUser = (id) => {
        const user = users.filter((item) => item.id === id);
        return (
            <div>
                {user[0].name} {user[0].phone}
            </div>
        );
    };

    return (
        <div>
            <Add id={props.id} isAddModalOpen={isAddModalOpen} setIsAddModalOpen={setIsAddModalOpen} />
            <Edit setIsItemModalOpen={setIsItemModalOpen} isItemModalOpen={isItemModalOpen} id={props.id} />
            <Card
                title="Доступ"
                extra={
                    <Button type="primary" onClick={showAddModal} disabled={props.archived}>
                        <PlusOutlined />
                    </Button>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={numbers}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                padding: "16px",
                                cursor: "pointer"
                            }}
                            onDoubleClick={() => {
                                showItemModal(item.id);
                            }}
                        >
                            <List.Item.Meta title={filterUser(item.company_id)} style={{ minWidth: "50px" }} />
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        dispatch(deleteCompanyUser(props.id, item.id));
                                    }}
                                    disabled={props.archived}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Space>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

export default Access;
