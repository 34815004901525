import { combineReducers } from "redux";

export function list(state = [], action) {
    switch (action.type) {
        case "REGIONS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "REGIONS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export const regionsReducers = () =>
    combineReducers({
        list: list,
        item: item
    });
