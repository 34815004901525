import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, Form, Input, Button } from "antd";
import { fetchItem, updateItem } from "actions/regions";

const Item = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const item = useSelector(state => state.regions.item)

  useEffect(() => {
    dispatch(fetchItem(id));
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(item);
  });

  const onFinish = (values) => {
    let val = { ...values, id: item.id }
    dispatch(updateItem(val));
  };

  return (
    <Card title="Редактирование" extra={
      <Link to="/dict/regions"><Button type="primary">Назад</Button></Link>
    }>

      <Form
        autoComplete="off"
        colon={false}
        labelCol={{ span: 5 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item label="Наименование" name="name">
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Код региона" name="code">
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Сохранить</Button>
      </Form>
    </Card>
  );
};

export default Item;
