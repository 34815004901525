import React, { useEffect, useState } from "react";
import { Typography, Table, Button, Space, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { fetchList, clearFilterList, fetchFilterList } from "actions/users";
import { CloseOutlined } from "@ant-design/icons";
import Filter from "./filter";

const List = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const list = useSelector((state) => state.users.data);
    const filterList = useSelector((state) => state.users.filterList);
    const filterValues = useSelector((state) => state.users.filterValues);

    useEffect(() => {
        if (filterValues) {
            dispatch(fetchFilterList(filterValues));
        } else {
            dispatch(fetchList());
        }
    }, [filterValues]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const kind = (kind) => {
        return kind === "individual" ? "Физическое лицо" : "Компания"
    }

    const columns = [
        {
            title: "Тип",
            dataIndex: "kind",
            key: "kind",
            render: (text) => {
                return <div>{kind(text)}</div>;
            }
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return <div>{text}</div>;
            }
        },
        {
            title: "ИНН",
            dataIndex: "inn",
            key: "inn",
            render: (text) => {
                return <div>{text ? text : "-"}</div>;
            }
        },
        {
            title: "Электронная почта",
            dataIndex: "email",
            key: "email",
            render: (text) => {
                return <div>{text ? text : "-"}</div>;
            }
        },
        {
            title: "Телефон",
            dataIndex: "phone",
            key: "phone",
            render: (text) => {
                return <div>{text ? text : "-"}</div>;
            }
        }
    ];

    return (
        <div>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Контрагенты</Typography.Title>
                </Col>

                <Col>
                    {filterValues ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                dispatch(clearFilterList());
                            }}
                        >
                            <CloseOutlined />
                        </Button>
                    ) : undefined}
                    <Button type="primary" onClick={showModal} style={{ marginRight: 5, marginLeft: 5 }}>
                        Фильтры
                    </Button>
                    <Link to="/users/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <Filter isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <br />
            <br />
            <Table
                className={"data-table"}
                pagination={false}
                bordered
                tableLayout="fixed"
                rowKey="id"
                columns={columns}
                dataSource={filterList.length ? filterList : list}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/users/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
