let API_URL = null;
let REACT_APP_ENV = null;
let WEBSOCKET = null;

if (process.env.REACT_APP_MODE === "production") {
    REACT_APP_ENV = process.env.REACT_APP_MODE;
    console.log("App environment is set to " + REACT_APP_ENV);
    API_URL = "https://takeheavy.ru/managers/";
    WEBSOCKET = "wss://takeheavy.ru/cable";
} else if (process.env.REACT_APP_MODE === "staging") {
    REACT_APP_ENV = process.env.REACT_APP_MODE;
    console.log("App environment is set to " + REACT_APP_ENV);
    API_URL = "https://takeheavy.ru/managers/";
    WEBSOCKET = "wss://takeheavy.ru/cable";
} else if (process.env.REACT_APP_MODE === "local") {
    REACT_APP_ENV = process.env.REACT_APP_MODE;
    console.log("App environment is set to " + REACT_APP_ENV);
    API_URL = "http://localhost:3000/managers/";
    WEBSOCKET = "ws://localhost:3000/cable";
} else {
    REACT_APP_ENV = "development";
    console.log("App environment is set to " + REACT_APP_ENV);
    API_URL = "https://takeheavy.ru/managers/";
    WEBSOCKET = "wss://takeheavy.ru/cable";
}

export { API_URL, REACT_APP_ENV, WEBSOCKET };
