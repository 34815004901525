import React from "react";
import { Card, Button, Form, DatePicker, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "constants/globals";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "actions/bills";

const Add = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const managers = useSelector((state) => state.users.activeManagers);
    const clients = useSelector((state) => state.users.data);
    const user = useSelector((state) => state.globals.current_user);

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(addItem(val));
    };

    return (
        <Card
            title="Создать счета"
            extra={
                <Link to="/sales/bills">
                    <Button type="primary">Назад</Button>
                </Link>
            }
        >
            <Form
                autoComplete="off"
                labelCol={{ span: 5 }}
                onFinish={onFinish}
                form={form}
                fields={[
                    {
                        name: ["user_id"],
                        value: user?.name
                    }
                ]}
            >
                <Form.Item
                    label="Дата счета"
                    name="date"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <DatePicker placeholder="Не указано" format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item
                    label="Номер"
                    name="uid"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="Не указано" maxLength={20} minLength={20} />
                </Form.Item>

                <Form.Item
                    label="Клиент"
                    name="user_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select
                        showSearch
                        placeholder="Выберите клиента"
                        allowClear
                        filterOption={(input, option) =>
                            (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())
                        }
                    >
                        {clients.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Описание" name="comment">
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Менеджер"
                    name="user_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())
                        }
                    >
                        {managers.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Создать
                </Button>
            </Form>
        </Card>
    );
};

export default Add;
