import React, { useState } from "react";
import { DATE_FORMAT } from "constants/globals";
import { Card, Table, Button } from "antd";
import dayjs from "dayjs";
import { fetchUserBankAccount } from "actions/users";
import { useDispatch, useSelector } from "react-redux";
import Add from "./add";
import Edit from "./edit";

const BankAccounts = (props) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItemModalOpen, setIsItemModalOpen] = useState(false);
    const bankAccounts = useSelector((state) => state.users.bankAccountsById);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showItemModal = (item) => {
        dispatch(fetchUserBankAccount(props.id, item.id));
        setIsItemModalOpen(true);
    };

    const columns = [
        {
            title: "Дата",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => {
                return <div>{dayjs(text).format(DATE_FORMAT)}</div>;
            }
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return <div>{text}</div>;
            }
        },
        {
            title: "Номер счёта",
            dataIndex: "number",
            key: "number",
            render: (text) => {
                return <div>{text}</div>;
            }
        }
    ];

    return (
        <Card
            title="Банковские счета"
            extra={
                <Button type="primary" onClick={showModal}>
                    Создать
                </Button>
            }
        >
            <Add setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={props.id} />
            <Edit setIsItemModalOpen={setIsItemModalOpen} isItemModalOpen={isItemModalOpen} id={props.id} />
            <Table
                className={"data-table data-table"}
                bordered
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={bankAccounts}
                locale={{ emptyText: "Нет данных" }}
                onRow={(item) => {
                    return {
                        onClick: () => {
                            showItemModal(item);
                        }
                    };
                }}
            />
        </Card>
    );
};

export default BankAccounts;
