import React, { useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { addOrderSale } from "actions/orders";

const Add = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [nomenclature, setNomenclature] = useState();

    const handleCancel = () => {
        props.setIsAddModalOpen(false);
    };

    const handleSelect = (values, option) => {
        setNomenclature(option.label);
    };

    const onFinish = (values) => {
        const val = { ...values };
        dispatch(addOrderSale(props.id, val));
        props.setIsAddModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Создать продажу"
            open={props.isAddModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Создать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 3 }}
                form={form}
                onFinish={onFinish}
                fields={[
                    {
                        name: ["name"],
                        value: nomenclature
                    }
                ]}
            >
                <Form.Item
                    label="Номенклатура"
                    name="nomenclature_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                            (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())
                        }
                        onSelect={handleSelect}
                        placeholder="Не указано"
                    >
                        {props.nomenclature.map((item) => (
                            <Select.Option
                                label={item.name + ", " + item.unit_name}
                                key={item.id}
                                value={item.id}
                            >
                                {item.name + ", " + item.unit_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Наименование"
                    name="name"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item
                    label="Ставка налога"
                    name="vat"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {props.taxes.map((item) => (
                            <Select.Option key={item.id} value={item.nds}>
                                {item.nds}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Количество"
                    name="quantity"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="" />
                </Form.Item>

                <Form.Item
                    label="Цена"
                    name="price"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Input type="text" placeholder="" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
