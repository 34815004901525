import React, { useEffect } from "react";
import { updateItem } from "actions/users";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form } from "antd";
import { useParams } from "react-router-dom";
import Loading from "utils/loading";
import Company from "./company";
import Individual from "./individual";

const Info = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const cities = useSelector((state) => state.users.cities);
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {
        form.setFieldsValue(props.user);
    });

    if (loading) {
        return <Loading />;
    }

    const onFinish = (values) => {
        const val = { ...values, kind: props.user.kind };
        dispatch(updateItem(id, val));
    };

    const formCheck = () => {
        switch (props.user.kind) {
            case "company":
                return <Company id={id} onFinish={onFinish} form={form} cities={cities} />;
            case "individual":
                return <Individual id={id} onFinish={onFinish} form={form} cities={cities} />;
        }
    };

    return <Card title="Информация">{formCheck()}</Card>;
};

export default Info;
