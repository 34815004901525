import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import { useEffect } from "react";
import { updateItem } from "actions/profile";

const Info = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector(state => state.globals.current_user);

    useEffect(() => {
        form.setFieldsValue(user);
    });

    const onFinish = (values) => {
        let val = { ...values }
        dispatch(updateItem(val));
    };

    return (
        <Form autoComplete="off" labelCol={{ span: 3 }} onFinish={onFinish} form={form}>
            <Form.Item
                label="Имя"
                name="first_name"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Фамилия"
                name="last_name"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Отчество"
                name="middle_name"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Телефон"
                name="phone"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Почта"
                name="email"
                rules={[{ required: true, message: "Заполните поле" }]}
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Form.Item
                label="Телеграм"
                name="tg_chat"
            >
                <Input type="text" placeholder="Не указано" />
            </Form.Item>

            <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", marginLeft: "auto" }}
            >
                Обновить
            </Button>
        </Form>
    );
};

export default Info;