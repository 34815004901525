import { combineReducers } from 'redux';
import {
    IMPLEMENTATIONS_DATA,
    IMPLEMENTATIONS_DATA_ARE_LOADING,
    IMPLEMENTATION_ADD,
    IMPLEMENTATION_BY_ID,
    STORED_IMPLEMENTATION_FORM_BY_ID,
    IMPLEMENTATION_BY_ID_LOADING,
    IMPLEMENTATION_BY_ID_NOT_FOUND,
    IMPLEMENTATION_ORDERS,
    IMPLEMENTATION_ORDERS_LOADING,
    IMPLEMENTATION_RELEVANT_ORDERS,
    IMPLEMENTATION_RELEVANT_ORDERS_LOADING,
    IMPLEMENTATION_BUTTON_DISPLAY_STATUS,
    IMPLEMENT_FILTER_DATA_CHANGE,
    IMPLEMENT_FILTER_DISPLAY_STATUS
} from "../constants/implementations";


export function implementationById(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_BY_ID:
            return action.item;
        default:
            return state;
    }
}

export function storedImplementationForm(state = [], action) {
    switch (action.type) {
        case STORED_IMPLEMENTATION_FORM_BY_ID:
            return action.sale;
        default:
            return state;
    }
}

export function implementationButtonDisplay(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_BUTTON_DISPLAY_STATUS:
            return action.bool;
        default:
            return state;
    }
}

export function implementationByIdAreLoading(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_BY_ID_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function implementationByIdError(state = false, action) {
    switch (action.type) {
        case IMPLEMENTATION_BY_ID_NOT_FOUND:
            return action.bool;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_ADD:
            return action.data;
        default:
            return state;
    }
}

export function implementationOrders(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_ORDERS:
            return action.data;
        default:
            return state;
    }
}

export function implementationOrdersAreLoading(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_ORDERS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function implementationRelevantOrders(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_RELEVANT_ORDERS:
            return action.data;
        default:
            return state;
    }
}

export function implementationRelevantOrdersAreLoading(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATION_RELEVANT_ORDERS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function data(state = [], action) {
    switch (action.type) {
        case IMPLEMENTATIONS_DATA:
            return action.data;
        default:
            return state;
    }
}

export function dataAreLoading(state = true, action) {
    switch (action.type) {
        case IMPLEMENTATIONS_DATA_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function filterData(state = [], action) {
  switch (action.type) {
    case IMPLEMENT_FILTER_DATA_CHANGE:
      return action.value;
    default:
      return state;
  }
}
export function filterDisplay(state = [], action) {
  switch (action.type) {
    case IMPLEMENT_FILTER_DISPLAY_STATUS:
      return !action.isShown;
    default:
      return state;
  }
}

export const implementationsReducers = () => combineReducers({
    add: add,
    item: implementationById,
    itemLoading: implementationByIdAreLoading,
    itemError: implementationByIdError,
    storedItemForm: storedImplementationForm,
    itemButtonDisplay: implementationButtonDisplay,
    orders: implementationOrders,
    ordersLoading: implementationOrdersAreLoading,
    relevantOrders: implementationRelevantOrders,
    relevantOrdersLoading: implementationRelevantOrdersAreLoading,
    data: data,
    dataAreLoading: dataAreLoading,
    filterDisplay: filterDisplay,
    filterData: filterData
});
