import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "utils/loading";

const ArchivedTable = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {}, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div>Подписки</div>
        </>
    );
};

export default ArchivedTable;
