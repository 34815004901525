import { combineReducers } from 'redux';
import {
    CASH_LIST,
    CASH_LIST_ARE_LOADING,
    CASH_BY_ID,
    CASH_BY_ID_LOADING,
    CASH_LAST_ADDED_ITEM,
    CASH_MANAGERS
} from "../constants/cash";


export function data(state = [], action) {
    switch (action.type) {
        case CASH_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function dataAreLoading(state = true, action) {
    switch (action.type) {
        case CASH_LIST_ARE_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function itemById(state = [], action) {
    switch (action.type) {
        case CASH_BY_ID:
            return action.payload;
        default:
            return state;
    }
}

export function itemByIdAreLoading(state = true, action) {
    switch (action.type) {
        case CASH_BY_ID_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function LastAddedItem(state = true, action) {
    switch (action.type) {
        case CASH_LAST_ADDED_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function CashManagers(state = [], action) {
    switch (action.type) {
        case CASH_MANAGERS:
            return action.payload;
        default:
            return state;
    }
}

export const cashReducers = () => combineReducers({
    data: data,
    dataAreLoading: dataAreLoading,
    item: itemById,
    itemLoading: itemByIdAreLoading,
    lastAddedItem: LastAddedItem,
    cashmanagers: CashManagers
});
