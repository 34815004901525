import React from "react";
import { Typography, Tabs } from "antd";
import Info from "./info";
import Mileages from "./mileages";
import Numbers from "./numbers";
import Services from "./services";
import Insurances from "./insurances";

const EquipmentsItem = () => {
    const items = [
        {
            key: "1",
            label: "Информация",
            children: <Info />
        },
        {
            key: "2",
            label: "Пробег",
            children: <Mileages />
        },
        {
            key: "3",
            label: "ТО",
            children: <Services />
        },
        {
            key: "4",
            label: "Госномера",
            children: <Numbers />
        },
        {
            key: "5",
            label: "Страховка",
            children: <Insurances />
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>Техника</Typography.Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    );
};

export default EquipmentsItem;
