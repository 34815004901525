import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table } from "antd";
import { fetchProfileSettingsManagerList } from "actions/notifications";

const ManagerNotificationsSettings = (props) => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.notifications.profileNotificationsManagerSettings);

    useEffect(() => {
        dispatch(fetchProfileSettingsManagerList());
    }, []);

    return (
        <>
            <Typography.Title level={3}>Менеджер</Typography.Title>
            <Table
                className={"data-table"}
                pagination={false}
                rowKey="id"
                columns={props.columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
            />
            <br />
            <br />
        </>
    );
};

export default ManagerNotificationsSettings;