export const IMPLEMENTATIONS_DATA = "IMPLEMENTATIONS_DATA";
export const IMPLEMENTATIONS_DATA_ARE_LOADING = "IMPLEMENTATIONS_DATA_ARE_LOADING";

export const IMPLEMENTATION_ADD = "IMPLEMENTATION_ADD";

export const IMPLEMENTATION_BY_ID = "IMPLEMENTATION_BY_ID";
export const STORED_IMPLEMENTATION_FORM_BY_ID = "STORED_IMPLEMENTATION_FORM_BY_ID";
export const IMPLEMENTATION_BY_ID_LOADING = "IMPLEMENTATION_BY_ID_LOADING";
export const IMPLEMENTATION_BY_ID_NOT_FOUND = "IMPLEMENTATION_BY_ID_NOT_FOUND";
export const IMPLEMENTATION_BUTTON_DISPLAY_STATUS = "IMPLEMENTATION_BUTTON_DISPLAY_STATUS";

export const IMPLEMENTATION_ORDERS = "IMPLEMENTATION_ORDERS";
export const IMPLEMENTATION_ORDERS_LOADING = "IMPLEMENTATION_ORDERS_LOADING";
export const IMPLEMENTATION_RELEVANT_ORDERS = "IMPLEMENTATION_RELEVANT_ORDERS";
export const IMPLEMENTATION_RELEVANT_ORDERS_LOADING = "IMPLEMENTATION_RELEVANT_ORDERS_LOADING";

export const IMPLEMENTATIONS_NAV = [
    {
        slug: "",
        name: "Информация",
    }, {
        slug: "orders",
        name: "Заказы",
    }
];

export const IMPLEMENT_FILTER_DATA_CHANGE = 'IMPLEMENT_FILTER_DATA_CHANGE';
export const IMPLEMENT_FILTER_DISPLAY_STATUS = 'IMPLEMENT_FILTER_DISPLAY_STATUS';
