import React from "react";
import Icon from "@ant-design/icons/lib/components/Icon";

const EmailSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M495.6,99.3L343.9,250l151.7,150.7c2.7-5.8,4.4-12.1,4.4-18.8V118.2C500,111.4,498.3,105.1,495.6,99.3z M456.1,74.2H43.9c-6.7,0-13.1,1.7-18.8,4.4l193.8,192.9c17.1,17.1,45,17.1,62.1,0L474.9,78.6C469.1,75.9,462.8,74.2,456.1,74.2z M4.4,99.3C1.7,105.1,0,111.4,0,118.2v263.7c0,6.7,1.7,13.1,4.4,18.8L156.1,250L4.4,99.3z M323.2,270.7l-21.5,21.5c-28.5,28.5-75,28.5-103.6,0l-21.5-21.5L25.1,421.4c5.8,2.7,12.1,4.4,18.8,4.4h412.1c6.7,0,13.1-1.7,18.8-4.4L323.2,270.7z" />
  </svg>
);

const FilterSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.4" height="10.496" viewBox="0 0 10.4 10.496">
    <path d="M8.359,4.959A.648.648,0,0,1,8.53,5.4V10.17a.324.324,0,0,0,.552.231l1.331-1.526c.178-.214.276-.32.276-.531V5.4a.653.653,0,0,1,.171-.438L14.68.815A.486.486,0,0,0,14.323,0H4.9a.486.486,0,0,0-.357.815Z" transform="translate(-4.41)" />
  </svg>
);

const SearchSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <path d="M11.908,4.077a5.536,5.536,0,1,0,0,7.831A5.538,5.538,0,0,0,11.908,4.077Zm-.87,6.961a4.307,4.307,0,1,1,0-6.091A4.308,4.308,0,0,1,11.038,11.038Zm9.508,6.9-3.54-3.54a1.688,1.688,0,0,0-1.985-.242l-.952-.96a7.982,7.982,0,1,0-.87.87l.952.952A1.7,1.7,0,0,0,14.394,17l3.54,3.54a1.764,1.764,0,0,0,2.486-.123,1.939,1.939,0,0,0,.574-1.222A1.576,1.576,0,0,0,20.547,17.938Zm-7.769-5.16a6.767,6.767,0,1,1,0-9.57A6.776,6.776,0,0,1,12.778,12.778ZM19.55,19.55a.557.557,0,0,1-.747.123l-3.54-3.54a.561.561,0,0,1,.123-.747h0a.548.548,0,0,1,.747-.123l3.54,3.54A.552.552,0,0,1,19.55,19.55Z" />
  </svg>
);

const SwitcherCrossSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.557" viewBox="0 0 15.557 15.557">
    <path d="M7.778,9.192,1.414,15.557,0,14.142,6.364,7.778,0,1.414,1.414,0,7.778,6.364,14.142,0l1.415,1.414L9.192,7.778l6.364,6.364-1.415,1.415Z" />
  </svg>
);

const SwitcherCheckSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11.035" viewBox="0 0 16 11.035">
    <path d="M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z" />
  </svg>
);

const FilterCrossSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <path d="M250.5,295.9L46,500.5L0.5,455l204.6-204.5L0.5,46L46,0.5l204.6,204.6L455,0.5L500.5,46L295.9,250.5L500.5,455L455,500.5L250.5,295.9z" />
  </svg>
);

const SmallPlusSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <polygon points="500.5,206.7 294.3,206.7 294.3,0.5 206.8,0.5 206.8,206.7 206.7,206.7 206.7,206.8 0.5,206.80.5,294.3 206.7,294.3 206.7,500.5 294.2,500.5 294.2,294.3 294.3,294.3 294.3,294.2 500.5,294.2 " />
  </svg>
);

const CalendarSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <path xmlns="http://www.w3.org/2000/svg" d="M80.5,309.7h60c8.3,0,15-6.7,15-15v-60c0-8.3-6.8-15-15-15h-60c-8.3,0-15,6.7-15,15v60C65.5,303,72.2,309.7,80.5,309.7z   M95.5,249.7h30v30h-30V249.7z M220.5,309.7h60c8.3,0,15-6.7,15-15v-60c0-8.3-6.8-15-15-15h-60c-8.3,0-15,6.7-15,15v60  C205.5,303,212.2,309.7,220.5,309.7z M235.5,249.7h30v30h-30V249.7z M360.5,309.7h60c8.3,0,15-6.7,15-15v-60c0-8.3-6.7-15-15-15h-60  c-8.3,0-15,6.7-15,15v60C345.5,303,352.2,309.7,360.5,309.7z M375.5,249.7h30v30h-30V249.7z M80.5,429.7h60c8.3,0,15-6.7,15-15v-60  c0-8.3-6.8-15-15-15h-60c-8.3,0-15,6.7-15,15v60C65.5,423,72.2,429.7,80.5,429.7z M95.5,369.7h30v30h-30V369.7z M220.5,429.7h60  c8.3,0,15-6.7,15-15v-60c0-8.3-6.8-15-15-15h-60c-8.3,0-15,6.7-15,15v60C205.5,423,212.2,429.7,220.5,429.7z M235.5,369.7h30v30h-30  V369.7z M360.5,429.7h60c8.3,0,15-6.7,15-15v-60c0-8.3-6.7-15-15-15h-60c-8.3,0-15,6.7-15,15v60C345.5,423,352.2,429.7,360.5,429.7z   M375.5,369.7h30v30h-30V369.7z M485.9,46.4h-91.6V15.1c0-8.1-6.5-14.6-14.6-14.6C371.5,0.5,365,7,365,15.1v31.2h-56.7V15.1  c0-8.1-6.5-14.6-14.6-14.6c-8.1,0-14.6,6.5-14.6,14.6v31.2h-56.8V15.1c0-8.1-6.5-14.6-14.6-14.6c-8.1,0-14.6,6.5-14.6,14.6v31.2H136  V15.1c0-8.1-6.5-14.6-14.6-14.6S106.8,7,106.8,15.1v31.2H15.1C7,46.4,0.5,52.9,0.5,61v424.8c0,8.1,6.5,14.6,14.6,14.6h470.7  c8.1,0,14.6-6.5,14.6-14.6V61C500.5,52.9,494,46.4,485.9,46.4z M471.2,471.2H29.8V174.6h441.4V471.2z M471.2,145.3H29.8V75.7h77V103  c0,8.1,6.5,14.6,14.6,14.6S136,111.1,136,103V75.7h56.7V103c0,8.1,6.5,14.6,14.6,14.6c8.1,0,14.6-6.5,14.6-14.6V75.7h56.8V103  c0,8.1,6.5,14.6,14.6,14.6c8.1,0,14.6-6.5,14.6-14.6V75.7H365V103c0,8.1,6.5,14.6,14.6,14.6c8.1,0,14.6-6.5,14.6-14.6V75.7h77V145.3  z" />
  </svg>
);

const NotificationSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <path xmlns="http://www.w3.org/2000/svg" d="M397.4,276.6V200c0-59.7-35.7-113.1-91-135.9c-8.9-3.7-15.3-12.1-15.3-23.1c0-35.4-43-54.3-68.8-29  c-21.2,20.8-3.1,42-27.7,52.1c-55.3,22.8-91,76-91,135.9v76.6H397.4z M446.7,363.2l-39-57.2H93.2l-38.9,57.2  c-9.9,14.6-9.4,33.8,1.3,47.8c7.7,10,20,16,32.9,16h323.9c13,0,25.3-6,33-16C456.1,397,456.7,377.8,446.7,363.2z M174.7,456.3  c15.1,26.4,43.4,44.2,75.8,44.2c32.3,0,60.6-17.8,75.8-44.2H174.7z" />
  </svg>
);

const UserSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <path xmlns="http://www.w3.org/2000/svg" d="M250,0C111.8,0,0,111.8,0,250s111.8,250,250,250s250-111.8,250-250S387.1,0,250,0z M250,74.6c46.1,0,82.2,37.3,82.2,82.2  c0,46.1-37.3,82.2-82.2,82.2c-46.1,0-82.2-37.3-82.2-82.2C166.7,111.8,203.9,74.6,250,74.6z M250,434.2c-45,0-86.6-16.4-119.5-43.9  c-7.7-6.6-12.1-16.4-12.1-26.3c0-46.1,37.3-83.3,83.3-83.3h96.5c46.1,0,83.3,37.3,83.3,83.3c0,9.9-4.4,19.7-12.1,26.3  C336.6,417.8,295,434.2,250,434.2z" />
  </svg>
);

const ArchiveSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M483.4,217.1H322.9l-28.7,28.7c-1.7,1.7-3.6,3.1-5.5,4.5h178v110.5l-17.2-60.6c-2-7.1-8.5-12-16-12H82.9v-92.2h95l-27-27c-1.9-1.9-3.6-4-5.2-6.2H66.3c-9.2,0-16.6,7.4-16.6,16.6v108.8H16.6c-5.2,0-10.1,2.4-13.2,6.6c-3.1,4.2-4.1,9.5-2.7,14.5l47.8,166.6c3.5,12.2,14.6,20.6,27.3,20.6h395.9c15.7,0,28.4-12.7,28.4-28.4V233.6C500,224.5,492.6,217.1,483.4,217.1z M251.3,222.3c2.7,2.7,6.2,4,9.7,4c3.5,0,7.1-1.4,9.7-4l77-77c4-3.9,5.1-9.9,3-15c-2.1-5.1-7.2-8.5-12.7-8.5h-33.7V22c0-10.1-8.2-18.3-18.3-18.3h-50c-10.1,0-18.3,8.2-18.3,18.3v99.8H184c-5.6,0-10.6,3.4-12.7,8.5c-2.1,5.1-1,11.1,3,15L251.3,222.3z" />
  </svg>
);

const UnarchiveSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
    <path d="M184.7,163.2H66.9c-9.1,0-16.5,7.4-16.5,16.5v108.8H17.1c-5.2,0-10.1,2.5-13.3,6.6s-4.1,9.6-2.7,14.5l47.8,166.7c3.5,12.1,14.6,20.5,27.2,20.5h396c15.7,0,28.4-12.7,28.4-28.4V234.2c0-9.1-7.4-16.5-16.5-16.5H337.5c-2.4,13.7-10.2,25.4-21,33.2h150.7v110.5l-17.3-60.6c-2.1-7.1-8.5-12-15.9-12H83.5v-92.3h101.3v-33.2H184.7z M271.3,8.2c-2.7-2.7-6.3-4-9.8-4c-3.5,0-7.1,1.3-9.8,4l-77.1,77c-3.9,3.9-5.1,9.9-3,15s7.2,8.5,12.7,8.5h33.7v99.9c0,10.2,8.2,18.4,18.4,18.4h50c10.2,0,18.3-8.2,18.3-18.4v-99.9h33.7c5.5,0,10.6-3.4,12.7-8.5s1-11.1-3-15L271.3,8.2z" />
  </svg>
);

const FolderSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M483.5,137.6H278c-2,0-3.8-0.6-5.3-1.8L207.2,85c-1.5-1.2-3.4-1.8-5.3-1.8H66.4c-9.1,0-16.5,7.4-16.5,16.5v108.8H16.6c-5.2,0-10.1,2.5-13.3,6.6s-4.1,9.6-2.7,14.5l47.8,166.7c3.5,12.1,14.6,20.5,27.2,20.5h396c15.7,0,28.4-12.7,28.4-28.4V154.2C500,145.1,492.6,137.6,483.5,137.6z M466.8,281.4l-17.3-60.6c-2.1-7.1-8.5-12-15.9-12H83v-92.3h108.6c2,0,3.8,0.6,5.3,1.8l65.4,50.8c1.5,1.2,3.4,1.8,5.3,1.8h199.1V281.4z" />
  </svg>
);

const LinkSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M305.6,195.4c-6-6-12.5-11.2-19.6-15.7c-18.7-12-40.6-18.5-62.9-18.5c-31-0.1-60.6,12.2-82.5,34.2l-106,106c-21.8,21.9-34,51.5-34.1,82.3c0,64.4,52.1,116.7,116.6,116.7c30.9,0.1,60.5-12.1,82.4-34L287,379c1.6-1.6,2.4-3.7,2.4-6c0-4.6-3.8-8.3-8.4-8.3h-3.2c-18.3,0.1-36.4-3.4-53.3-10.4c-3.1-1.3-6.7-0.6-9.1,1.9l-62.9,63c-19.5,19.5-51.2,19.5-70.7,0s-19.5-51.2,0-70.7l106.4-106.3c19.5-19.5,51.2-19.5,70.7,0c13.2,12.4,33.7,12.4,46.9,0c5.7-5.7,9.1-13.2,9.7-21.2C315.9,211.6,312.4,202.2,305.6,195.4z M466.4,34.7c-45.6-45.6-119.4-45.6-165,0L214,122c-2.4,2.4-3.1,6.1-1.8,9.2s4.4,5.2,7.8,5.1h3.1c18.3,0,36.3,3.5,53.2,10.4c3.1,1.3,6.7,0.6,9.1-1.9l62.8-62.7c19.5-19.5,51.2-19.5,70.7,0s19.5,51.2,0,70.7L340.8,231l-0.7,0.8l-27.3,27.1c-19.5,19.5-51.2,19.5-70.7,0c-13.2-12.4-33.7-12.4-46.9,0c-5.7,5.7-9.2,13.3-9.7,21.3c-0.6,9.6,2.9,18.8,9.7,25.7c9.7,9.7,20.9,17.6,33.3,23.3c1.8,0.9,3.5,1.5,5.3,2.2c1.8,0.8,3.6,1.4,5.4,2c1.8,0.7,3.6,1.3,5.4,1.8l4.9,1.4c3.3,0.9,6.6,1.5,10.1,2.1c4.1,0.6,8.3,1,12.4,1.2h5.9h0.5l5-0.6c1.9-0.1,3.7-0.5,6-0.5h2.8l5.8-0.9l2.6-0.5l4.8-1h0.9c20.5-5.2,39.2-15.7,54.1-30.7l106.2-105.9C511.9,154.1,511.9,80.2,466.4,34.7z" />
  </svg>
);

const TrashSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M425.8,58.3h-104c-2.8-14.1-9.8-27.3-20-37.3c-27.2-28-76.7-27.8-103.4-0.3c-10.4,10.2-17.4,23.5-20.3,37.6h-104c-24.2,0-43.9,19.7-43.9,43.9v30.3c0,8.1,6.5,14.6,14.6,14.6h410.5c8.1,0,14.6-6.5,14.6-14.6v-30.3C469.9,78,450.2,58.3,425.8,58.3zM208.4,58.3c2.1-6.2,5.8-12.1,10.6-16.9c16.1-16.6,45.2-16.9,61.9,0.3c4.7,4.6,8.2,10.5,10.4,16.6C291.5,58.3,208.4,58.3,208.4,58.3z M63,176.6l23.4,283.2c2.1,22.9,21.1,40.2,43.9,40.2h239.3c22.9,0,41.7-17.3,43.9-40.4l23.4-283L63,176.6L63,176.6z M176.6,396.4c0,8.1-6.5,14.6-14.6,14.6c-8.1,0-14.6-6.5-14.6-14.6V220.5c0-8.1,6.5-14.6,14.6-14.6c8.1,0,14.6,6.5,14.6,14.6V396.4z M264.6,396.4c0,8.1-6.5,14.6-14.6,14.6c-8.1,0-14.6-6.5-14.6-14.6V220.5c0-8.1,6.5-14.6,14.6-14.6c8.1,0,14.6,6.5,14.6,14.6V396.4z M352.6,396.4c0,8.1-6.5,14.6-14.6,14.6s-14.6-6.5-14.6-14.6V220.5c0-8.1,6.5-14.6,14.6-14.6s14.6,6.5,14.6,14.6V396.4z" />
  </svg>
);

const InformationSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M147.1,411.7V500h205.8l0,0l0,0v-88.3h-44.1V235.2v-88.1H147.1v88.1H191v176.5H147.1z M308.7,58.8c0-32.5-26.4-58.8-58.8-58.8S191,26.4,191,58.8s26.4,58.8,58.8,58.8S308.7,91.3,308.7,58.8z" />
  </svg>
);

const PrinterSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path d="M129.1,422.1l-3.7,38.9c-1.9,21,13.5,38.1,34.5,38.1h180.1c21,0,37-17,35.4-38.1l-2.8-38.9H129.1L129.1,422.1z M500,424.6V209.8c0-21-17-38.2-38.2-38.2h-66.3v101.7H104.3V171.5H38.2c-21,0-38.2,17-38.2,38.2v215c0,21,17,38.2,38.2,38.2h66.3v-65.9h291.3V463h66.3C483,462.8,500,445.6,500,424.6z M448.3,242.9c-9.8,0-17.9-8.1-17.9-17.9c0-9.8,8.1-17.9,17.9-17.9c9.8,0,17.9,8.1,17.9,17.9C466.2,235,458.3,242.9,448.3,242.9z M369.3,74.5v-8.8L302.2,0.9h-9.6l0,0l0,0H167.7c-20,0.2-36.1,16.5-36.1,36.3v211.3h238V75.2C369.3,75.1,369.3,74.9,369.3,74.5z M333.7,210.3c0,0.4-0.2,0.5-0.5,0.5H167.7c-0.4,0-0.7-0.2-0.7-0.5V37.2c0-0.4,0.4-0.5,0.7-0.5h104.7v21.7c0,27.2,23.3,37.2,39.1,37.2h22.3C333.7,95.6,333.7,210.3,333.7,210.3z" />
  </svg>
);

const ArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path className="st0" d="M0.5,296h318.2v90.9l181.8-136.4L318.7,114.1V205H0.5V296z" />
  </svg>
);

const RelevantReports = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500">
    <path d="M56,102.8l98.4-98.4c2.7-2.7,6-3.9,9.2-3.9c6.7,0,13.2,5.2,13.2,13.1V99c0,0,0,0.1,0,0.2c-0.1,14.2-11.7,25.8-25.9,25.9
  	c0,0-0.1,0-0.2,0H65.4C53.7,125.1,47.9,111,56,102.8z M448.1,31.1v437.8c0,17.1-13.9,31.1-31.1,31.1H83c-17.1,0-31.1-13.9-31.1-31.1
  	V142.7l98.9,0.1c24.2,0,43.9-19.7,43.9-43.9L194.5,0H417C434.1,0,448.1,14,448.1,31.1z M358.6,284.4c-0.4-7.9-7.2-14-15.2-14h-78.7
  	v-79.2c0-8.3-7-15.1-15.4-14.7c-7.9,0.4-14,7.2-14,15.2v78.7h-79.2c-8.3,0-15.1,7-14.7,15.4c0.4,7.9,7.2,14,15.2,14h78.7V379
  	c0,8.3,7,15.1,15.4,14.7c7.9-0.4,14-7.2,14-15.2v-78.7h79.2C352.2,299.8,359,292.8,358.6,284.4z"/>
  </svg>
)

export const FastAddUserIcon = () => (
  <svg
    viewBox="64 64 896 896" focusable="false" data-icon="user-add" width="1.5em" height="1.5em" fill="currentColor" aria-hidden="true">
    <path d="M678.3 642.4c24.2-13 51.9-20.4 81.4-20.4h.1c3 0 4.4-3.6 2.2-5.6a371.67 371.67 0 0 0-103.7-65.8c-.4-.2-.8-.3-1.2-.5C719.2 505 759.6 431.7 759.6 349c0-137-110.8-248-247.5-248S264.7 212 264.7 349c0 82.7 40.4 156 102.6 201.1-.4.2-.8.3-1.2.5-44.7 18.9-84.8 46-119.3 80.6a373.42 373.42 0 0 0-80.4 119.5A373.6 373.6 0 0 0 137 888.8a8 8 0 0 0 8 8.2h59.9c4.3 0 7.9-3.5 8-7.8 2-77.2 32.9-149.5 87.6-204.3C357 628.2 432.2 597 512.2 597c56.7 0 111.1 15.7 158 45.1a8.1 8.1 0 0 0 8.1.3zM512.2 521c-45.8 0-88.9-17.9-121.4-50.4A171.2 171.2 0 0 1 340.5 349c0-45.9 17.9-89.1 50.3-121.6S466.3 177 512.2 177s88.9 17.9 121.4 50.4A171.2 171.2 0 0 1 683.9 349c0 45.9-17.9 89.1-50.3 121.6C601.1 503.1 558 521 512.2 521zM880 759h-84v-84c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v84h-84c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h84v84c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-84h84c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path>
  </svg>
)

export const EmailIcon = props => <Icon component={EmailSvg} {...props} />;

export const FilterIcon = props => <Icon component={FilterSvg} {...props} />;

export const SmallCrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="white">
    <path d="M5,5.909.909,10,0,9.091,4.091,5,0,.909.909,0,5,4.091,9.091,0,10,.909,5.909,5,10,9.091,9.091,10Z" />
  </svg>
);

export const SearchIcon = props => <Icon component={SearchSvg} {...props} />;

export const SwitcherCrossIcon = props => <Icon component={SwitcherCrossSvg} {...props} />;

export const SwitcherCheckIcon = props => <Icon component={SwitcherCheckSvg} {...props} />;

export const FilterCrossIcon = props => <Icon component={FilterCrossSvg} {...props} />;

export const SmallPlusIcon = props => <Icon component={SmallPlusSvg} {...props} />;

export const CalendarIcon = props => <Icon component={CalendarSvg} {...props} />;

export const UserIcon = props => <Icon component={UserSvg} {...props} />;

export const DocumentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="1.5em" height="1.5em" fill="currentColor">
    < path d="M250,500h167c17.2,0,31.1-14,31.1-31.1v-405V31.1C448.1,14,434.1,0,417,0H250h-55.5v10.3c0.1,1.1,0.2,2.2,0.2,3.3v67.9v17.4c0,24.2-19.7,43.9-43.9,43.9h-17.4h-68c-0.9,0-1.8-0.1-2.7-0.1H51.9v326.2c0,17.2,14,31.1,31.1,31.1h34.1L250,500L250,500zM256.3,394.1H147.4c-8.1,0-14.7-6.6-14.7-14.7s6.6-14.7,14.7-14.7h108.9c8.1,0,14.7,6.6,14.7,14.7S264.4,394.1,256.3,394.1zM365.2,319H147.4c-8.1,0-14.7-6.6-14.7-14.7s6.6-14.7,14.7-14.7h217.8c8.1,0,14.7,6.6,14.7,14.7S373.3,319,365.2,319z M147.4,209.8h217.8c8.1,0,14.7,6.6,14.7,14.7c0,8.1-6.6,14.7-14.7,14.7H147.4c-8.1,0-14.7-6.6-14.7-14.7C132.7,216.4,139.3,209.8,147.4,209.8zM65.4,125.1h85.3c0.1,0,0.2,0,0.2,0c14.2-0.1,25.8-11.7,25.9-25.9c0-0.1,0-0.2,0-0.2V13.6c0-7.9-6.5-13.1-13.2-13.1c-3.2,0-6.5,1.2-9.2,3.9L56,102.8C47.9,111,53.7,125.1,65.4,125.1z" />
  </svg >
)

export const ArchiveIcon = props => <Icon component={ArchiveSvg} {...props} />;

export const UnarchiveIcon = props => <Icon component={UnarchiveSvg} {...props} />;

export const FolderIcon = props => <Icon component={FolderSvg} {...props} />;

export const LinkIcon = props => <Icon component={LinkSvg} {...props} />;

export const TrashIcon = props => <Icon component={TrashSvg} {...props} />;

export const InformationIcon = props => <Icon component={InformationSvg} {...props} />;

export const PrinterIcon = props => <Icon component={PrinterSvg} {...props} />;

export const ArrowIcon = props => <Icon component={ArrowSvg} {...props} />;

export const Documents = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="1.5em" height="1.5em" fill="#e3e3e3" >
    <path d="M335.1,500c17.2,0,31.1-14,31.1-31.1V31.1c0-17.1-14-31.1-31.1-31.1H142.6c0,0,0,0,0,0l0.2,98.9c0,24.2-19.7,43.9-43.9,43.9
  L0,142.7c0,0,0,0,0,0v326.2C0,486.1,14,500,31.1,500H335.1z M174.4,394.1l-78.4,0c-7.9,0-14.8-6.1-15.2-14
  c-0.4-8.4,6.3-15.4,14.7-15.4h78.4c7.9,0,14.8,6.1,15.2,14C189.5,387.1,182.7,394.1,174.4,394.1z M283.3,319H96
  c-7.9,0-14.8-6.1-15.2-14c-0.4-8.4,6.3-15.4,14.7-15.4l187.3,0c7.9,0,14.8,6.1,15.2,14C298.4,312,291.6,319,283.3,319z M95.5,209.8
  h187.3c7.9,0,14.8,6.1,15.2,14c0.4,8.4-6.3,15.4-14.7,15.4H96c-7.9,0-14.8-6.1-15.2-14C80.4,216.8,87.2,209.8,95.5,209.8z
   M13.5,125.1h85.3c0.1,0,0.2,0,0.2,0c14.2-0.1,25.8-11.7,25.9-25.9c0-0.1,0-0.2,0-0.2V13.6c0-7.9-6.5-13.1-13.2-13.1
  c-3.2,0-6.5,1.2-9.2,3.9L4.1,102.8C-4,111,1.8,125.1,13.5,125.1z M500,31.1v437.8c0,17.1-13.9,31.1-31.1,31.1h-81.2
  c0,0,28.5,0,28.5-51.1V51.1C416.2,0.5,387.7,0,387.7,0h81.2C486,0,500,14,500,31.1z"/>
  </svg >
)

export const RelevantDocuments = props => <Icon component={RelevantReports} {...props} />;
