import React, { useEffect, useState } from "react";
import { Typography, Table, Space, Col, Button } from "antd";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import Filter from "./filter";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loading from "utils/loading";
import { CurrencyFormat } from "utils/currency";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { fetchList } from "actions/sales";
import { clearFilterList } from "actions/sales";

const List = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const list = useSelector((state) => state.sales.list);
    const loading = useSelector((state) => state.globals.loadingList);
    const filterValues = useSelector((state) => state.sales.filterValues);

    useEffect(() => {
        dispatch(fetchList(filterValues));
    }, [filterValues]);

    if (loading) {
        return <Loading />;
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Дата",
            dataIndex: "date",
            key: "date",
            render: (text) => {
                return dayjs(text).format(DATE_AND_TIME_FORMAT);
            }
        },
        {
            title: "Заказ",
            dataIndex: "order_id",
            key: "order_id",
            render: (text) => {
                return text ? text : "-";
            }
        },
        {
            title: "Контрагент",
            dataIndex: "contractor",
            key: "contractor",
            render: (text) => {
                return text.name;
            }
        },
        {
            title: "Сумма",
            dataIndex: "amount",
            key: "amount",
            render: (text) => {
                return <CurrencyFormat value={text} />;
            }
        },
        {
            title: "НДС",
            dataIndex: "vat",
            key: "vat",
            render: (text) => {
                return <CurrencyFormat value={text} />;
            }
        }
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <div>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Отгрузки</Typography.Title>
                </Col>

                <Col>
                    {filterValues ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                dispatch(clearFilterList());
                            }}
                        >
                            <CloseOutlined />
                        </Button>
                    ) : undefined}
                    <Button type="primary" onClick={showModal} style={{ marginRight: 5, marginLeft: 5 }}>
                        Фильтры
                    </Button>
                    <Link to="/sales/shipments/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <Filter isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <br />
            <br />
            <Table
                className={"data-table"}
                bordered
                rowKey="id"
                columns={columns}
                dataSource={list}
                pagination={false}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
