export function success(message) {
    return {
        type: "SUCCESS_MESSAGE",
        message
    };
}

export function error(message) {
    return {
        type: "ERROR_MESSAGE",
        message
    };
}
