import axios from "axios";
import { history } from "store";
import * as messages from "./handle_messages";
import { API_URL } from "config/conf";

export function fetchList() {
    return (dispatch) => {
        axios.get(`${API_URL}dict/units`).then((response) => {
            dispatch({ type: "UNITS_LIST", payload: response.data });
        });
    };
}

export function fetchItem(id) {
    return (dispatch) => {
        axios.get(`${API_URL}dict/units/${id}`).then((response) => {
            dispatch({ type: "UNITS_ITEM", payload: response.data });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}dict/units`, item).then((response) => {
            dispatch(fetchList());
            dispatch({ type: "UNITS_ITEM", payload: response.data });
            dispatch(messages.success("Единица измерения создана"));
            history.push("/dict/units");
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}dict/units/${item.id}`, item).then((response) => {
            dispatch(fetchList());
            dispatch({ type: "UNITS_ITEM", payload: response.data });
            dispatch(messages.success("Единица измерения обновлена"));
            history.push("/dict/units");
        });
    };
}
