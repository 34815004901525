import React from "react";
import { Button, Form, Input, DatePicker, Select, Card } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DATE_AND_TIME_FORMAT } from "constants/globals";
import { addItem } from "actions/accruals";
import { useSelector } from "react-redux";

const Add = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const performers = useSelector((state) => state.users.activePerformers);
    const managers = useSelector((state) => state.users.managers);
    const user = useSelector((state) => state.globals.current_user);

    const onFinish = (values) => {
        const val = { ...values };
        dispatch(addItem(val));
    };

    return (
        <Card
            title="Создание начисления"
            extra={<Link to="/accruals"><Button type='primary'>Назад</Button></Link>}
        >
            <Form
                autoComplete="off"
                colon={false}
                labelCol={{ span: 4 }}
                form={form}
                onFinish={onFinish}
                fields={[
                    {
                        name: ["responsible_id"],
                        value: user?.id
                    }
                ]}
            >
                <Form.Item label="Дата" name="date" rules={[{ required: true, message: "Заполните поле" }]}>
                    <DatePicker showTime placeholder="Не указано" format={DATE_AND_TIME_FORMAT} />
                </Form.Item>

                <Form.Item label="Водитель" name="user_id" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {performers.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Сумма" name="amount" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input type="text" placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Описание" name="comment" rules={[{ required: true, message: "Заполните описание" }]}>
                    <Input.TextArea placeholder="Не указано" />
                </Form.Item>

                <Form.Item label="Менеджер" name="responsible_id" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Select
                        placeholder="Не указано"
                        showSearch
                        filterOption={(input, option) => (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())}
                    >
                        {managers.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
            </Form>
        </Card>
    );
};

export default Add;
