import React, { useEffect } from "react";
import { Spin } from "antd";
import { preloading } from "actions/globals";
import { useDispatch } from "react-redux";

const Preloader = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(preloading());
    });

    return (
        <div style={{ padding: "25% 50%", width: "100%", height: "100vh" }}>
            <Spin size="large" />
        </div>
    );
};

export default Preloader;
