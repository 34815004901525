import React from "react";
import { useSelector } from "react-redux";
import { Typography, Table } from "antd";
import { useNavigate } from "react-router-dom";

const List = () => {
    const navigate = useNavigate();
    const list = useSelector((state) => state.users.performers);

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return <div title={text}>{text}</div>;
            }
        },
        {
            title: "Электронная почта",
            dataIndex: "email",
            key: "email",
            render: (text) => {
                return <div title={text}>{text ? text : "-"}</div>;
            }
        },
        {
            title: "Телефон",
            dataIndex: "phone",
            key: "phone",
            render: (text) => {
                return <div title={text}>{text ? text : "-"}</div>;
            }
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>Водители</Typography.Title>
            <Table
                className={"data-table"}
                bordered
                rowKey="id"
                columns={columns}
                dataSource={list}
                pagination={false}
                locale={{ emptyText: "Нет данных" }}
                rowClassName={(record) => (record.active_performer ? "archive" : "active")}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/performers/${id}`);
                    }
                })}
            />
        </div>
    );
};

export default List;
