import axios from "axios";
import { history } from "store";
import * as messages from "./handle_messages";
import { API_URL } from "config/conf";

export function fetchRecentList(item) {
    return (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        axios.get(`${API_URL}offers`, { params: { ...item, status: "recent" } }).then((response) => {
            dispatch({ type: "RECENT_LIST", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function fetchApprovedList(item) {
    return (dispatch) => {
        axios.get(`${API_URL}offers`, { params: { ...item, status: "approved" } }).then((response) => {
            dispatch({ type: "APPROVED_LIST", payload: response.data });
        });
    };
}

export function fetchCanceledList(item) {
    return (dispatch) => {
        axios.get(`${API_URL}offers`, { params: { ...item, status: "canceled" } }).then((response) => {
            dispatch({ type: "CANCELED_LIST", payload: response.data });
        });
    };
}

export function setFilterValues(item) {
    return (dispatch) => {
        dispatch({ type: "OFFERS_FILTER_VALUES", payload: item });
    };
}

export function clearFilterList() {
    return (dispatch) => {
        dispatch({ type: "OFFERS_FILTER_VALUES", payload: null });
    };
}

export function fetchItem(id) {
    return async (dispatch) => {
        dispatch({ type: "LOADING_LIST", payload: true });
        await axios.get(`${API_URL}offers/${id}`).then((response) => {
            dispatch({ type: "OFFERS_ITEM", payload: response.data });
            dispatch({ type: "LOADING_LIST", payload: false });
        });
    };
}

export function addItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}offers`, item).then(() => {
            dispatch(messages.success("Заявка добавлен"));
            history.push("/offers");
        });
    };
}

export function updateItem(item) {
    return (dispatch) => {
        axios.patch(`${API_URL}offers/${item.id}`, item).then(() => {
            dispatch(messages.success("Заявка обновлена"));
            history.push("/offers");
        });
    };
}

export function recentCounts() {
    return (dispatch) => {
        axios.get(`${API_URL}offers/counts`).then((response) => {
            dispatch({ type: "OFFERS_RECENT_COUNT", payload: response.data });
        });
    };
}
