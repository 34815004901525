import React, { useEffect } from "react";
import { Typography, Table, Space, Col, Button } from "antd";
import Loading from "utils/loading";
import { fetchList } from "actions/equipments";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

const List = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const list = useSelector((state) => state.equipments.list);
    const loading = useSelector((state) => state.globals.loadingList);

    useEffect(() => {
        dispatch(fetchList());
    }, []);

    if (loading) {
        return <Loading />;
    }

    const columns = [
        {
            title: "Госномер",
            dataIndex: "last_number",
            key: "last_number",
            render: (text) => {
                return text?.number;
            }
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return text;
            }
        },
        {
            title: "Моточасы",
            dataIndex: "last_mileage",
            key: "hours",
            render: (text, record) => {
                return record.last_mileage?.hours;
            }
        },
        {
            title: "Пробег",
            dataIndex: "last_mileage",
            key: "mileage",
            render: (text, record) => {
                return record.last_mileage?.mileage;
            }
        },
        {
            title: "Город",
            dataIndex: "city",
            key: "city",
            render: (text) => {
                return text?.name;
            }
        }
    ];

    return (
        <>
            <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                <Col style={{ height: "40px" }}>
                    <Typography.Title level={2}>Техника</Typography.Title>{" "}
                </Col>

                <Col>
                    <Link to="/equipments/add">
                        <Button type="primary">Создать</Button>
                    </Link>
                </Col>
            </Space>
            <br />
            <br />
            <Table
                className={"data-table"}
                pagination={false}
                bordered
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowClassName={(record) => (record.archived ? "archive" : "active")}
                locale={{ emptyText: "Нет данных" }}
                onRow={({ id }) => ({
                    onClick: () => {
                        navigate(`/equipments/${id}`);
                    }
                })}
            />
        </>
    );
};

export default List;
