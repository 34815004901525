import React from "react";
import { Button, Layout, Space, Popover, Badge } from "antd";
import Monthpicker from "./monthpicker";
import Profilemenu from "./profilemenu";
import User from "./user";
import { useDispatch, useSelector } from "react-redux";
import { preloading } from "actions/globals";
import { ReloadOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Header = () => {
    const updateButton = useSelector((state) => state.globals.updateButton);
    const user = useSelector((state) => state.globals.current_user);
    const count = useSelector((state) => state.notifications.count);
    const dispatch = useDispatch();

    const updateData = () => {
        dispatch(preloading());
    };

    return (
        <Layout.Header style={{ backgroundColor: "#fff", display: "flex", alignItems: "center" }}>
            <Space size="large">
                <Monthpicker />
                <Profilemenu />
                <Badge count={count} size={"small"}>
                    <Link to="/profile" style={{ color: "rgba(0, 0, 0, 0.88)" }}>
                        Уведомления
                    </Link>
                </Badge>
                <User />
            </Space>
            {!user?.tg_chat && (
                <Popover content={"Добавьте в профиле номер телеграм чата"}>
                    <BellOutlined style={{ marginLeft: "auto", marginRight: "20px", fontSize: "23px", cursor: "pointer", color: "#f50" }} />
                </Popover>
            )}
            <Button
                type="primary"
                onClick={updateData}
                disabled={!updateButton}
                style={{ marginLeft: `${user?.tg_chat ? "auto" : "unset"}`, display: "block" }}
            >
                <ReloadOutlined />
            </Button>
        </Layout.Header>
    );
};

export default Header;
