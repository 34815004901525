import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table } from "antd";
import { fetchProfileSettingsAdminList } from "actions/notifications";

const AdminNotificationsSettings = (props) => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.notifications.profileNotificationsAdminSettings);

    useEffect(() => {
        dispatch(fetchProfileSettingsAdminList());
    }, []);

    return (
        <>
            <Typography.Title level={3}>Администратор</Typography.Title>
            <Table
                className={"data-table"}
                pagination={false}
                rowKey="id"
                columns={props.columns}
                dataSource={list}
                locale={{ emptyText: "Нет данных" }}
            />
            <br />
            <br />
        </>
    );
};

export default AdminNotificationsSettings;