import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="loading" style={{ position: "absolute", top: '20%', left: "50%", transform: "translate(-20%, -50%)", marginTop: "100px" }}>
      <Spin />
    </div>
  );
};

export default Loading;
