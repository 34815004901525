import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, Form, Input, Button, Switch } from "antd";
import { fetchItem, updateItem } from "actions/taxes";

const Item = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const item = useSelector(state => state.taxes.item)

  useEffect(() => {
    dispatch(fetchItem(id));
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(item);
  });

  const onFinish = (values) => {
    let val = { ...values, id: item.id }
    dispatch(updateItem(val))
  };

  return (
    <Card title="Редактирование" extra={
      <Link to="/dict/taxes"><Button type="primary">Назад</Button></Link>
    }>

      <Form autoComplete="off" form={form} onFinish={onFinish} labelCol={{ span: 5 }}>
        <Form.Item label="Ставка" name="nds">
          <Input type="text" placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="По умолчанию" name="default" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Сохранить</Button>
      </Form>
    </Card>
  );
};

export default Item;
