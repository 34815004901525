import React, { useEffect } from "react";
import { Button, Modal, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanyUser } from "actions/users";

const Edit = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.users.companyUser);
    const users = useSelector(state => state.users.data);

    useEffect(() => {
        form.setFieldsValue(item);
    });

    const handleCancel = () => {
        props.setIsItemModalOpen(false);
    };

    const onFinish = (values) => {
        let val = { ...values };
        dispatch(updateCompanyUser(props.id, item.id, val));
        props.setIsItemModalOpen(false);
    };

    return (
        <Modal
            title="Редактировать пользователя"
            open={props.isItemModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Редактировать
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={1000}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 5 }} form={form} onFinish={onFinish}>
                <Form.Item label="Пользователь" name="company_id">
                    <Select placeholder="Не указано">
                        {users.map((item) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
