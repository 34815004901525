import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import { Navigate, useParams } from "react-router-dom";
import AuthLayout from "components/layout/auth_layout";
import { changeRecoveryPassword } from "actions/login";

const NewPassword = () => {
    const dispatch = useDispatch();
    const { token } = useParams();

    const onFinish = (values) => {
        let val = { ...values, token: token };
        dispatch(changeRecoveryPassword(val));
    };

    // if (this.state.loggedIn) {
    //     return <Navigate to="/" />
    // }

    return (
        <AuthLayout>
            <h2 style={{ textAlign: "center" }}>Введите новый пароль</h2>
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} onFinish={onFinish}>
                <Form.Item name="password" label="Пароль">
                    <Input type="password" autoComplete="off" />
                </Form.Item>
                <Form.Item name="confirm_password" label="Еще раз">
                    <Input type="password" autoComplete="off" />
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>
                    Создать
                </Button>
            </Form>
        </AuthLayout>
    );
};

export default NewPassword;
