import { combineReducers } from 'redux';
import {
    AGREEMENT_BY_ID,
    AGREEMENT_BY_ID_LOADING,
    STORED_AGREEMENT_BY_ID,
    AGREEMENT_BY_ID_NOT_FOUND,
    AGREEMENTS_ADD,
    AGREEMENTS_ADD_STAY,
    AGREEMENTS_DATA,
    AGREEMENTS_DATA_ARE_LOADING,
    AGREEMENT_CONDITIONS,
    AGREEMENT_CONDITIONS_ARE_LOADING,
    AGREEMENT_TYPES,
    AGREEMENT_CONDITION_ADDED,
    AGREEMENT_BUTTON_DISPLAY_STATUS
} from "../constants/agreements";

export function agreementByID(state = [], action) {
    switch (action.type) {
        case AGREEMENT_BY_ID:
            return action.item;
        default:
            return state;
    }
}

export function agreementByIdError(state = false, action) {
    switch (action.type) {
        case AGREEMENT_BY_ID_NOT_FOUND:
            return action.bool;
        default:
            return state;
    }
}

export function storedAgreementById(state = [], action) {
    switch (action.type) {
        case STORED_AGREEMENT_BY_ID:
            return action.item;
        default:
            return state;
    }
}

export function agreementButtonDisplay(state = false, action) {
    switch (action.type) {
        case AGREEMENT_BUTTON_DISPLAY_STATUS:
            return action.bool;
        default:
            return state;
    }
}

export function agreementByIdAreLoading(state = [], action) {
    switch (action.type) {
        case AGREEMENT_BY_ID_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case AGREEMENTS_ADD:
            return action.data;
        default:
            return state;
    }
}

export function stay(state = 0, action) {
    switch (action.type) {
        case AGREEMENTS_ADD_STAY:
            return action.stay;
        default:
            return state;
    }
}

export function data(state = [], action) {
    switch (action.type) {
        case AGREEMENTS_DATA:
            return action.data;
        default:
            return state;
    }
}

export function dataAreLoading(state = true, action) {
    switch (action.type) {
        case AGREEMENTS_DATA_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function agreementConditions(state = [], action) {
    switch (action.type) {
        case AGREEMENT_CONDITIONS:
            return action.data;
        default:
            return state;
    }
}

export function agreementConditionsAreLoading(state = [], action) {
    switch (action.type) {
        case AGREEMENT_CONDITIONS_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function agreementTypes(state = [], action) {
    switch (action.type) {
        case AGREEMENT_TYPES:
            return action.agreementTypes;
        default:
            return state;
    }
}

export function agreementConditionAdded(state = null, action) {
    switch (action.type) {
        case AGREEMENT_CONDITION_ADDED:
            return action.key;
        default:
            return state;
    }
}

export const agreementsReducers = () => combineReducers({
    agreementTypes: agreementTypes,
    data: data,
    dataAreLoading: dataAreLoading,
    add: add,
    stay: stay,
    item: agreementByID,
    itemError: agreementByIdError,
    storedItem: storedAgreementById,
    itemLoading: agreementByIdAreLoading,
    itemButtonDisplay: agreementButtonDisplay,
    conditions: agreementConditions,
    conditionsLoading: agreementConditionsAreLoading,
    conditionAdded: agreementConditionAdded
});