import React from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import ProfileTabs from "./tabs";

const List = () => {
    const user = useSelector((state) => state.globals.current_user);

    return (
        <>
            <Typography.Title level={2}>{user?.name}</Typography.Title>
            <ProfileTabs />
        </>
    );
};

export default List;