import React, { useEffect } from "react";
import { Button, Modal, Form, Input, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateInsurance } from "actions/equipments";

const Edit = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const item = useSelector((state) => state.equipments.insurance);
    const managers = useSelector((state) => state.users.managers);

    useEffect(() => {
        form.setFieldsValue({
            ...item,
            start_date: dayjs(item.start_date),
            end_date: dayjs(item.end_date)
        });
    });

    const handleCancel = () => {
        props.setIsItemModalOpen(false);
    };

    const onFinish = (values) => {
        dispatch(updateInsurance(props.id, item.id, values));
        props.setIsItemModalOpen(false);
    };

    return (
        <Modal
            title="Редактировать страховку"
            open={props.isItemModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Сохранить
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Закрыть
                </Button>
            ]}
            width={700}
        >
            <Form autoComplete="off" colon={false} labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
                <Form.Item label="Начало страховки" name="start_date" rules={[{ required: true, message: "Заполните поле" }]}>
                    <DatePicker format={"DD.MM.YYYY"} />
                </Form.Item>

                <Form.Item label="Окончание страховки" name="end_date" rules={[{ required: true, message: "Заполните поле" }]}>
                    <DatePicker format={"DD.MM.YYYY"} />
                </Form.Item>

                <Form.Item label="Номер" name="number" rules={[{ required: true, message: "Заполните поле" }]}>
                    <Input placeholder="Не указано" />
                </Form.Item>


                <Form.Item
                    label="Менеджер"
                    name="user_id"
                    rules={[{ required: true, message: "Заполните поле" }]}
                >
                    <Select placeholder="Не указано">
                        {managers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
