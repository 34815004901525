import React from "react";
import { Typography } from "antd";
import OrdersTabs from "./tabs";

const List = () => {
  return (
    <>
      <Typography.Title level={2}>Заказы</Typography.Title>
      <OrdersTabs />
    </>
  )
}

export default List;
