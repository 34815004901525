import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginComponent from "components/login/index";
import LogoutComponent from "components/login/logout";
import RecoveryComponent from "components/login/recovery";
import NewPasswordForm from "components/login/recovery/new_password";
import PrivateRoute from "navigation/private_route";
import Dashboard from "components/dashboard";
import Equipments from "../pages/equipments";
import Nomenclature from "../pages/dict/nomenclature";
import Taxes from "../pages/dict/taxes";
import Reports from "../pages/documents/reports";
import EquipmentCategories from "../pages/dict/equipment_categories";
import NomenclatureCategories from "../pages/dict/nomenclature_categories";
import Regions from "../pages/dict/regions";
import Cities from "../pages/dict/cities";
import Units from "../pages/dict/units";
import Offers from "../pages/offers";
import Users from "../pages/users";
import Orders from "../pages/orders";
import Sales from "../pages/sales";
import Managers from "../pages/managers";
import Performers from "../pages/performers";
import Purchases from "../pages/purchases";
import Crm from "../pages/crm";
import Profile from "../pages/profile";
import Statements from "../pages/bank/statements";
import Accruals from "../pages/accruals";

const Main = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginComponent />} />
            <Route exact path="/recovery" element={<RecoveryComponent />} />
            <Route path="/recovery/:token" element={<NewPasswordForm />} />

            <Route element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/logout" element={<LogoutComponent />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/crm/*" element={<Crm />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/offers/*" element={<Offers />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/orders/*" element={<Orders />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/equipments/*" element={<Equipments />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/sales/*" element={<Sales />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/users/*" element={<Users />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/managers/*" element={<Managers />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/performers/*" element={<Performers />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/accruals/*" element={<Accruals />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/purchases/shipments/*" element={<Purchases />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/bank/statements/*" element={<Statements />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/equipment-categories/*" element={<EquipmentCategories />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/cities/*" element={<Cities />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/regions/*" element={<Regions />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/nomenclature/*" element={<Nomenclature />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/units/*" element={<Units />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/nomenclature-categories/*" element={<NomenclatureCategories />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dict/taxes/*" element={<Taxes />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/documents/reports/*" element={<Reports />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/profile/*" element={<Profile />} />
            </Route>
        </Routes>
    );
};

export default Main;
