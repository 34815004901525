import React, { useEffect } from "react";
import { Button, Modal, Form, Input, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateMileage } from "actions/equipments";


const Edit = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const item = useSelector(state => state.equipments.mileage);
  const managers = useSelector((state) => state.users.managers);

  useEffect(() => {
    form.setFieldsValue({
      date: item.date ? dayjs(item.date) : dayjs(),
      hours: item.hours,
      mileage: item.mileage,
      user_id: item.user?.id
    })
  });

  const handleCancel = () => {
    props.setIsItemModalOpen(false);
  };

  const onFinish = (values) => {
    let val = { ...values, date: values.date.format() };
    dispatch(updateMileage(props.id, item.id, val));
    props.setIsItemModalOpen(false);
  };

  return (
    <Modal
      title="Редактировать пробег"
      open={props.isItemModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" onClick={() => { form.submit() }}>Редактировать</Button>,
        <Button key="back" onClick={handleCancel}>Закрыть</Button>
      ]}
      width={700}
    >
      <Form autoComplete="off" colon={false} labelCol={{ span: 4 }} form={form} onFinish={onFinish}>

        <Form.Item label="Дата" name="date">
          <DatePicker />
        </Form.Item>

        <Form.Item label="Моточасы" name="hours">
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item label="Пробег" name="mileage">
          <Input placeholder="Не указано" />
        </Form.Item>

        <Form.Item
          label="Менеджер"
          name="user_id"
          rules={[{ required: true, message: "Заполните поле" }]}
        >
          <Select placeholder="Не указано">
            {managers.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  )
};

export default Edit;