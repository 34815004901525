import React from "react";
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { addItem } from "actions/regions"

const Add = () => {
	const dispatch = useDispatch();

  	const onFinish = (values) => {
    	dispatch(addItem(values))
  	};

	return (
		<Card title="Создание региона" extra={<Link to="/dict/regions"><Button type='primary'>Назад</Button></Link>}>
			<Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 3 }}>
				<Form.Item label="Наименование" name="name">
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Form.Item label="Код региона" name="code">
					<Input type="text" placeholder="Не указано" />
				</Form.Item>

				<Button type="primary" htmlType="submit" style={{ display: "block", marginLeft: "auto" }}>Создать</Button>
			</Form>
		</Card>
	)
}

export default Add;
