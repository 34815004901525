export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_AND_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const DATE_MONTH_FORMAT = "M";

/* layouts const */
export const ADD_DYNAMIC_FIELD_COLS = 4;

/* theme constants */
export const ACTIVE_LINK_CLASS = "ant-btn-link_active";

/* time */
export const SUCCESS_UPDATE_NOTE_TIME = 5000;

/* sizes */
export const SMALL_INPUT_WIDTH = 92;

/* global month */
export const MONTH = "MONTH";
export const DAYS_IN_MONTH = "DAYS_IN_MONTH";

export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_MENU_ITEM = "CURRENT_MENU_ITEM";
