import axios from "axios";
import * as messages from "./handle_messages";
import { API_URL } from "config/conf";

export function updateItem(item) {
    return (dispatch) => {
        axios.post(`${API_URL}profile/change_info`, item).then((response) => {
            dispatch({ type: "CURRENT_USER", payload: response.data });
            dispatch(messages.success("Профиль обновлен"));
        });
    };
}