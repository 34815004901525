import { combineReducers } from "redux";

export function monthList(state = [], action) {
    switch (action.type) {
        case "MONTH_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function dayList(state = [], action) {
    switch (action.type) {
        case "DAY_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function processingList(state = [], action) {
    switch (action.type) {
        case "PROCESSING_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function unshipmentList(state = [], action) {
    switch (action.type) {
        case "UNSHIPMENT_ORDERS_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function filterValues(state = null, action) {
    switch (action.type) {
        case "FILTER_VALUES":
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case "ORDERS_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function purchases(state = [], action) {
    switch (action.type) {
        case "ORDER_PURCHASES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function purchase(state = [], action) {
    switch (action.type) {
        case "ORDER_PURCHASES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export function sales(state = [], action) {
    switch (action.type) {
        case "ORDER_SALES_LIST":
            return action.payload;
        default:
            return state;
    }
}

export function sale(state = [], action) {
    switch (action.type) {
        case "ORDER_SALES_ITEM":
            return action.payload;
        default:
            return state;
    }
}

export const ordersReducers = () =>
    combineReducers({
        monthList: monthList,
        dayList: dayList,
        processingList: processingList,
        unshipmentList: unshipmentList,
        filterValues: filterValues,
        item: item,
        purchases: purchases,
        purchase: purchase,
        sales: sales,
        sale: sale
    });
