import axios from "axios";
import Cookie from "js-cookie";
import { API_URL } from "../config/conf";
import * as message from "./handle_messages";
import { history } from "../store";

export function login(params) {
    return (dispatch) => {
        axios.post(`${API_URL}login`, params).then((response) => {
            setToken(response.data.token);
            dispatch(message.success("Добро пожаловать"));
            dispatch(current_user());
            history.push("/");
        });
    };
}

export function current_user() {
    return (dispatch) => {
        axios.get(`${API_URL}profile/user`, { token: Cookie.get("token") }).then((response) => {
            dispatch({ type: "CURRENT_USER", payload: response.data });
        });
    };
}

export function resetpassword(params) {
    return (dispatch) => {
        axios.post(`${API_URL}resetpassword`, params).then((response) => {
            dispatch({ type: "RECOVERY_PASSWORD_TYPE", payload: response.data });
        });
    };
}

export function changePassword(item) {
    return (dispatch) => {
        axios.post(`${API_URL}profile/change_password`, item).then(() => {
            dispatch(message.success("Пароль изменён"));
        });
    };
}

export function changeRecoveryPassword(item) {
    return (dispatch) => {
        axios.post(`${API_URL}resetpassword/change`, item).then(() => {
            dispatch(message.success("Пароль изменён"));
            history.push("/login");
        });
    };
}

export function isLoggedIn() {
    if (getToken()) {
        return true;
    } else {
        return false;
    }
}

export function setToken(token) {
    Cookie.set("token", token);
}

export function logout() {
    Cookie.remove("token");
    window.location.reload(true);
}

export function getToken() {
    return Cookie.get("token") || null;
}

export function setTokenHeaders() {
    const token = this.getToken();
    if (token) {
        return {
            headers: {
                authorization: `JWT ${token}`
            }
        };
    }
}
