import React, { useEffect, useState } from "react";
import { Typography, List, Space, Button, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Add from "./add";
import Edit from "./edit";

const Positions = (props) => {
    const dispatch = useDispatch();
    const nomenclature = useSelector((state) => state.nomenclature.list);
    const taxes = useSelector((state) => state.taxes.list);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const showAddModal = () => {
        setIsAddModalOpen(true);
    };

    const showEditModal = (id) => {
        setIsEditModalOpen(true);
        dispatch(props.fetchPosition(props.id, id));
    };

    const deletePosition = (id) => {
        dispatch(props.deletePosition(props.id, id));
    };

    const total = (price, quantity) => {
        return price * quantity;
    };

    return (
        <div>
            <Space style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography.Title level={3}>Позиции</Typography.Title>
                <Button type="primary" onClick={showAddModal} disabled={props.archived}>
                    <PlusOutlined />
                </Button>
            </Space>
            <Add
                addPosition={props.addPosition}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
                nomenclature={nomenclature}
                taxes={taxes}
                id={props.id}
            />
            <Edit
                type={props.type}
                updatePosition={props.updatePosition}
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                nomenclature={nomenclature}
                taxes={taxes}
                id={props.id}
            />
            <List
                itemLayout="horizontal"
                dataSource={props.list}
                renderItem={(item) => (
                    <List.Item
                        style={{
                            background: "#fff",
                            borderRadius: "8px",
                            padding: "16px",
                            cursor: "pointer"
                        }}
                    >
                        <List.Item.Meta
                            title={"Наименование"}
                            style={{ minWidth: "500px" }}
                            description={
                                <Popover
                                    content={<Link to={`/dict/nomeclature/${item.nomenclature?.id}`}>{item.nomenclature?.name}</Link>}
                                    trigger="hover"
                                >
                                    {item.name}
                                </Popover>
                            }
                        />
                        <List.Item.Meta
                            title={"Кол-во"}
                            style={{ minWidth: "50px" }}
                            description={
                                <span>
                                    {item.quantity} {item.nomenclature?.unit_name}
                                </span>
                            }
                        />
                        <List.Item.Meta
                            title={"Цена"}
                            style={{ minWidth: "150px" }}
                            description={
                                <span>
                                    {item.price} руб. ({item.vat} %)
                                </span>
                            }
                        />
                        <List.Item.Meta
                            title={"Сумма"}
                            style={{ minWidth: "150px" }}
                            description={<span>{total(item.price, item.quantity)} руб.</span>}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    showEditModal(item.id);
                                }}
                                disabled={props.archived}
                            >
                                <EditOutlined />
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    deletePosition(item.id);
                                }}
                                disabled={props.archived || (props.type === "shipments" && props.list.length === 1)}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default Positions;
